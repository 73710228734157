import React from "react";
import { Menu, Icon, Dropdown } from "semantic-ui-react";
import { useLoginStatus, useLoggedInUser } from "../store/authSelectors";
import { isLoaded } from "../../../sg-core/models/ILoadable";
import useLogout from "../hooks/useLogout";
import RouteLink from "../../../sg-core/components/RouteLink";
import { useCurrentCompany } from "../../store/selectors";

const AuthenticatedMenuItem: React.FC = () => {
    const user = useLoggedInUser();
    const company = useCurrentCompany();

    const logout = useLogout();

    if (user) {
        return (
            <Dropdown item text={`${user.firstName} ${user.surname}`}>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}>Kirjaudu ulos</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    if (isLoaded(company)) {
        return (
            <Dropdown item text={company.data.name}>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <RouteLink id="Login">
                            Kirjaudu sisään käyttäjänä
                        </RouteLink>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Kirjaudu ulos</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <Dropdown item text="Kirjaudutaan...">
            <Dropdown.Menu>
                <Dropdown.Item onClick={logout}>Kirjaudu ulos</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const AuthenticationMenuItem: React.FC = () => {

    const loginState = useLoginStatus();

    if (!isLoaded(loginState)) {
        return (
            <Menu.Item>
                <RouteLink id="Login">
                    <Icon name="key" /> Kirjaudu sisään
                </RouteLink>
            </Menu.Item>
        );
    }

    return <AuthenticatedMenuItem />;
};

export default AuthenticationMenuItem;
