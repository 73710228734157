import fetch from "unfetch";
import { TResult, makeFailure, makeSuccess } from "../utils/Result";

export interface ISgApiService {
    sendHttpRequest: <T>(type: string, body: any, headers: any) => Promise<TResult<T>>;
    sendAction: <T>(action: string, body: any, noToken?: boolean) => Promise<TResult<T>>;
    setToken: (token: string | number) => void;
    clearToken: () => void;
}

export const getSgApiService = (baseUrl: string): ISgApiService => {

    let TOKEN: string | null = null;

    const getToken = () => {
        if (!TOKEN) {
            throw new Error("Cannot send a request with token if token is not set.");
        }
        return `NAS SID ${TOKEN}`;
    };

    const sendHttpRequest = async <T>(method: string, body: any, headers: any, path?: string) => {
        const url = path ? baseUrl.concat(path) : baseUrl;

        try {
            const response = await fetch(url, {
                method,
                body: JSON.stringify(body),
                headers,
            });

            const json = await response.json();

            return makeSuccess(json);
        } catch (e) {
            return makeFailure(e);
        }
    };

    const sendAction = async <P, T>(action: string, payload: P, noToken: boolean = false) => {
        const body = {
            ...payload,
            action,
        };

        const headers: { [key: string]: string } = {};
        if (!noToken) {
            headers.Authorization = getToken();
        }

        return await sendHttpRequest<T>("POST", body, headers);
    };

    const setToken = (token: string | number) => {
        TOKEN = `${token}`;
    };

    const clearToken = () => TOKEN = null;

    return {
        sendHttpRequest,
        sendAction,
        setToken,
        clearToken,
    };
};
