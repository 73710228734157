import { TResult, makeFailure, makeSuccess } from "../../../sg-core/utils/Result";
import { TTKAuthResponse } from "../interfaces/ITTKAuthResponse";
import { ITTKAuthStorage } from "./TTKAuthStorage";
import { ISgApiService } from "../../../sg-core/services/ApiService";
import ITTKAuthInfo from "../interfaces/ITTKAuthInfo";
import AuthUserMapper from "../mappers/authUserMapper";
import IRegistrationInfo from "../interfaces/IRegistrationInfo";

export interface ITTKAuthService {
    initializeAuthentication: () => Promise<TResult<ITTKAuthInfo>>;
    login: (username: string, password: string, remember?: boolean) => Promise<TResult<ITTKAuthInfo>>;
    logout: () => void;
    register: (opts: IRegistrationInfo) => Promise<TResult<boolean>>;
    requestPasswordResetCode: (email: string) => Promise<TResult<boolean>>;
    updatePasswordWithCode: (email: string, password: string, code: string) => Promise<TResult<boolean>>;
    // changeUserPassword: (userId: number, newPassword: string) => Promise<TResult<boolean>>;
}

const ACTION_LOGIN = "kasittele_kirjautuminen_anonyymi";
const ACTION_REGISTER = "rekisteroi_uusi_asiakas_anonyymi";
const ACTION_SEND_PASSWD_RESET_CODE = "laheta_resetointitarkiste_meililla_anonyymi";
const ACTION_CHANGE_PASSWORD_WITH_CODE = "vaihda_salasana_anonyymi";

export const getTTKAuthService = (storageService: ITTKAuthStorage, apiService: ISgApiService): ITTKAuthService => {
    const initializeAuthentication = async (): Promise<TResult<ITTKAuthInfo>> => {
        const fromStorage = storageService.getAuthFromStorage();

        if (fromStorage.isSuccess === true) {
            const { sid } = fromStorage.value;
            apiService.setToken(sid);
            // TODO: Validate sid
            // return makeFailure(new Error("Not authenticated"));
            return makeSuccess(fromStorage.value);
        }

        return makeFailure(new Error("Now authenticated"));
    };

    // tslint:disable-next-line: max-line-length
    const login = async (username: string, password: string, remember: boolean = false): Promise<TResult<ITTKAuthInfo>> => {
        const body = {
            kayttajatunnus: username,
            salasana: password,
        };

        const response = await apiService.sendAction<TTKAuthResponse>(ACTION_LOGIN, body, true);

        if (response.isSuccess === true) {
            const data = response.value;
            const authInfo: ITTKAuthInfo = {
                sid: `${data.sid}`,
                user: AuthUserMapper.authToDomain(data.henkilo),
                companyId: data.yritys.id,
            };
            apiService.setToken(`${data.sid}`);
            storageService.setAuthToStorage(authInfo);
            return makeSuccess(authInfo);
        }

        return makeFailure(response.error);
    };

    const logout = () => {
        apiService.clearToken();
        storageService.clearAuthFromStorage();
    };

    const register = async (opts: IRegistrationInfo): Promise<TResult<boolean>> => {
        const action = ACTION_REGISTER;
        const payload = {
            kayttaja: {
                etunimi: opts.firstName,
                sukunimi: opts.surname,
                email: opts.email,
                salasana: opts.password,
            },
            yritys: {
                nimi: opts.companyName,
                toimiala: opts.companyIndustry,
            },
        };

        interface IRes { success: boolean; error: { code: string }; }
        const result = await apiService.sendAction<IRes>(action, payload, true);

        if (result.isFailure) {
            return makeFailure(result.error);
        }

        if (!result.value.success) {
            return makeFailure(new Error(result.value.error.code));
        }

        return makeSuccess(true);
    };

    const requestPasswordResetCode = async (email: string): Promise<TResult<any>> => {
        const action = ACTION_SEND_PASSWD_RESET_CODE;
        const payload = {
            kayttaja: {
                email,
            },
        };

        const response = await apiService.sendAction<{ success: boolean }>(action, payload, true);

        if (response.isFailure) {
            return makeFailure(response.error);
        }

        if (!response.value.success) {
            return makeFailure(new Error("failResponse"));
        }

        return makeSuccess(true);
    };

    const updatePasswordWithCode = async (email: string, newPassword: string, code: string): Promise<TResult<any>> => {
        const action = ACTION_CHANGE_PASSWORD_WITH_CODE;
        const payload = {
            kayttaja: {
                email,
                salasana: newPassword,
            },
            tarkiste: code,
        };

        const response = await apiService.sendAction<{ success: boolean }>(action, payload, true);

        if (response.isFailure) {
            return makeFailure(response.error);
        }

        if (!response.value.success) {
            return makeFailure(new Error("failResponse"));
        }

        return makeSuccess(true);
    };

    return {
        initializeAuthentication,
        login,
        logout,
        register,
        requestPasswordResetCode,
        updatePasswordWithCode,
    };
};
