import { TResult, makeSuccess, makeFailure } from "../../sg-core/utils/Result";
import ITTKUser, { ITTKUserDTO } from "../interfaces/ITTKUser";
import UserMapper from "../mappers/UserMapper";
import { ISgApiService } from "../../sg-core/services/ApiService";

export interface IRegisterUserParam {
    firstname: string;
    surname: string;
    email: string;
    companyName: string;
    companyIndustry: number;
    password: string;
}

export interface ITTKUserService {
    getUsers(companyId: number): Promise<TResult<ITTKUser[]>>;
    saveUser(user: ITTKUser): Promise<TResult<ITTKUser>>;
    inviteUser(companyId: string, email: string, firstname: string, surname: string): Promise<TResult<boolean>>;
}

interface IGetUsersResponse {
    kayttajat: ITTKUserDTO[];
}

export const getTTKUserService = (apiService: ISgApiService): ITTKUserService => {

    const getUsers = async (companyId: number): Promise<TResult<ITTKUser[]>> => {
        const action = "hae_yrityksen_kayttajalista";
        const payload = { yritys: { id: companyId } };

        const response = await apiService.sendAction<IGetUsersResponse>(action, payload);

        if (response.isFailure === true) {
            return makeFailure(response.error);
        }

        const mapped = response.value.kayttajat.map(UserMapper.apiToDomain);

        return makeSuccess(mapped);
    };

    const saveUser = async (user: ITTKUser): Promise<TResult<ITTKUser>> => {
        const action = "paivita_kayttajan_tiedot";
        const payload = {
            kayttaja: UserMapper.domainToApi(user),
        };

        const response = await apiService.sendAction(action, payload);

        if (response.isFailure === true) {
            return makeFailure(response.error);
        }

        return makeSuccess({ ...user });
    };

    const inviteUser =
        async (companyId: string, email: string, firstname: string, surname: string): Promise<TResult<boolean>> => {
            const action = "laheta_kayttajakutsu_meili";

            const base64data = btoa(JSON.stringify({
                companyId,
                email,
                firstname,
                surname,
            }));

            const payload = {
                kutsuttava: {
                    meiliosoite: email,
                    etunimi: firstname,
                    sukunimi: surname,
                },
                base64data,
            };

            const response = await apiService.sendAction(action, payload);

            if (response.isFailure === true) {
                return makeFailure(response.error);
            }

            return makeSuccess(true);
        };

    return {
        getUsers,
        saveUser,
        inviteUser,
    };
};
