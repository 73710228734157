import { takeLeading, call, put } from "redux-saga/effects";
import { ITTKAuthService } from "../services/ITTKAuthService";
import { createSuccessLoginAction, requestInitializeLogin, createLogoutAction, createSuccessAnonLoginAction } from "./authActions";
import { TResult } from "../../../sg-core/utils/Result";
import ITTKAuthInfo from "../interfaces/ITTKAuthInfo";

const createInitializeSaga = (ttkAuth: ITTKAuthService) =>
    function* saga() {
        try {
            // Check the URL for a company GUID. If there is one and it's valid,
            // mark the user as an anonymous login with a company identifier.
            const { search } = window.location;
            const regex = /^\?.*yritys=([A-Za-z0-9-]+)&?$/g; // "?yritys={id}"
            const regexResult = regex.exec(search);
            if (regexResult !== null) {
                const [, companyId] = regexResult;
                yield put(createSuccessAnonLoginAction(companyId));

                // Clear the company ID from the url
                window.history.replaceState({}, "Tapaturmatutkinta", `${window.location.origin}${window.location.pathname}`);
                return;
            }

            // Attempt to initialize via the ttk auth service. The
            // service will handle the situation where the SID is stored in
            // local-/sessionStorage. The SID will also be validated by the
            // service.
            const initializationResult: TResult<ITTKAuthInfo> = yield call(ttkAuth.initializeAuthentication);
            if (initializationResult.isSuccess === true) {
                const { sid, user, companyId } = initializationResult.value;
                yield put(createSuccessLoginAction(sid, user, companyId));
                return;
            }

            // If none of the above returns a valid login, the user is not
            // logged in. End the login processing by dispatching a logout
            // action.
            yield put(createLogoutAction());
        } catch (e) {
            yield put(createLogoutAction());
        }
    };

export default function(ttkAuth: ITTKAuthService) {
    return takeLeading(
        requestInitializeLogin,
        createInitializeSaga(ttkAuth),
    );
}
