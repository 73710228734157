import { takeEvery, call, put } from "redux-saga/effects";
import { ITTKUserService } from "../../services/TTKUserService";
import {
    IRequestSaveUser,
    createFailureSaveUserAction,
    createSuccessSaveUserAction,
    requestSaveUser,
    createUserMarkedForEditingAction,
} from "../actions/userActions";
import { TResult } from "../../../sg-core/utils/Result";
import ITTKUser from "../../interfaces/ITTKUser";

const makeSaveUserSaga = (userService: ITTKUserService) =>
    function* saga(action: IRequestSaveUser) {
        const { id } = action.payload.user;
        try {
            const result: TResult<ITTKUser> = yield call(userService.saveUser, action.payload.user);

            if (result.isSuccess) {
                yield put(createSuccessSaveUserAction(result.value));

                // Reset the edit state
                yield put(createUserMarkedForEditingAction(action.payload.user));
            } else {
                yield put(createFailureSaveUserAction(id, result.isFailure ? result.error : new Error("Unkown error")));
            }
        } catch (error) {
            yield put(createFailureSaveUserAction(id, error));
        }
    };

export default function createSaga(userService: ITTKUserService) {
    return takeEvery(
        requestSaveUser,
        makeSaveUserSaga(userService),
    );
}
