import { call, put, takeLeading, select } from "redux-saga/effects";
import { updateActionReportAnonymousRequest, createAccidentReportUpdateSuccess, IUpdateAccidentReportEditingNoSave } from "../actions";
import { IAccidentReport } from "../../interfaces/IAccidentReportData";
import { IAccidentReportService,  } from "../../services/AccidentReportService";
import { TResult } from "../../../sg-core/utils/Result";
import { selectLoggedInUserCompanyId } from "../../auth/store/authSelectors";

export const makeUpdateAccidentReportFormAnonymousSaga = (accidentReportService: IAccidentReportService) => 
    function* updateForm(action: IUpdateAccidentReportEditingNoSave): any {
        try {
            const { editedReport } = action.payload;
            const companyId = yield select(selectLoggedInUserCompanyId);
            const result: TResult<IAccidentReport> = yield call(
                accidentReportService.updateAccidentReportAnonymous,
                companyId,
                editedReport
            );

            if (result.isSuccess) {
                yield put(createAccidentReportUpdateSuccess(result.value));
            } else {
                console.error(result.error);
            }
        } catch (e) {
            console.error("Error when posting form", e);
        }
};

export default function create(accidentReportService: IAccidentReportService) {
    return takeLeading(
        updateActionReportAnonymousRequest,
        makeUpdateAccidentReportFormAnonymousSaga(accidentReportService),
    );
}
