import React, { memo, useState } from "react";

import { Menu, MenuProps, MenuItemProps, Button, Icon } from "semantic-ui-react";
import { ISgHeaderMenuItem } from "../interfaces";

interface IMenuItemProps {
    menuItem: ISgHeaderMenuItem;
}

interface ISgNavbarProps {
    color: MenuProps["color"];
    menuItems: ISgHeaderMenuItem[];
    menuHeaderItems: ISgHeaderMenuItem[];
    renderMenuItem?: (item: ISgHeaderMenuItem) => JSX.Element;
}

/**
 * Renders a single header item from a definition
 * @param {ISgHeaderMenuItem} item Item to render
 */
// tslint:disable-next-line: max-line-length
const renderMenuItemWithRenderer = (item: ISgHeaderMenuItem, render?: (item: ISgHeaderMenuItem) => JSX.Element, restProps: MenuItemProps = {}): JSX.Element => {
    const renderDefaultContent = (menuItem: ISgHeaderMenuItem) => {
        if (menuItem.linkTo) {
            return <a href={menuItem.linkTo}>{menuItem.text}</a>;
        }
        return <span>{menuItem.text}</span>;
    };

    if (item.component) {
        return React.createElement(item.component, { key: item.key });
    }

    return (
        <Menu.Item key={item.key} onClick={item.onClick} {...restProps}>
            {render ? render(item) : renderDefaultContent(item)}
        </Menu.Item>
    );
};

/**
 * Renders the Systemsgarden navigation bar with the proper options.
 * @param param0
 */
const SgNavbar: React.FC<ISgNavbarProps> = ({ color, menuItems, menuHeaderItems, renderMenuItem }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <Menu color={color} inverted className={mobileMenuOpen ? "sg-menu-visible" : ""}>
            {menuHeaderItems.map((item, index) => (
                renderMenuItemWithRenderer(item, renderMenuItem, { header: index === 0 })
            ))}

            <Menu.Menu position="right" className="collapseMenu">
                {menuItems.map((item) => renderMenuItemWithRenderer(item, renderMenuItem))}
            </Menu.Menu>

            <Button primary circular className="menuButton" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                <Icon name="bars" />
            </Button>
        </Menu>
    );
};

export default memo(SgNavbar);
