import { call, put, takeLeading, select } from "redux-saga/effects";
import { postAccidentReportInquiryAction, createAccidentReportInquiryPostSuccess, createAccidentReportInquiryFailure } from "../actions";
import { IAccidentReportInquiry } from "../../interfaces/IAccidentReportData";
import { IAccidentReportService } from "../../services/AccidentReportService";
import { TResult } from "../../../sg-core/utils/Result";
import { selectAccidentReportInquiry } from "../selectors";
import { TSubmittable } from "../../../sg-core/models/ISubmittable";

export const makePostAccidentReportInquirySaga = (accidentReportService: IAccidentReportService) => function* postForm(): any {
    try {
        const formData: TSubmittable<IAccidentReportInquiry> = yield select(selectAccidentReportInquiry);

        const result: TResult<IAccidentReportInquiry> = yield call(
            accidentReportService.createAccidentReportInquiry,
            formData.data.report.id,
            formData.data.message,
        );

        if (result.isSuccess) {
            yield put(createAccidentReportInquiryPostSuccess(result.value));
        } else {
            yield put(createAccidentReportInquiryFailure(result.error));            
            console.error(result.error);
        }
    } catch (e) {
        yield put(createAccidentReportInquiryFailure(e as Error));
        console.error("Error when posting form", e);
    }
};

export default function create(accidentReportService: IAccidentReportService) {
    return takeLeading(
        postAccidentReportInquiryAction,
        makePostAccidentReportInquirySaga(accidentReportService),
    );
}
