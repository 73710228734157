// tslint:disable: indent

export default {
	fi: {
		translation: {
			"turvallisuusilmoituksetMenu": "Ilmoitukset",
			"toimenpiteetMenu": "Toimenpiteet",
			"asetuksetMenu": "Asetukset",
			"logoutMenu": "Kirjaudu ulos",

			// Frontpage texts for buttons and headers
			"turvaArvi": "Tapaturmatutka",
			"turvaArviSubtext": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ullam voluptatum corporis architecto.",
			"accidentReport": "Tapaturmailmoitus",
			"accidentReports": "Ilmoitukset",
			"closeCallReport": "Läheltä piti -ilmoitus",
			"safetyObservation": "Turvallisuushavainto",
			"seeAllAccidentReports": "Katso tapaturmailmoitukset",

			"turvaArviDescription": "Tapaturmatutka kokoaa yhteen ilmoitukset työpaikalla sattuneista tapaturmista ja opastaa tapaturmien tutkinnassa. Tapaturman tutkinnan tarkoituksena on selvittää, miten vastaava tapaturma voidaan välttää tulevaisuudessa. Samalla hoidetaan kuntoon tutkinnassa esille tulleet toimenpiteitä vaativat asiat.",
			"turvaArviDescriptionNote": "HUOM! Tapaturmatutkan ilmoitus ei ole vakuutusyhtiölle tehtävä tapaturmailmoitus. Vakavat työtapaturmat pitää ilmoittaa poliisille ja työsuojeluviranomaiselle. Lisätietoa työsuojeluviranomaisen sivuilta:",

			// Frontpgae buttons & texts
			"shareReportLink": "Jaa ilmoituslinkki henkilöstölle",
			"shareReportLinkDescription": "Täydennä työpaikan tiedot Asetukset-välilehdellä. Asetukset-välilehdeltä löydät myös linkin sähköiseen ilmoituslomakkeeseen, jonka voit jakaa henkilöstölle esimerkiksi intranetissä tai lähettää sähköpostitse.",
			"viewReportsLink": "Ilmoitukset - tutki tapaturma",
			"viewReportsLinkDescription": "Selaa henkilöstön tekemiä ilmoituksia Ilmoitukset-välilehdeltä ja tutki tapaturma: haastattele paikalla olleita, käy läpi olosuhteet ja muut tapaturmaan vaikuttaneet asiat ja täydennä tapaturman kuvaus. Mitä tästä opimme ja miten toimimme jatkossa?",
			"viewProceedingsLink": "Toimenpiteet - toteuta ja seuraa",
			"viewProceedingsLinkDescription": "Käy läpi toimenpiteitä vaativat asiat: valitse toimenpiteen tila, kirjoita kuvaus toimenpiteistä, määritä vastuuhenkilö ja aikataulu. Seuraa toimenpiteiden edistymistä. Ilmoitus muuttuu tilaan ”Valmis” vasta kun kaikki siihen liittyvät toimenpiteet on käsitelty.",

			// Buttons etc
			"createNew": "Luo uusi",
			"addNew": "Lisää uusi",

			// Error messages
			"routeErrorTitle": "Odottamaton virhe",
			"routeErrorDescription": "Sovellus kohtasi odottamattoman virheen näkymää ladattaessa.",

			// Different status texts
			"genericLoading": "Ladataan",
			"genericError": "Virhe",
			"genericInitializing": "Valmistellaan",

			// Accident report form texts
			"accidentReportFormTitle": "Ilmoitus",
			"accidentReportEventDescription": "Kuvaus tapahtumasta",
			"accidentReportEventDescriptionHelpTextP1": "Kuvaile mahdollisimman tarkasti mitä tilanteessa tapahtui. Mikä tapahtumaketju mahdollisesti johti tilanteeseen? Mitä tehtiin ennen tilannetta ja välittömästi sen jälkeen? Ota tapahtumaketjua tukevia kuvia, jos se on mahdollista. ",
			// "accidentReportEventDescriptionHelpTextP2": "Selvitä tapaturman taustalla olevat tekijät, jotka mahdollistivat tapaturman sattumisen. Muistathan, että esimerkiksi huolimattomuus ja kiire eivät ole tapaturman syitä vaan seurauksia – selvitä perimmäiset tekijät eli mistä kiire johtui, oliko henkilö perehdytetty tehtävään, miten työ oli suunniteltu, miksi ei huomattu olennaista asiaa jne. Miten vastaavat tapaturmat voitaisiin välttää – mitä tästä opimme?",
			// "accidentReportEventDescriptionHelpTextP3": "Muista selvittää faktat, kysele ja kuuntele, käy tapahtumapaikalla, ota valokuvia. Älä oleta!",
			"accidentReportLocation": "Tapahtumapaikka",
			"accidentReportLocationHelpText": "Kuvaile tapahtumapaikka mahdollisimman tarkasti. Ota tueksi kuvia, jos se on mahdollista. Älä kuvaa henkilöitä vaan työympäristöä.",
			"accidentReportWhatWasGood": "Mitkä asiat olivat jo kunnossa?",
			"accidentReportMayContact": "Sähköpostiosoitteeni saa näyttää käsittelijälle",
			"accidentReportMayContactDescription": "Jos annat yhteystietosi, työpaikkasi tapaturmantutkijat voivat ottaa sinuun yhteyttä ja kysyä lisätietoja tai kertoa tutkinnan edistymisestä. Voit myös itse ottaa yhteyttä työpaikkasi tapaturmantutkijoihin ja pyytää tietoja tutkinnan edistymisestä.",

			// Accident report form finished texts
			"accidentReportFormFinished": "Kiitos ilmoituksestasi!",
			// "accidentReportFormFinishedDescription": "Aliquam in feugiat risus. Donec finibus velit a vehicula ullamcorper. Sed nec tempor magna, eget posuere nunc. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sit amet posuere mi.",
			// "accidentReportFormFinishedContactInfo": "Ilmoituksen käsittelijä voi olla sinuun yhteydessä, mikäli olet antanut lomakkeella yhteystietosi.",
			"accidentReportInquiryFinished": "Lisätietopyyntösi on lähetetty",

			"accidentReportProcess": "Ilmoituksen tutkinta",
			"environmentalFactorsDuringAccident": "Olosuhteet tapahtumahetkellä",
			"otherFactorsAffectingAccident": "Muut tilanteeseen vaikuttaneet asiat",

			// Accident report process parts
			"list-valaistus": "Valaistus",
			"list-cleaniness": "Siisteys, järjestys",
			"list-slippery": "Liukkaus - kulkuteiden (ulkona ja sisällä) ja lattioiden rakenne sekä pinnat",
			"list-noise": "Melu",
			"list-other": "Muut olosuhteet, esimerkiksi lämpötila, tärinä",
			"list-dangerousEnvironment": "Esineet, käsiteltävät kappaleet, terävät pinnat",
			"list-dangerousMaterials": "Kemikaalit, pölyt, kaasut",
			"list-equipment": "Työvälineet, koneet ja laitteet, työkalut, työtasot, tikkaat, telineet jne.",
			"list-workHabits": "Työmenetelmät, työskentelytapa",
			"list-miscellaneous": "Muut tekijät",

			// Accident report list texts
			"accidentReportListText": "Voit suodattaa ilmoituksia tutkintavaiheen mukaan tai lajitella niitä sarakeotsikoiden mukaan. Aloita tutkinta napsauttamalla ilmoituksen kuvausta ja valitse \"Aloita tutkinta\".",
			"accidentReportListText2": "Ilmoittajalle ei lähde viestiä tutkinnan etenemisestä. Hyvä toimintatapa on kertoa säännöllisesti tutkinnan ja toimenpiteiden edistymisestä.",

			"accReportLighting": "Valaistus",
			"accReportLightingDescription": "Vaikuttiko valaistus tilanteen syntyyn? Oliko yleisvalaistus riittävää? Entä kohdevalaistus? Mahdolliset häikäisyt? Oliko valaistuksessa jotain poikkeavaa normaalitilanteeseen? ",
			"accReportSlippery": "Liukkaus - kulkuteiden (ulkona ja sisällä) ja lattioiden rakenne sekä pinnat",
			"accReportSlipperyDescription": "Oliko tapahtumapaikalla esimerkiksi liukasta, rikkinäisiä pintoja tai kuoppia? Vaikuttiko henkilön toiminta jotenkin tilanteen syntyyn? Mistä toiminta johtui? Onko tällainen toiminta normaalia tapahtumapaikalla?",
			"accReportNoise": "Melu",
			"accReportNoiseDescription": "Oliko melulla vaikutusta tilanteen syntyyn? Kuultiinko mahdolliset hälytys- ja varoitusäänet? Vaikeuttiko melu kommunikointia? Millainen melutaso tapahtumahetkellä oli? Oliko tilanne normaali vai poikkesiko se jotenkin tavallisesta ja jos poikkesi niin miksi?",
			"accReportCleaniness": "Siisteys, järjestys",
			"accReportCleaninessDescription": "Vaikuttiko siisteys ja järjestys tilanteen syntyyn - tai ennemmin niiden puuttuminen? Oliko tavaroita väärillä paikoilla? Kulkuväylät auki? Oliko tilanne normaali siisteyden ja järjestyksen osalta vai oliko tilanne poikkeava?",
			"accReportOtherFactors": "Muut olosuhteet, esimerkiksi lämpötila, tärinä",
			"accReportOtherDescription": "Vaikuttiko joku muu olosuhde tai tekijä tilanteen syntyyn? Oliko tilanne poikkeava vai normaalia työhön liittyvää? Jos poikkesi normaalista, niin miksi?",
			"accReportEquipment": "Työvälineet, koneet ja laitteet, työkalut, työtasot, tikkaat, telineet jne.",
			"accReportEquipmentDescription": "Käytettiinkö tilanteessa esimerkiksi työkaluja ja/tai apuvälineitä? Miten nämä edesauttoivat tilanteen synnyssä? Käytettiinkö näitä oikein? Onko käyttöohjeet saatavilla ja ohjeistettu? Onko huollot ym. tehty ajallaan? Olivatko ehjät, tarkistettu, määräykset täyttävät? Onko vaara- ja työskentelyalueet merkitty selkeästi?",
			"accReportWorkHabits": "Työmenetelmät, työskentelytapa",
			"accReportWorkHabitsDescription": "Toimittiinko tilanteessa työohjeiden mukaisesti? Oliko työskentelytapa oikea ja ohjeistettu? Oliko työ suunniteltu oikein? Miten perehdytys työtapoihin ja -menetelmiin on hoidettu? Osasiko työtä tehnyt työnsä riittävän hyvin? Tuliko työn tekemiseen keskeytyksiä tai jouduitko tekemään samanaikaisesti useampia tehtäviä?",
			"accReportDangerousMaterials": "Kemikaalit, pölyt, kaasut",
			"accReportDangerousMaterialsDescription": "Olivatko kemikaalit tai kaasut mahdollisesti osallisena tilanteen synnyssä? Oliko pölyllä osuutta tapahtuneeseen? Miten kemikaaleja käsiteltiin? Oliko käyttöturvallisuustiedotteet saatavilla ja käyty läpi? Miten ohjeet ja toimintatavat sattuessa oli hoidettu? Oliko oikeanlaiset henkilönsuojaimet käytössä?",
			"accReportDangerousEnvironment": "Esineet, käsiteltävät kappaleet, terävät pinnat",
			"accReportDangerousEnvironmentDescription": "Aiheuttiko tilanteen esimerkiksi putoava esine? Jos aiheutti niin mikä aiheutti esineen putoamisen? Käsiteltiinkö hankalia kappaleita oikein? Aiheuttiko mahdollisesti jokin terävä pinta tilanteen? Mieti mitä tapahtui ja miksi?",
			"accReportOtherMisc": "Muut tekijät",
			"accReportOtherMiscDescription": "Mitkä muut tekijät vaikuttivat tilanteen syntyyn? Mieti esimerkiksi koko tapahtumakulun kautta? Mitä mahdollisesti muita normaalista poikkeavia tilanteita oli?",

			// Accident report yes no texts
			"accReportYesNosNoText": "Korjattavaa",
			"accReportYesNosYesText": "Kunnossa",

			// Safety observation yes nos
			"safetyObservationInspection": "Tehdäänkö tutkinta?",

			// Proceedings page texts
			"proceedingsList": "Toimenpiteet",
			"requiredProceeding": "Toimenpidelista",
			"responsiblePerson": "Vastuuhenkilö",
			"writeupDate": "Kirjauspäivä",
			"proceedingDescription": "Kuvaus toimenpiteistä",
			"proceedingListText": "Voit suodattaa toimenpiteitä tilan mukaan (Kaikki – Valmiit – Työn alla – Ei aiheuta toimenpiteitä).",
			"proceedingListText2": "Avaa toimenpidettä vaativa asia napsauttamalla sen otsikkoa. Valitse toimenpiteen tila ja kirjoita kuvaus toimenpiteistä. Määritä vastuuhenkilö ja aikataulu.",
			"proceedingListText3": "Seuraa toimenpiteiden edistymistä. Ilmoitus muuttuu tilaan ”Valmis” vasta kun kaikki siihen liittyvät toimenpiteet on käsitelty.",
			"proceedingListText4": "Vastuuhenkilölle tai ilmoittajalle ei lähde viestiä tutkinnan etenemisestä. Hyvä toimintatapa on kertoa heille säännöllisesti tutkinnan ja toimenpiteiden edistymisestä.",

			// Table headers
			"report": "Ilmoitus",
			"reportId": "Ilmoitusnro.",
			"description": "Kuvaus",
			"type": "Tyyppi",
			"status": "Tila",
			"accReportCreatedAt": "Tapahtuma-aika",
			"accReportUpdatedAt": "Viimeksi käsitelty",

			// Generic form texts
			"addPhoto": "Lisää kuva",
			"department": "Osasto",
			"email": "Sähköposti",
			"send": "Lähetä",
			"save": "Tallenna",
			"saveProcessCompleted": "Tallenna käsiteltynä",
			"overview": "Yhteenveto",
			"genericDownload": "Lataa",
			"downloadPDF": "Lataa PDF",
			"actions": "Toiminnot",
			"lastSaved": "Viimeksi tallennettu",
			"saveFailed": "Tallennus epäonnistui",
			"noInformationGiven": "Tietoa ei annettu",
			"showMore": "Näytä lisää",
			"hide": "Piilota",
			"show": "Näytä",
			"yes": "Kyllä",
			"no": "Ei",
			"and": "ja",

			// Report status texts
			"new": "Uusi",
			"inprogress": "Käsittelyssä",
			"proceedingInProgress": "Työn alla",
			"awaitingActions": "Toimenpiteet määrittämättä",
			"finished": "Valmis",
			"wontbedone": "Ei aiheuta toimenpiteitä",
			"invalid": "Virheellinen tila",

			// Report edit button texts
			"btnReportBeginProcessing": "Aloita tutkinta",
			"btnReportContinueProcessing": "Jatka tutkintaa",
			"btnReportBeginHandling": "Aloita käsittely",
			"btnReportContinueHandling": "Jatka käsittelyä",			

			// Table filter by status
			"tableFilterAll": "Kaikki",
			"tableFilterNew": "Uudet",
			"tableFilterInProgress": "Käsittelyssä",
			"tableFilterProceedingInProgress": "Työn alla",
			"tableFilterAwaitingActions": "Toimenpiteet määrittämättä",
			"tableFilterNoAction": "Ei aiheuta toimenpiteitä",
			"tableFilterFinished": "Valmiit",
			"tableFilterInvalid": "Virhe",

			// Language names
			"finnish": "Suomi",
			"fi": "Suomi",
			"swedish": "Ruotsi",
			"se": "Ruotsi",
			"english": "Englanti",
			"en": "Englanti",

			// Department sectors
			"industrial-food": "Teollisuus / Elintarvike",
			"industrial-chemistry": "Teollisuus / Kemia",
			"industrial-forest": "Teollisuus / Metsä",
			"industrial-metal-electronic": "Teollisuus / Metalli- ja elektroniikka",
			"industrial-other": "Teollisuus / Muu",
			"construction": "Rakentaminen",
			"service": "Palvelualat",
			"transport-logistics": "Kuljetus ja logistiikka",
			"farm-forest": "Maa- ja metsäala",
			"county": "Kunta",
			"seurakunta": "Seurakunta",
			"goverment": "Valtio",
			"other": "Muu",

			// Settings page texts
			"settings": "Asetukset",

			// Forgotten password page texts
			"forgottenPasswordPageTitle": "Unohtuneen salasanan vaihto",
			"forgottenPasswordInstruction": "Syötä ensin sähköpostiosoitteesi. Osoitteeseen lähetetään koodi, jolla pääset vaihtamaan salasanasi.",
			"tokenLabel": "Sähköpostiin lähettetty tarkistekoodi",
			"newPasswordLabel": "Uusi salasana",
			"repeatNewPasswordLabel": "Kirjoita salasana uudestaan",

			// Error messages
			"errInvalidDataFromServer": "Palvelin palautti odottamattoman vastauksen esitettyyn tietopyyntöön",
			"errPasswordTooShort": "Salasanan vähimmäispituus on 6 merkkiä.",
			"errPasswordsDontMatch": "Salasanat eivät täsmää",
			"errPasswordChangeTokenFailed": "Salasananvaihtokoodin lähetys ei onnistunut",
			"errCheckFormData": "Tarkista lomakkeen tiedot",
			"errYesNosIncomplete": "Kaikkiin kunnossa/korjattavaa -kenttiin tulee valita arvo, ennen kuin käsittely voidaan viimeistellä.",

			// Gate error codes
			"email_already_exists": "Sähköpostiosoite on jo käytössä",

			// Generic UI texts
			"cancelAndReturnToList": "Peruuta ja palaa listaukseen",
			"backToList": "Takaisin listaukseen",
			"numberAbbrv": "nro.",

			// Report types
			"reportType1": "Tapaturma",
			"reportType2": "Turvallisuushavainto",
			"reportType3": "Läheltä piti -tilanne",
			"reportType4": "Hyvä huomio",
			"reportType5": "Idea",
		},
	},
};
