import React, { memo } from "react";
import { Button } from "semantic-ui-react";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";

interface ISgTopLinksProps {
    color: SemanticCOLORS;
    homeLink: string;
    routeLinkWrapper: React.ComponentType<any>;
    gateLink: string;
}

const SgTopLinks: React.FC<ISgTopLinksProps> = ({ color, gateLink, routeLinkWrapper, homeLink }) => {

    const RouteLinkWrap = routeLinkWrapper;

    return (
        <p>
            <RouteLinkWrap to={homeLink}>
                <Button circular icon="home" color={color} size="large" />
            </RouteLinkWrap>
            <a href={gateLink}>
                <Button type="button" circular icon="th" color={color} size="large" />
            </a>
        </p>
    );
};

export default memo(SgTopLinks);
