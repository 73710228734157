import { call, put, takeLeading } from "redux-saga/effects";

import { IAccidentReportService } from "../../services/AccidentReportService";
import {
    IFetchAccidentReportAnonymous,
    createFetchAccReportAnonymousFailureAction,
    createFetchAccReportAnonymousSuccessAction,
    fetchAccidentReportAnonymous,
} from "../actions";
import { TResult } from "../../../sg-core/utils/Result";
import { IAccidentReport } from "../../interfaces/IAccidentReportData";

const makeFetchAccidentReportAnonymousSaga = (accidentReportService: IAccidentReportService) =>
    function* fetchAccidentReportAnonymousSaga(action: IFetchAccidentReportAnonymous) {
        const { payload: { id, guid } } = action;
        try {
            const result: TResult<IAccidentReport> = yield call(accidentReportService.getAccidentReportById, id, guid);
            if (result.isSuccess) {
                yield put(createFetchAccReportAnonymousSuccessAction(result.value));
            } else {
                console.error(result.error);
                if (result.isFailure) {
                    yield put(createFetchAccReportAnonymousFailureAction(id, result.error));
                } else {
                    yield put(createFetchAccReportAnonymousFailureAction(id, new Error("Invalid result value")));
                }
            }
        } catch (error) {
            console.error(error);
            yield put(createFetchAccReportAnonymousFailureAction(id, error as Error));
        }
    };

export default function create(accidentReportService: IAccidentReportService) {
    return takeLeading(
        fetchAccidentReportAnonymous,
        makeFetchAccidentReportAnonymousSaga(accidentReportService),
    );
}
