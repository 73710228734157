import React, { createContext, useContext, PropsWithChildren, useState, useEffect } from "react";
import { IFileUploadService, createFileUploadService } from "../services/FileUploadService";

const Context = createContext<IFileUploadService | undefined>(undefined);

export const useFileUploadService = () => useContext(Context);

interface IFileUploadProiderProps {
    url: string;
}

export const FileUploadProvider: React.FC<PropsWithChildren<IFileUploadProiderProps>> = ({ url, children }) => {

    const [service, setService] = useState<IFileUploadService>(createFileUploadService({ url }));

    useEffect(() => {
        setService(createFileUploadService({ url }));
    }, [url]);

    return (
        <Context.Provider value={service}>
            {children}
        </Context.Provider>
    );
};
