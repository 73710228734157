import { TLoadable, createUnloaded, createLoading, createLoaded, createLoadErrored } from "../../../sg-core/models/ILoadable";
import { TAuthAction } from "./authActions";
import { TSubmittable, createUnsubmitted, createSubmitting, createSubmitted, createSubmitErrored } from "../../../sg-core/models/ISubmittable";
import IRegistrationInfo from "../interfaces/IRegistrationInfo";

// Order is important! First element must be " = 0"
// These are semantically compared by value, so that, for example
// (TTKUser > AnonymoysWithCompanyId) === true
export enum AuthenticationType { AnonymousWithCompanyId, TTKUser, GateUser }

export interface IForgottenPasswordFormData {
    email: string;
    code: string;
    password: string;
}

export interface ITTKAuthState {
    authenticationStatus: TLoadable<AuthenticationType>;
    token: string | null;
    loggedInUserId: number | null;
    loggedInUserCompanyId: number | string | null;
    registration: TSubmittable<IRegistrationInfo>;
    passwordResetCode: TLoadable<boolean>;
    forgottenPasswordForm: TSubmittable<IForgottenPasswordFormData>;
}

export const defaultAuthState: ITTKAuthState = {
    authenticationStatus: createLoading(),
    token: null,
    loggedInUserId: null,
    loggedInUserCompanyId: null,
    registration: createUnsubmitted<IRegistrationInfo>({
        companyIndustry: 0,
        companyName: "",
        email: "",
        firstName: "",
        password: "",
        surname: "",
    }),
    passwordResetCode: createUnloaded(),
    forgottenPasswordForm: createUnsubmitted({
        email: "",
        code: "",
        password: "",
    }),
};

const authReducer = (state = defaultAuthState, action: TAuthAction): ITTKAuthState => {
    switch (action.type) {
        case "REQUEST_LOGIN": {
            return {
                ...state,
                authenticationStatus: createLoading(),
            };
        }
        case "REQUEST_INITIALIZE_LOGIN": {
            return { ...state, authenticationStatus: createLoading() };
        }
        case "SUCCESS_GATE_LOGIN": {
            return state;
        }
        case "SUCCESS_ANON_LOGIN": {
            return {
                ...state,
                authenticationStatus: createLoaded(AuthenticationType.AnonymousWithCompanyId),
                loggedInUserCompanyId: action.payload.companyId,
            };
        }
        case "SUCCESS_LOGIN": {
            return {
                ...state,
                authenticationStatus: createLoaded(AuthenticationType.TTKUser),
                loggedInUserId: action.payload.user.id,
                loggedInUserCompanyId: action.payload.companyId,
                token: action.payload.sid,
            };
        }
        case "FAILURE_LOGIN": {
            return {
                ...state,
                authenticationStatus: createLoadErrored(action.error),
                loggedInUserId: null,
                token: null,
            };
        }
        case "REGISTRATION_DATA_UPDATE": {
            return {
                ...state,
                registration: createUnsubmitted({
                    ...state.registration.data,
                    ...action.payload.updatedFields,
                }),
            };
        }
        case "REQUEST_REGISTER": {
            return {
                ...state,
                registration: createSubmitting(state.registration.data),
            };
        }
        case "SUCCESS_REGISTER": {
            return {
                ...state,
                registration: createSubmitted(state.registration.data),
            };
        }
        case "FAILURE_REGISTER": {
            return {
                ...state,
                registration: createSubmitErrored(state.registration.data, action.error),
            };
        }
        case "LOGOUT": {
            return {
                ...state,
                authenticationStatus: createUnloaded(),
                loggedInUserId: null,
                loggedInUserCompanyId: null,
                token: null,
            };
        }
        case "UPDATE_FORGOTTEN_PASSWORD_DATA": {
            return {
                ...state,
                forgottenPasswordForm: createUnsubmitted<IForgottenPasswordFormData>({
                    ...state.forgottenPasswordForm.data,
                    ...action.payload.data,
                }),
            };
        }
        case "REQUEST_FETCH_PASSWORD_RESET_CODE": {
            return {
                ...state,
                passwordResetCode: createLoading(),
            };
        }
        case "SUCCESS_FETCH_PASSWORD_RESET_CODE": {
            return {
                ...state,
                passwordResetCode: createLoaded(true),
            };
        }
        case "FAILURE_FETCH_PASSWORD_RESET_CODE": {
            return {
                ...state,
                passwordResetCode: createLoadErrored(action.error),
            };
        }
        case "REQUEST_CHANGE_PASSWORD_WITH_CODE": {
            return {
                ...state,
                forgottenPasswordForm: createSubmitting(state.forgottenPasswordForm.data),
            };
        }
        case "SUCCESS_CHANGE_PASSWORD_WITH_CODE": {
            return {
                ...state,
                forgottenPasswordForm: createSubmitted(state.forgottenPasswordForm.data),
            };
        }
        case "FAILURE_CHANGE_PASSWORD_WITH_CODE": {
            return {
                ...state,
                forgottenPasswordForm: createSubmitErrored(state.forgottenPasswordForm.data, action.error),
            };
        }
        case "RESET_CHANGE_PASSWORD_STATE": {
            return {
                ...state,
                passwordResetCode: createUnloaded(),
                forgottenPasswordForm: defaultAuthState.forgottenPasswordForm,
            };
        }
    }
    return state;
};

export default authReducer;
