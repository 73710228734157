import { ITTKAuthSuccessResponse } from "../interfaces/ITTKAuthResponse";
import ITTKUser from "../../interfaces/ITTKUser";
import { gateBooleanToBoolean } from "../../../sg-core/utils/GateSupport";

const AuthUserMapper = {
    authToDomain: (henkilo: ITTKAuthSuccessResponse["henkilo"]): ITTKUser => ({
        id: henkilo.id,
        admin: gateBooleanToBoolean(henkilo.on_paakayttaja),
        email: henkilo.meiliosoite,
        firstName: henkilo.etunimi,
        language: henkilo.kieli,
        surname: henkilo.sukunimi,
    }),
};

export default AuthUserMapper;
