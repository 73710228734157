import React, { useEffect, useMemo } from "react";

// Redux store
import { StoreProvider } from "./store";

import "semantic-ui-less/semantic.less";

// SG Service providers
import { FileUploadProvider } from "./sg-fileupload/context/FileUploadServiceContext";

// Application parts
import TurvaArviHeader from "./turva-arvi/genericComponents/Header";
import { Container } from "semantic-ui-react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import { RouteSwitch } from "./routing";
import { createRequestInitializeLoginAction } from "./turva-arvi/auth/store/authActions";
import { useDispatch } from "react-redux";
import { GATracker } from "./turva-arvi/GATracker";

const AuthInitializer: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(createRequestInitializeLoginAction());
    }, [dispatch]);

    return null;
};

const App: React.FC = () => {

  const history = useMemo(() => createBrowserHistory(), []);

  const fileUploadUrl = process.env.REACT_APP_FILE_UPLOAD_URL;
  if (!fileUploadUrl) {
    throw new Error("Build mishap (file upload url) missing.");
  }

  return (
    <StoreProvider>
      <AuthInitializer />
      <FileUploadProvider url={fileUploadUrl}>
        <Router history={history}>
          <TurvaArviHeader />
          <Container>
            <RouteSwitch />
          </Container>
          <footer>
            <div className="ui container">
            <div className="ui divider"></div>
              <a href="https://ttk-api-prod.app19.systemsgarden.com/data/Tapaturmatutka_tietosuojaseloste.pdf">
                  <i className="icon file pdf"></i> Tietosuojaseloste (PDF)
              </a>
            </div>
          </footer>
        </Router>
        <GATracker history={history} />
      </FileUploadProvider>
    </StoreProvider>
  );
};

export default App;
