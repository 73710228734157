import { all } from "redux-saga/effects";
import { IAccidentReportService } from "../../services/AccidentReportService";
import { ITTKUserService } from "../../services/TTKUserService";
import { ITTKCompanyService } from "../../services/TTKCompanyService";

// Saga creators
import createPostAccidentReportSaga from "./postAccidentReportFormSaga";
import createFetchAccidentReportSaga from "./fetchAccidentReportSaga";
import createFetchAccidentReportAnonymousSaga from "./fetchAccidentReportAnonymousSaga";
import createAutosaveAccidentReportSaga from "./autosaveAccidentReportSaga";
import createFetchReportPageSaga from "./fetchReportPageSaga";
import createFetchReportListSaga from "./fetchReportListSaga";
import createFetchUsersSaga from "./fetchUsersSaga";
import createSaveUserSaga from "./saveUserSaga";
import createFetchCurrentCompanySaga from "./fetchCurrentCompanySaga";
import createLoginSaga from "../../auth/store/loginSaga";
import createInitializeLoginSaga from "../../auth/store/initializeSaga";
import createLogoutSaga from "../../auth/store/logoutSaga";
import createFetchPasswordTokenSaga from "../../auth/store/fetchPasswdResetTokenSaga";
import createResetPasswordWithTokenSaga from "../../auth/store/resetPasswordWithTokenSaga";
import createRegisterSaga from "../../auth/store/registerSaga";
import createFetchIndustriesSaga from "./fetchIndustriesSaga";
import createSaveCompanySaga from "./saveCompanySaga";
import createDeleteDepartmentSaga from "./deleteCompanyDepartmentSaga";
import createFetchProceedingsSaga from "./fetchProceedingsListSaga";
import createFetchProceedingSaga from "./fetchProceedingSaga";
import createSaveProceedingSaga from "./saveProceedingSaga";
import createAfterProceedingSavedSaga from "./afterProceedingSavedSaga";
import createInviteUserSaga from "./inviteUserSaga";
import createPostAccidentReportInquirySaga from "./postAccidentReportInquirySaga";
import createUpdateAccidentReportFormAnonymousSaga from "./updateAccidentReportFormAnonymousSaga";

import { ITTKAuthService } from "../../auth/services/ITTKAuthService";
import { ISgApiService } from "../../../sg-core/services/ApiService";
import { IProceedingsService } from "../../services/ProceedingsService";

export default function createTurvaArviSagas(
    accidentReportService: IAccidentReportService,
    userService: ITTKUserService,
    companyService: ITTKCompanyService,
    authService: ITTKAuthService,
    sgApiService: ISgApiService,
    proceedingService: IProceedingsService,
) {
    return function* turvaArviSagas() {
        yield all([
            createPostAccidentReportSaga(accidentReportService),
            createFetchAccidentReportSaga(accidentReportService),
            createFetchAccidentReportAnonymousSaga(accidentReportService),
            createAutosaveAccidentReportSaga(1500, accidentReportService),
            createFetchReportListSaga(accidentReportService),
            createFetchReportPageSaga(accidentReportService),
            createFetchUsersSaga(userService),
            createSaveUserSaga(userService),
            createFetchCurrentCompanySaga(companyService),
            createLoginSaga(authService),
            createInitializeLoginSaga(authService),
            createLogoutSaga(authService),
            createRegisterSaga(authService),
            createFetchIndustriesSaga(sgApiService),
            createSaveCompanySaga(companyService),
            createDeleteDepartmentSaga(companyService),
            createFetchProceedingsSaga(proceedingService),
            createFetchPasswordTokenSaga(authService),
            createResetPasswordWithTokenSaga(authService),
            createFetchProceedingSaga(proceedingService),
            createSaveProceedingSaga(proceedingService),
            createAfterProceedingSavedSaga(),
            createInviteUserSaga(userService),
            createPostAccidentReportInquirySaga(accidentReportService),
            createUpdateAccidentReportFormAnonymousSaga(accidentReportService)
        ]);
    };
}
