import ITTKUser from "../../interfaces/ITTKUser";
import IRegistrationInfo from "../interfaces/IRegistrationInfo";
import { IForgottenPasswordFormData } from "./authReducer";

export const requestLogin = "REQUEST_LOGIN";

export const requestInitializeLogin = "REQUEST_INITIALIZE_LOGIN";
const successLogin = "SUCCESS_LOGIN";
const successGateLogin = "SUCCESS_GATE_LOGIN";
const successAnonLogin = "SUCCESS_ANON_LOGIN";
const failureLogin = "FAILURE_LOGIN";

export const logout = "LOGOUT";
const registrationDataUpdate = "REGISTRATION_DATA_UPDATE";

export const requestRegister = "REQUEST_REGISTER";
const successRegister = "SUCCESS_REGISTER";
const failureRegister = "FAILURE_REGISTER";

const updateForgottenPasswordFormData = "UPDATE_FORGOTTEN_PASSWORD_DATA";

export const requestFetchPasswordResetCode = "REQUEST_FETCH_PASSWORD_RESET_CODE";
const successFetchPasswordResetCode = "SUCCESS_FETCH_PASSWORD_RESET_CODE";
const failureFetchPasswordResetCode = "FAILURE_FETCH_PASSWORD_RESET_CODE";

export const requestChangePasswordWithCode = "REQUEST_CHANGE_PASSWORD_WITH_CODE";
const successChangePasswordWithCode = "SUCCESS_CHANGE_PASSWORD_WITH_CODE";
const failureChangePasswordWithCode = "FAILURE_CHANGE_PASSWORD_WITH_CODE";
const resetChangePasswordState = "RESET_CHANGE_PASSWORD_STATE";

// TYPES

export interface IRequestLogin {
    type: typeof requestLogin;
    payload: {
        username: string;
        password: string;
    };
}

interface ISuccessLogin {
    type: typeof successLogin;
    payload: {
        sid: string;
        user: ITTKUser;
        companyId: number;
    };
}

interface ISuccessGateLogin {
    type: typeof successGateLogin;
}

interface ISuccessAnonLogin {
    type: typeof successAnonLogin;
    payload: {
        companyId: string;
    };
}

interface IFailureLogin {
    type: typeof failureLogin;
    error: Error;
}

interface IRequestInitializeLogin {
    type: typeof requestInitializeLogin;
}

export interface ILogoutAction {
    type: typeof logout;
}

interface IRegistrationDataUpdate {
    type: typeof registrationDataUpdate;
    payload: { updatedFields: Partial<IRegistrationInfo> };
}

interface IRequestRegister {
    type: typeof requestRegister;
}

interface ISuccessRegister {
    type: typeof successRegister;
}

interface IFailureRegister {
    type: typeof failureRegister;
    error: Error;
}

interface IUpdateForgottenPasswordData {
    type: typeof updateForgottenPasswordFormData;
    payload: {
        data: Partial<IForgottenPasswordFormData>;
    };
}

export interface IRequestFetchPasswordResetCode {
    type: typeof requestFetchPasswordResetCode;
    payload: { email: string };
}

interface ISuccessFetchPasswordResetCode {
    type: typeof successFetchPasswordResetCode;
}

interface IFailureFetchPasswordResetCode {
    type: typeof failureFetchPasswordResetCode;
    error: Error;
}

export interface IRequestChangePasswordWithCode {
    type: typeof requestChangePasswordWithCode;
}

interface ISuccessChangePasswordWithCode {
    type: typeof successChangePasswordWithCode;
}

interface IFailureChangePasswordWithCode {
    type: typeof failureChangePasswordWithCode;
    error: Error;
}

interface IResetChangePasswordState {
    type: typeof resetChangePasswordState;
}

export type TAuthAction
    = IRequestLogin
    | ISuccessLogin
    | ISuccessAnonLogin
    | ISuccessGateLogin
    | IFailureLogin
    | IRequestInitializeLogin
    | ILogoutAction
    | IRegistrationDataUpdate
    | IRequestRegister
    | ISuccessRegister
    | IFailureRegister
    | IUpdateForgottenPasswordData
    | IRequestFetchPasswordResetCode
    | ISuccessFetchPasswordResetCode
    | IFailureFetchPasswordResetCode
    | IRequestChangePasswordWithCode
    | ISuccessChangePasswordWithCode
    | IFailureChangePasswordWithCode
    | IResetChangePasswordState;

// CREATORS

export const createRequestLoginAction = (username: string, password: string): IRequestLogin => ({
    type: requestLogin,
    payload: { username, password },
});

export const createRequestInitializeLoginAction = (): IRequestInitializeLogin => ({
    type: requestInitializeLogin,
});

export const createSuccessLoginAction = (sid: string, user: ITTKUser, companyId: number): ISuccessLogin => ({
    type: successLogin,
    payload: { sid, user, companyId },
});

export const createSuccessAnonLoginAction = (companyId: string): ISuccessAnonLogin => ({
    type: successAnonLogin,
    payload: { companyId },
});

export const createFailureLoginAction = (error: Error): IFailureLogin => ({
    type: failureLogin,
    error,
});

export const createLogoutAction = (): ILogoutAction => ({
    type: logout,
});

export const createRegistrationDataUpdatedAction =
(updatedFields: Partial<IRegistrationInfo>): IRegistrationDataUpdate => ({
    type: registrationDataUpdate,
    payload: { updatedFields },
});

export const createRequestRegisterAction = (): IRequestRegister => ({
    type: requestRegister,
});

export const createSuccessRegisterAction = (): ISuccessRegister => ({
    type: successRegister,
});

export const createFailureRegisterAction = (error: Error): IFailureRegister => ({
    type: failureRegister,
    error,
});

export const createUpdateForgottenPasswordDataAction =
(data: Partial<{ email: string; code: string; password: string; }>): IUpdateForgottenPasswordData => {
    return {
        type: updateForgottenPasswordFormData,
        payload: { data },
    };
};

export const createRequestFetchPasswordResetCode = (email: string): IRequestFetchPasswordResetCode => ({
    type: requestFetchPasswordResetCode,
    payload: { email },
});

export const createSuccessRequestFetchPasswordResetCode = (): ISuccessFetchPasswordResetCode => ({
    type: successFetchPasswordResetCode,
});

export const createFailureRequestFetchPasswordResetCode = (error: Error): IFailureFetchPasswordResetCode => ({
    type: failureFetchPasswordResetCode,
    error,
});

export const createRequestChangePasswordWithCode = (): IRequestChangePasswordWithCode => ({
    type: requestChangePasswordWithCode,
});

export const createSuccessChangePasswordWithCode = (): ISuccessChangePasswordWithCode => ({
    type: successChangePasswordWithCode,
});

export const createFailureChangePasswordWithCode = (error: Error): IFailureChangePasswordWithCode => ({
    type: failureChangePasswordWithCode,
    error,
});

export const createResetChangePasswordState = (): IResetChangePasswordState => ({
    type: resetChangePasswordState,
});
