import React, { PropsWithChildren, memo } from "react";
import { Link } from "react-router-dom";
import { getRouteHrefById, RouteId } from "../../routing";

interface IRouteLinkProps {
    id: keyof typeof RouteId;
    params?: { [key: string]: any };
}

const RouteLink: React.FC<PropsWithChildren<IRouteLinkProps>> = ({ id, params, children }) => {
    const href = getRouteHrefById(id, params);

    if (!href) {
        throw new Error(`Invalid route ID supplied to link: '${id}'`);
    }

    return <Link to={href}>{children}</Link>;
};

export default memo(RouteLink);
