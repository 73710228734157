import { IAccidentReportTableFilters } from "../../interfaces/IAccidentReportListing";
import { IAccidentReport, IAccidentReportInList } from "../../interfaces/IAccidentReportData";

const accidentReportFiltersChanged = "ACCIDENT_REPORT_LIST_FILTERS_CHANGED";
const accidentReportSortingChanged = "ACCIDENT_REPORT_LIST_SORTING_CHANGED";
const reportListPageChanged = "REPORT_LIST_PAGE_CHANGED";

interface IAccidentReportFiltersChanged {
    type: typeof accidentReportFiltersChanged;
    payload: {
        filters: IAccidentReportTableFilters;
    };
}

interface IReportSortingChanged {
    type: typeof accidentReportSortingChanged;
    payload: {
        sortBy: string;
        descending: boolean;
    };
}

interface IReportListPageChanged {
    type: typeof reportListPageChanged;
    payload: {
        newPage: number;
    };
}

// PAGE LOADING ACTIONS
export const requestReportListLoad = "REQUEST_REPORT_LIST_LOAD";
const successReportListLoad = "SUCCESS_REPORT_LIST_LOAD";
const failureReportListLoad = "FAILURE_REPORT_LIST_LOAD";
export const requestPageLoad = "REQUEST_REPORT_LIST_PAGE_LOAD";
const successPageLoad = "SUCCESS_REPORT_LIST_PAGE_LOAD";
const failurePageLoad = "FAILURE_REPORT_LIST_PAGE_LOAD";

interface IRequestReportListLoadAction {
    type: typeof requestReportListLoad;
}

interface ISuccessReportListLoadAction {
    type: typeof successReportListLoad;
    payload: { list: IAccidentReportInList[] };
}

interface IFailureReportListLoadAction {
    type: typeof failureReportListLoad;
    error: Error;
}

export interface IRequestPageLoadAction {
    type: typeof requestPageLoad;
    payload: { index: number };
}

interface ISuccessPageLoadAction {
    type: typeof successPageLoad;
    payload: {
        pageIndex: number;
        page: IAccidentReport[];
        totalPages: number;
    };
}

interface IFailurePageLoadAction {
    type: typeof failurePageLoad;
    payload: {
        error: Error;
    };
}

// TYPE

export type TAccidentReportListAction
    = IAccidentReportFiltersChanged
    | IReportSortingChanged
    | IReportListPageChanged
    | IRequestPageLoadAction
    | ISuccessPageLoadAction
    | IFailurePageLoadAction
    | IRequestReportListLoadAction
    | ISuccessReportListLoadAction
    | IFailureReportListLoadAction;

// ACTION CREATORS

export const createAccidentReportFiltersChangedAction
    = (filters: IAccidentReportTableFilters): IAccidentReportFiltersChanged => ({
        type: accidentReportFiltersChanged,
        payload: {
            filters,
        },
    });

export const createReportSortingChanged = (sortBy: string, descending: boolean = false): IReportSortingChanged => ({
    type: accidentReportSortingChanged,
    payload: { sortBy, descending },
});

export const createReportListPageChanged = (newPage: number): IReportListPageChanged => ({
    type: reportListPageChanged,
    payload: { newPage },
});

// Request page action creators

export const createRequestFetchReportListAction = (): IRequestReportListLoadAction => ({
    type: requestReportListLoad,
});

export const createSuccessFetchReportListAction = (list: IAccidentReportInList[]): ISuccessReportListLoadAction => ({
    type: successReportListLoad,
    payload: { list },
});

export const createFailureFetchReportListAction = (error: Error): IFailureReportListLoadAction => ({
    type: failureReportListLoad,
    error,
});

export const createRequestFetchReportListPage = (index: number): IRequestPageLoadAction => ({
    type: requestPageLoad,
    payload: { index },
});

// tslint:disable-next-line: max-line-length
export const createSuccessFetchReportListPage = (pageIndex: number, page: IAccidentReport[], totalPages: number): ISuccessPageLoadAction => ({
    type: successPageLoad,
    payload: { pageIndex, page, totalPages },
});

export const createFailureFetchReportListPage = (error: Error): IFailurePageLoadAction => ({
    type: failurePageLoad,
    payload: { error },
});
