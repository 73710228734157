import React, { ErrorInfo } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";

interface IErrorState {
    hasError: boolean;
    error: Error | null;
}

class RouteErrorBoundary extends React.Component<WithTranslation, IErrorState> {
    constructor(props: WithTranslation) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
        }
    }

    static getDerivedStateFromError(error: Error): IErrorState {
        return {
            hasError: true,
            error: error,
        }
    }

    componentDidCatch(error: Error, logInfo: ErrorInfo) {
        console.error(logInfo);
        console.error(error);
    }

    render() {
        const { t } = this.props;

        if (this.state.hasError) {
            return (
                <Message negative>
                    <Message.Header>{t("routeErrorTitle")}</Message.Header>
                    <p>{t("routeErrorDescription")}</p>
                </Message>
            );
        }

        return this.props.children;
    }
}

export default withTranslation()(RouteErrorBoundary);