import { takeLeading, call, put } from "redux-saga/effects";
import { ITTKAuthService } from "../services/ITTKAuthService";
import { requestLogin, createFailureLoginAction, IRequestLogin, createSuccessLoginAction } from "./authActions";
import { TResult } from "../../../sg-core/utils/Result";
import ITTKAuthInfo from "../interfaces/ITTKAuthInfo";

const createLoginSaga = (ttkAuth: ITTKAuthService) =>
    function* saga(action: IRequestLogin) {
        try {
            const { username, password } = action.payload;

            const loginResponse: TResult<ITTKAuthInfo> = yield call(ttkAuth.login, username, password);

            if (loginResponse.isSuccess === true) {
                const { sid, user, companyId } = loginResponse.value;
                yield put(createSuccessLoginAction(sid, user, companyId ));
            } else {
                yield put(createFailureLoginAction(loginResponse.error));
            }
        } catch (e) {
            yield put(createFailureLoginAction(e));
        }
    };

export default function(ttkAuth: ITTKAuthService) {
    return takeLeading(
        requestLogin,
        createLoginSaga(ttkAuth),
    );
}
