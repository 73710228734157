import { call, put, takeLeading, select } from "redux-saga/effects";
import { postAccidentReportFormAction, createAccidentReportFormPostSuccess } from "../actions";
import IAccidentReportFormData, { IAccidentReport } from "../../interfaces/IAccidentReportData";
import { IAccidentReportService } from "../../services/AccidentReportService";
import { TResult } from "../../../sg-core/utils/Result";
import { selectAccidentReportFormData } from "../selectors";
import { selectLoggedInUserCompanyId } from "../../auth/store/authSelectors";
import { TSubmittable } from "../../../sg-core/models/ISubmittable";

export const makePostAccidentReportFormSaga = (accidentReportService: IAccidentReportService) => function* postForm(): any {
    try {
        const formData: TSubmittable<IAccidentReportFormData> = yield select(selectAccidentReportFormData);
        const companyId = yield select(selectLoggedInUserCompanyId);

        const result: TResult<IAccidentReport> = yield call(
            accidentReportService.createAccidentReport,
            companyId,
            formData.data,
        );

        if (result.isSuccess) {
            yield put(createAccidentReportFormPostSuccess(result.value));
        } else {
            console.error(result.error);
        }
    } catch (e) {
        console.error("Error when posting form", e);
    }
};

export default function create(accidentReportService: IAccidentReportService) {
    return takeLeading(
        postAccidentReportFormAction,
        makePostAccidentReportFormSaga(accidentReportService),
    );
}
