import { useDispatch } from "react-redux";
import { useCallback } from "react";

import { createLogoutAction } from "../store/authActions";

const useLogout = () => {
    const dispatch = useDispatch();
    return useCallback(
        () => dispatch(createLogoutAction()),
        [dispatch]
    );
};

export default useLogout;
