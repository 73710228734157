import { IProceedingDataInListDTO, IProceedingDataInList, EProceedingStatus, IProceedingDataDTO, IProceedingsData, IProceedingBase64Data } from "../interfaces/IProceedingData";
import { gateNonstandardDateToDate } from "../../sg-core/utils/GateSupport";

const StatusMap = {
    kasittelyssa: EProceedingStatus.InProgress,
    valmis: EProceedingStatus.Done,
    ei_toimenpiteita: EProceedingStatus.WontBeDone,
};

const StatusMapDomainToApi = {
    [EProceedingStatus.InProgress]: "kasittelyssa",
    [EProceedingStatus.Done]: "valmis",
    [EProceedingStatus.WontBeDone]: "ei_toimenpiteita",
    [EProceedingStatus.Invalid]: undefined,
};

const isProceedingStatus = (str: string): str is EProceedingStatus => {
    return [
        EProceedingStatus.InProgress,
        EProceedingStatus.Done,
        EProceedingStatus.WontBeDone,
    ].includes(str as any);
};

const ProceedingMapper = {
    listApiToDomain: (api: IProceedingDataInListDTO): IProceedingDataInList => {

        const mappedStatus: any = StatusMap[api.tila as keyof typeof StatusMap];
        const status = isProceedingStatus(mappedStatus)
                        ? mappedStatus
                        : EProceedingStatus.Invalid;

        const writeupDate = gateNonstandardDateToDate(api.kirjauspaiva) || new Date(1970, 0, 1);

        const base64data: IProceedingBase64Data = api.base64data
                            ? JSON.parse(atob(api.base64data))
                            : {};

        return {
            id: api.id,
            reportId: api.ilmoitus,
            reportRowId: api.ilmoitusrivi,
            reportRowType: api.ilmoitusrivityyppi,
            responsibility: base64data.responsibility || "",
            status,
            writeupDate,
        };
    },
    apiToDomain: (api: IProceedingDataDTO): IProceedingsData => {

        const base64data: IProceedingBase64Data = api.base64data
                            ? JSON.parse(atob(api.base64data))
                            : {};

        return {
            ...ProceedingMapper.listApiToDomain(api),
            additionalInfo: base64data.additionalInfo || "",
            responsibility: base64data.responsibility || "",
            dueDate: base64data.dueDateText,
        };
    },
    domainToApi: (domain: IProceedingsData): Partial<IProceedingDataDTO> => {
        const formData: IProceedingBase64Data = {
            additionalInfo: domain.additionalInfo,
            responsibility: domain.responsibility,
            dueDateText: domain.dueDate,
        };
        const base64data = btoa(JSON.stringify(formData));

        return {
            id: domain.id,
            tila: StatusMapDomainToApi[domain.status],
            base64data,
        };
    },
};

export default ProceedingMapper;
