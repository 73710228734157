import { takeLeading, call, put, select } from "redux-saga/effects";
import { ITTKAuthService } from "../services/ITTKAuthService";
import {
    requestChangePasswordWithCode,
    createFailureChangePasswordWithCode,
    createSuccessChangePasswordWithCode,
} from "./authActions";
import { TResult } from "../../../sg-core/utils/Result";
import { selectForgottenPasswordFormData } from "./authSelectors";
import { TSubmittable } from "../../../sg-core/models/ISubmittable";
import { IForgottenPasswordFormData } from "./authReducer";

const createUpdatePasswordWithTokenSaga = (authService: ITTKAuthService) =>
    function* saga() {
        try {
            const formData: TSubmittable<IForgottenPasswordFormData> = yield select(selectForgottenPasswordFormData);
            const { code, email, password } = formData.data;
            const result: TResult<any> = yield call(
                authService.updatePasswordWithCode,
                email,
                password,
                code,
            );

            if (result.isFailure) {
                yield put(createFailureChangePasswordWithCode(result.error));
            } else {
                yield put(createSuccessChangePasswordWithCode());
            }
        } catch (error) {
            yield put(createFailureChangePasswordWithCode(error));
        }
    };

export default function(authService: ITTKAuthService) {
    return takeLeading(
        requestChangePasswordWithCode,
        createUpdatePasswordWithTokenSaga(authService),
    );
}
