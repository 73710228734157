import React, { memo, PropsWithChildren } from "react";
import SgSiteTop from "./SgSiteTop";
import SgNavbar from "./SgNavbar";
import { MenuProps } from "semantic-ui-react";
import { ISgHeaderMenuItem } from "../interfaces";

interface ISgHeaderProps {
    menuColor: MenuProps["color"];
    logoUrl?: string;
    menuItems: ISgHeaderMenuItem[];
    menuHeaderItems: ISgHeaderMenuItem[];
    topRightContent: React.ComponentType;
    renderMenuItem?: (item: ISgHeaderMenuItem) => JSX.Element;
    logoWrapper?: React.ComponentType<PropsWithChildren<{}>>;
}

const SgHeaderView: React.FC<ISgHeaderProps> = (props) => {

    const { menuColor, menuItems, menuHeaderItems, logoUrl, renderMenuItem, logoWrapper, topRightContent } = props;

    return (
        <header className="sg-header">
            <section className="sg-header-top">
                <SgSiteTop
                    logoUrl={logoUrl}
                    color={menuColor}
                    logoWrapper={logoWrapper}
                    topRightContent={topRightContent}
                />
            </section>
            <nav className="sg-header-nav">
                <SgNavbar
                    color={menuColor}
                    menuItems={menuItems}
                    menuHeaderItems={menuHeaderItems}
                    renderMenuItem={renderMenuItem}
                />
            </nav>
        </header>
    );
};

export default memo(SgHeaderView);
