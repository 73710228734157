import { takeLeading, call, put, select } from "redux-saga/effects";
import { ITTKAuthService } from "../services/ITTKAuthService";
import { createFailureRegisterAction, createSuccessRegisterAction, requestRegister } from "./authActions";
import { selectRegistrationData } from "./authSelectors";
import { TSubmittable } from "../../../sg-core/models/ISubmittable";
import IRegistrationInfo from "../interfaces/IRegistrationInfo";
import { TResult } from "../../../sg-core/utils/Result";

const createRegisterSaga = (authService: ITTKAuthService) =>
    function* saga() {
        try {
            const data: TSubmittable<IRegistrationInfo> = yield select(selectRegistrationData);

            const result: TResult<any> = yield call(authService.register, data.data);

            if (result.isFailure) {
                yield put(createFailureRegisterAction(result.error));
            } else {
                yield put(createSuccessRegisterAction());
            }
        } catch (error) {
            yield put(createFailureRegisterAction(error));
        }
    };

export default function(authService: ITTKAuthService) {
    return takeLeading(
        requestRegister,
        createRegisterSaga(authService),
    );
}
