import React, { lazy, Suspense } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import RouteErrorBoundary from "./sg-core/components/RouteErrorBoundary";
import { TurvaArviRouteId } from "./turva-arvi/routes";

// AUTHENTICATION
import AuthenticatedRoute from "./turva-arvi/auth/components/AuthenticatedRoute";
import { AuthenticationType } from "./turva-arvi/auth/store/authReducer";

// VIEWS
import FrontPage from "./turva-arvi/views/FrontPage";
import LoginView from "./turva-arvi/views/LoginPage";
import { Segment } from "semantic-ui-react";

const AccidentReportView = lazy(() => import(/* webpackChunkName: "report-view" */"./turva-arvi/views/AccidentReportView"));
const AccidentReportFormView = lazy(() => import(/* webpackChunkName: "report-form" */"./turva-arvi/views/AccidentReportFormView"));
const AccidentReportEditingView = lazy(() => import(/* webpackChunkName: "report-editing" */"./turva-arvi/views/AccidentReportEditingView"));
const AccidentReportListingView = lazy(() => import(/* webpackChunkName: "report-list" */"./turva-arvi/views/AccidentReportListingView"));
const AccidentReportProcessView = lazy(() => import(/* webpackChunkName: "report-process" */"./turva-arvi/views/AccidentReportProcessView"));
const AccidentReportInquiryView = lazy(() => import(/* webpackChunkName: "report-inquiry" */"./turva-arvi/views/AccidentReportInquiryView"));
const ProceedingView = lazy(() => import(/* webpackChunkName: "proceeding" */"./turva-arvi/views/ProceedingPage"));
const ProceedingsListView = lazy(() => import(/* webpackChunkName: "proceeding-list" */"./turva-arvi/views/ProceedingsList"));
const ProceedingViewReport = lazy(() => import(/* webpackChunkName: "proceeding-view" */"./turva-arvi/views/ProceedingReportView"));
const SettingsView = lazy(() => import(/* webpackChunkName: "settings" */"./turva-arvi/views/SettingsView"));
const RegistrationView = lazy(() => import(/* webpackChunkName: "registration" */"./turva-arvi/views/RegistrationPage"));
const PasswordResetPage = lazy(() => import(/* webpackChunkName: "pass-reset" */"./turva-arvi/views/PasswordResetPage"));
const InviteUserPage = lazy(() => import(/* webpackChunkName: "invite" */"./turva-arvi/views/InvitePage"));

const PrintReportsPage = lazy(() => import("./turva-arvi/views/printViews/ReportsReport"));
const PrintReportPage = lazy(() => import("./turva-arvi/views/printViews/SingleReportReport"));
const PrintReportProcessPage = lazy(() => import("./turva-arvi/views/printViews/SingleReportFullReport"));

export interface IRouteDefinition {
    readonly id: string;
    readonly hrefTemplate?: string;
    readonly path: string;
    readonly exact: boolean;
    readonly render: React.FC<any>;
    readonly requireAuth?: true;
    readonly requiredAuthenticationLevel?: AuthenticationType;
}

// Do the same for route ids. Route ids are used for easy in-app link generation
export const RouteId = {
    ...TurvaArviRouteId,
};

// Import and concatenate required routes from our modules
export const routes: IRouteDefinition[] = [
    {
        id: RouteId.FrontPage,
        exact: true,
        path: "/",
        render: () => <FrontPage />,
        requireAuth: true,
        requiredAuthenticationLevel: AuthenticationType.AnonymousWithCompanyId,
    }, {
        id: RouteId.AccidentReportForm,
        exact: true,
        path: "/tapaturmailmoitus/lomake",
        render: () => <AccidentReportFormView />,
    }, {
        id: RouteId.AccidentReportEditing,
        exact: true,
        path: "/tapaturmailmoitus/lomake/:accidentReportId/:guid",
        render: () => <AccidentReportEditingView />,

    }, {
        id: RouteId.ReportListing,
        exact: true,
        path: "/tapaturmailmoitus/listaus",
        render: () => <AccidentReportListingView />,
        requireAuth: true,
    }, {
        id: RouteId.AccidentReportInfo,
        exact: true,
        path: "/tapaturmailmoitus/:accidentReportId",
        render: () => <AccidentReportView />,
        requireAuth: true,
    }, {
        id: RouteId.AccidentReportProcessView,
        exact: true,
        path: "/tapaturmailmoitus/:accidentReportId/kasittely",
        render: () => <AccidentReportProcessView />,
        requireAuth: true,
    }, {
        id: RouteId.AccidentReportInquiryView,
        exact: true,
        path: "/tapaturmailmoitus/:accidentReportId/tiedustelu",
        render: () => <AccidentReportInquiryView />,
        requireAuth: true,
    }, {
        id: RouteId.ProceedingListView,
        exact: true,
        path: "/toimenpiteet",
        render: () => <ProceedingsListView />,
        requireAuth: true,
    }, {
        id: RouteId.ProceedingInstanceView,
        exact: true,
        path: "/toimenpiteet/:proceedingId",
        render: () => <ProceedingView />,
        requireAuth: true,
    }, {
        id: RouteId.PriceedingInstanceReportView,
        exact: true,
        path: "/toimenpiteet/:proceedingId/ilmoitus",
        render: () => <ProceedingViewReport />,
        requireAuth: true,
    }, {
        id: RouteId.Settings,
        exact: true,
        path: "/asetukset",
        render: () => <SettingsView />,
        requireAuth: true,
    }, {
        id: RouteId.Login,
        exact: true,
        path: "/kirjaudu",
        render: () => <LoginView />,
    }, {
        id: RouteId.Register,
        exact: true,
        path: "/rekisteroidy",
        render: () => <RegistrationView />,
    }, {
        id: RouteId.ForgottenPassword,
        exact: true,
        path: "/kirjaudu/unohtunut-salasana/:emailHint?",
        hrefTemplate: "/kirjaudu/unohtunut-salasana/:emailHint",
        render: () => <PasswordResetPage />,
    }, {
        id: RouteId.InviteUser,
        exact: true,
        path: "/kutsu/:inviteId",
        render: () => <InviteUserPage />,
    },

    // PRINT VIEWS

    {
        id: RouteId.PrintReportList,
        exact: true,
        path: "/tulostus/ilmoituslista/:dataId",
        render: () => <PrintReportsPage />,
    }, {
        id: RouteId.PrintReportInstance,
        exact: true,
        path: "/tulostus/ilmoitus/:dataId",
        render: () => <PrintReportPage />,
    }, {
        id: RouteId.PrintReportProcess,
        exact: true,
        path: "/tulostus/ilmoitus/:dataId/full",
        render: () => <PrintReportProcessPage />,
    },
];

export function getRouteHref(route: IRouteDefinition, params: { [key: string]: any } = {}): string {
    const paramNames = Object.keys(params);

    const routeTemplate = route.hrefTemplate || route.path;

    return paramNames.reduce(
        (href, paramName) => href.replace(`:${paramName}`, params[paramName]),
        routeTemplate,
    );
}

/**
 * Return the appropriate Link href for the given route id with the applied
 * parameters. Returns null if the route, for some reason, is not found.
 *
 * @export
 * @param {keyof typeof RouteId} id The RouteId of the route we're linking to
 * @param {{ [key: string]: any }} params Object containing parameters
 * @returns {(string | null)}
 */
export function getRouteHrefById(id: keyof typeof RouteId, params?: { [key: string]: any }): string | undefined {
    const route = routes.find((r) => r.id === RouteId[id]);

    if (route) {
        return getRouteHref(route, params);
    } else {
        return undefined;
    }
}

/**
 * Renders a <Route> component for the given IRouteDefinition
 * @param route The route to render
 */
const renderRoute = (route: IRouteDefinition): JSX.Element => {
    if (route.requireAuth) {
        return (
            <AuthenticatedRoute
                key={route.id}
                {...route}
            />
        );
    }
    return (
        <Route
            key={route.id}
            path={route.path}
            exact={route.exact}
            render={route.render}
            />
    );
};

const routeLoadingFallback = <Segment loading padded="very" />;

export const RouteSwitch: React.FC = () => (
    <RouteErrorBoundary>
        <Suspense fallback={routeLoadingFallback}>
            <Switch>
                { routes.map(renderRoute) }
                <Route>
                    <Redirect to={getRouteHrefById("FrontPage", {}) || "/"} />
                </Route>
            </Switch>
        </Suspense>
    </RouteErrorBoundary>
);
