import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import IReduxState from "../../store/IState";
import { TSubmittable, createUnsubmitted } from "../../sg-core/models/ISubmittable";
import IAccidentReportFormData, { IAccidentReport, IAccidentReportInquiry } from "../interfaces/IAccidentReportData";
import { TLoadable, createUnloaded, isUnloaded, isLoaded, createLoaded, createLoading, createLoadErrored } from "../../sg-core/models/ILoadable";
import { createFetchAccReportAction, createFetchAccReportAnonymousAction } from "./actions";
import { IReportListingState } from "./reducers/accidentReportListReducer";
import { createRequestFetchReportListPage, createRequestFetchReportListAction } from "./actions/accidentReportList";
import ITTKUser from "../interfaces/ITTKUser";
import { createUserMarkedForEditingAction } from "./actions/userActions";
import ITTKCompany, { ITTKCompanyDepartment } from "../interfaces/ITTKCompany";
import { createRequestFetchCurrentCompanyAction, createMarkCurrentCompanyForEditingAction } from "./actions/companyActions";
import { createRequestIndustryListAction } from "./actions/industryActions";
import { createRequestFetchProceedingsAction, createRequestFetchProceedingAction, createMarkProceedingForEditingAction } from "./actions/proceedingActions";
import { IProceedingsData } from "../interfaces/IProceedingData";
import { useMemo } from "react";

export const selectTurvaArviState = (state: IReduxState) => state.turvaArvi;

export const selectTurvaArviReportsState = createSelector(
    selectTurvaArviState,
    (state) => state.reports,
);

export const selectAccidentReportFormData = createSelector(
    selectTurvaArviReportsState,
    (reports) => reports.accidentReportForm,
);

export const selectAccidentReports = createSelector(
    selectTurvaArviReportsState,
    (reports) => reports.accidentReports,
);

export const selectEditedAccidentReports = createSelector(
    selectTurvaArviReportsState,
    (reports) => reports.accidentReportsEdited,
);

export const selectAccidentReportInquiry = createSelector(
    selectTurvaArviReportsState,
    (reports) => reports.accidentReportInquiry,
);

export const selectTurvaArviReportListingState = createSelector(
    selectTurvaArviState,
    (state) => state.reportListing,
);

export const selectTurvaArviIndustriesState = createSelector(
    selectTurvaArviState,
    (state) => state.industries,
);

export const selectTurvaArviIndustriesList = createSelector(
    selectTurvaArviIndustriesState,
    (industries) => industries.list,
);

export const selectTurvaArviProceedingsState = createSelector(
    selectTurvaArviState,
    (state) => state.proceedings,
);

export const selectTurvaArviProceedingsList = createSelector(
    selectTurvaArviProceedingsState,
    (proceedings) => proceedings.list,
);

export const selectTurvaArviProceedingsFilters = createSelector(
    selectTurvaArviProceedingsState,
    (proceedings) => proceedings.filters,
);

export const selectLoggedInUserId = createSelector(
    selectTurvaArviState,
    (state) => state.authentication.loggedInUserId,
);

export const selectTurvaArviFilteredProceedingList = createSelector(
    selectTurvaArviProceedingsList,
    selectTurvaArviProceedingsFilters,
    (list, { status }) => {
        if (isLoaded(list) && status !== "all") {
            return createLoaded(list.data.filter((proceeding) => {
                return proceeding.status === status;
            }));
        }
        return list;
    }
);

export const selectTurvaArviProceedingsById = createSelector(
    selectTurvaArviProceedingsState,
    (proceedings) => proceedings.byId,
);

export const selectTurvaArviEditedProceedingsById = createSelector(
    selectTurvaArviProceedingsState,
    (proceeding) => proceeding.edited,
);

// tslint:disable-next-line: max-line-length
const makeReportListingPropSelector
    = <T extends keyof IReportListingState>(prop: T) => createSelector(
        selectTurvaArviReportListingState,
        (state) => state[prop],
    );

export const selectTurvaArviReportListingFilters = makeReportListingPropSelector("filters");
export const selectTurvaArviReportListingPages = makeReportListingPropSelector("pages");
export const selectTurvaArviReportListingPagination = makeReportListingPropSelector("pagination");
export const selectTurvaArviReportListingSorting = makeReportListingPropSelector("sorting");

export const selectTurvaArviReportListingList = makeReportListingPropSelector("list");

export const selectTurvaArviFilteredReportListingList = createSelector(
    selectTurvaArviReportListingList,
    selectTurvaArviReportListingFilters,
    (list, filters) => {
        if (isLoaded(list) && filters.status !== "all") {
            const filteredList = list.data.filter((report) => {
                return report.status === filters.status;
            });
            return createLoaded(filteredList);
        }
        return list;
    },
);

// USER SELECTORS

const selectUserState = createSelector(
    selectTurvaArviState,
    (turvaArvi) => turvaArvi.users,
);

const selectUserIdList = createSelector(
    selectUserState,
    (state) => state.userList,
);

export const selectUsersByIdState = createSelector(
    selectUserState,
    (state) => state.users,
);

export const selectUserInvite = createSelector(
    selectUserState,
    (state) => state.userInvite,
);

export const selectUserList = createSelector(
    selectUserIdList,
    selectUsersByIdState,
    (userList, users): TLoadable<ITTKUser[]> => {
        if (isLoaded(userList)) {
            return createLoaded(userList.data.map((id) => users[id]));
        }
        return userList;
    },
);

// COMPANY SELECTORS

const selectCompanyState = createSelector(
    selectTurvaArviState,
    (turvaArvi) => turvaArvi.companies,
);

export const selectCurrentCompany = createSelector(
    selectCompanyState,
    (state) => state.company,
);

export const selectCurrentEditedCompany = createSelector(
    selectCompanyState,
    (state) => state.editedCompany,
);

const selectDepartments = createSelector(
    selectCompanyState,
    (state) => state.departments,
);

const selectDepartmentsDeleting = createSelector(
    selectCompanyState,
    (state) => state.deletingDepartments,
);

const selectCurrentCompanyDepartments = createSelector(
    selectCurrentCompany,
    selectDepartments,
    (company, departments): TLoadable<ITTKCompanyDepartment[]> => {
        if (isLoaded(company)) {
            return createLoaded(company.data.departments.map((depId) => departments[depId]));
        } else {
            return company;
        }
    },
);

export const selectTurvaArviUserFilteredReportListingList = createSelector(
    selectTurvaArviReportListingList,
    selectTurvaArviReportListingFilters,
    selectCurrentCompanyDepartments,
    selectLoggedInUserId,
    selectUserState,
    (list, filters, departments, userId, userList) => {
        if (isLoaded(list) && isLoaded(departments)) {
            const userIsAdmin = userId && userList.users[userId].admin ? true : false;
            const userIsResponsible = departments.data.filter(d => d.responsibleId === userId);
            const userFilteredList = list.data.filter(row => userIsResponsible.find(u => row.departmentId === u.id) || userIsAdmin);
            if (filters.status !== "all") {
                const statusFilteredList = userFilteredList.filter((report) => {
                    return report.status === filters.status;
                });
                return createLoaded(statusFilteredList);
            }
            return createLoaded(userFilteredList);
        }
        return list;
    },
);

// INDUSTRIES SELECTORS

export const useIndustries = () => {
    const industries = useSelector(selectTurvaArviIndustriesList);
    const dispatch = useDispatch();

    if (isUnloaded(industries)) {
        dispatch(createRequestIndustryListAction());
    }

    return industries;
};

// PROCEEDINGS HOOKS

export const useProceedingsList = () => {
    const proceedings = useSelector(selectTurvaArviProceedingsList);
    const dispatch = useDispatch();

    if (isUnloaded(proceedings)) {
        dispatch(createRequestFetchProceedingsAction());
    }

    return proceedings;
};

export const useFilteredProceedingsList = () => {
    const filteredProceedings = useSelector(selectTurvaArviFilteredProceedingList);
    const dispatch = useDispatch();

    if (isUnloaded(filteredProceedings)) {
        dispatch(createRequestFetchProceedingsAction());
    }

    return filteredProceedings;
};

export const useProceeding = (id: number): TLoadable<IProceedingsData> => {
    const proceedings = useSelector(selectTurvaArviProceedingsById);
    const proceeding = proceedings[id];
    const dispatch = useDispatch();

    if (!proceeding) {
        dispatch(createRequestFetchProceedingAction(id));
        return createUnloaded();
    }

    return proceeding;
};

export const useProceedingFilters = () => {
    return useSelector(selectTurvaArviProceedingsFilters);
};

export enum EUseEditedProceedingFlags {
    RESET_EDIT,
}

export const useEditedProceeding =
    (id: number, flags: EUseEditedProceedingFlags[] = []): TLoadable<TSubmittable<IProceedingsData>> => {
        const proceedings = useSelector(selectTurvaArviProceedingsById);
        const proceeding = proceedings[id];

        const dispatch = useDispatch();
        const editedProceedings = useSelector(selectTurvaArviEditedProceedingsById);

        if (!proceeding) {
            dispatch(createRequestFetchProceedingAction(id));
            return createLoading();
        }

        if (!isLoaded(proceeding)) {
            return proceeding;
        }

        const edited = editedProceedings[id];

        if (!edited || flags.includes(EUseEditedProceedingFlags.RESET_EDIT)) {
            dispatch(createMarkProceedingForEditingAction(id));
            return createLoaded(createUnsubmitted(proceeding.data));
        }

        return createLoaded(edited);
    };

// SELECTOR HOOKS

const useAccidentReportsState = () => useSelector(selectAccidentReports);

const useAccidentReportsEditedState = () => useSelector(selectEditedAccidentReports);

export const useAccidentReportFormData = (): TSubmittable<IAccidentReportFormData> | undefined => {
    return useSelector(selectAccidentReportFormData);
};


export const useAccidentReportInquiryData = (): TSubmittable<IAccidentReportInquiry> | undefined => {
    return useSelector(selectAccidentReportInquiry);
};


export const useAccidentReportData = (id: number): TLoadable<IAccidentReport> => {
    const reports = useAccidentReportsState();

    const fromState = reports[id];

    const dispatch = useDispatch();

    if (!fromState) {
        dispatch(createFetchAccReportAction(id));
    }

    return fromState || createUnloaded();
};


export const useAccidentReportDataAnonymous = (id: number, guid: string): TLoadable<IAccidentReport> => {
    const reports = useAccidentReportsState();

    const fromState = reports[id];

    const dispatch = useDispatch();
    if (!fromState) {
        dispatch(createFetchAccReportAnonymousAction(id, guid));
    }

    return fromState || createUnloaded();
};

export const useAccidentReportPDFData = (id: number): TLoadable<any> => {
    const departments = useSelector(selectDepartments);
    const accidentReport = useAccidentReportData(id);

    return useMemo(() => {
        if (!isLoaded(accidentReport)) {
            return accidentReport;
        }

        const department = accidentReport.data.department ? departments[accidentReport.data.department] : null;

        return createLoaded({
            ...accidentReport.data,
            department,
        });
    }, [departments, accidentReport]);
};

export const useEditedAccidentReportData = (id: number): TSubmittable<IAccidentReport> | null => {
    const editedReports = useAccidentReportsEditedState();

    return editedReports[id] || null;
};

export const useReportListingFilters = () => useSelector(selectTurvaArviReportListingFilters);

export const useReportList = () => {
    const list = useSelector(selectTurvaArviReportListingList);
    const dispatch = useDispatch();

    if (isUnloaded(list)) {
        dispatch(createRequestFetchReportListAction());
    }

    return list || createUnloaded();
};

export const useFilteredReportList = () => {
    const filteredList = useSelector(selectTurvaArviFilteredReportListingList);
    const dispatch = useDispatch();

    if (isUnloaded(filteredList)) {
        dispatch(createRequestFetchReportListAction());
    }

    return filteredList;
};

export const useUserFilteredReportList = () => {
    const userFilteredList = useSelector(selectTurvaArviUserFilteredReportListingList);
    const dispatch = useDispatch();

    if (isUnloaded(userFilteredList)) {
        dispatch(createRequestFetchReportListAction());
    }

    return userFilteredList;
};

export const useFilteredReportListExport = () => {
    const departments = useSelector(selectDepartments);
    const reports = useFilteredReportList();

    return useMemo(() => {
        if (isLoaded(reports)) {
            const data = reports.data.map(({ departmentId, ...report }) => {
                const department = departmentId
                                    ? (departments[departmentId] ? departments[departmentId].name : "-" )
                                    : "-";

                return {
                    ...report,
                    department,
                };
            });
            return createLoaded(data);
        }
        return reports;
    }, [departments, reports]);
};

export const useReportListingPage = (index: number, params: { loadIfUnloaded?: boolean } = {}) => {
    const pages = useSelector(selectTurvaArviReportListingPages);
    const page = pages[index] || createUnloaded();

    const dispatch = useDispatch();

    if (params.loadIfUnloaded && isUnloaded(page)) {
        dispatch(createRequestFetchReportListPage(index));
    }

    return page;
};

export const useReportListingPagination = () => useSelector(selectTurvaArviReportListingPagination);
export const useReportListingSorting = () => useSelector(selectTurvaArviReportListingSorting);

// USER SELECTORS

export const useUserState = () => {
    const turvaArvi = useSelector(selectTurvaArviState);

    return turvaArvi.users;
};

export const useUserList = (): TLoadable<ITTKUser[]> => useSelector(selectUserList) as any;

export const useEditedUser = (id: number): TSubmittable<ITTKUser> => {
    const { users, editedUsers } = useSelector(selectUserState);
    const dispatch = useDispatch();

    const edited = editedUsers[id];

    if (edited) {
        return edited;
    }

    const editedUser = users[id];
    const newEdited = createUnsubmitted(editedUser);
    dispatch(createUserMarkedForEditingAction(editedUser));
    return newEdited;
};

export const useUserInvite = (): TSubmittable<any> => {
    return useSelector(selectUserInvite);
};

export const useCurrentCompany = (): TLoadable<ITTKCompany> => {
    const company = useSelector(selectCurrentCompany);
    const departments = useCurrentDepartments();
    const dispatch = useDispatch();

    if (isUnloaded(company)) {
        dispatch(createRequestFetchCurrentCompanyAction());
    }

    if (isLoaded(company) && isLoaded(departments)) {
        const parsedCompany: ITTKCompany = {
            ...company.data,
            departments: company.data.departments.map((id) => departments.data.find((d) => d.id === id)!),
        };

        return createLoaded(parsedCompany);
    }

    return company as any;
};

export const useCurrentEditedCompany = (): TLoadable<TSubmittable<ITTKCompany>> => {
    const dispatch = useDispatch();
    const originalCompany = useCurrentCompany();
    const editedCompany = useSelector(selectCurrentEditedCompany);

    if (!isLoaded(originalCompany)) {
        return originalCompany;
    }

    if (!editedCompany) {
        dispatch(createMarkCurrentCompanyForEditingAction());
        return createLoading();
    }

    return createLoaded(editedCompany);
};

export const useCurrentDepartments = () => {
    const departments = useSelector(selectCurrentCompanyDepartments);
    const dispatch = useDispatch();

    if (isUnloaded(departments)) {
        dispatch(createRequestFetchCurrentCompanyAction());
    }

    return departments;
};

export const useDeletingDepartment = (id: number) => {
    const deletingDepartments = useSelector(selectDepartmentsDeleting);

    return deletingDepartments[id] || createUnsubmitted(1);
};

export const useDepartment = (id: number): TLoadable<ITTKCompanyDepartment> => {
    const departments = useCurrentDepartments();

    if (isLoaded(departments)) {
        const department = departments.data.find((d) => d.id === id);
        if (department) {
            return createLoaded(department);
        }
        return createLoadErrored(new Error("Department not found"));
    } else {
        return departments;
    }
};
