import IIndustry from "../../interfaces/IIndustry";

export const requestIndustryListLoad = "REQUEST_FETCH_INDUSTRY_LIST";
const successIndustryListLoad = "SUCCESS_FETCH_INDUSTRY_LIST";
const failureIndustryListLoad = "FAILURE_FETCH_INDUSTRY_LIST";

interface IRequestFetchIndustryList {
    type: typeof requestIndustryListLoad;
}

interface ISuccessIndustryListLoad {
    type: typeof successIndustryListLoad;
    payload: { list: IIndustry[] };
}

interface IFailureIndustryListLoad {
    type: typeof failureIndustryListLoad;
    error: Error;
}

export type TIndustryAction
    = IRequestFetchIndustryList
    | ISuccessIndustryListLoad
    | IFailureIndustryListLoad;

export const createRequestIndustryListAction = (): IRequestFetchIndustryList => ({
    type: requestIndustryListLoad,
});

export const createSuccessIndustryListAction = (list: IIndustry[]): ISuccessIndustryListLoad => ({
    type: successIndustryListLoad,
    payload: { list },
});

export const createFailureIndustryListAction = (error: Error): IFailureIndustryListLoad => ({
    type: failureIndustryListLoad,
    error,
});
