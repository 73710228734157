import { call, put, debounce, cancel, cancelled, take, fork, select } from "redux-saga/effects";

import { IAccidentReportService } from "../../services/AccidentReportService";
import {
    IUpdateAccidentReportEditing,
    updateAccidentReportEditing,
    createAccidentReportUpdateSuccess,
    createAccidentReportUpdateFailure,
    createRequestAccidentReportUpdate,
    createCancelAccidentReportUpdate,
} from "../actions";
import { IAccidentReport } from "../../interfaces/IAccidentReportData";
import { TResult } from "../../../sg-core/utils/Result";
import { selectLoggedInUserCompanyId } from "../../auth/store/authSelectors";

const makeAutosaveAccidentReportSaga = (accidentReportService: IAccidentReportService) =>
    function* saga(action: IUpdateAccidentReportEditing) {
        try {
            if (action.payload.autosaveEnabled) {

                yield put(createRequestAccidentReportUpdate(action.payload.editedReport));
                const companyId = yield select(selectLoggedInUserCompanyId);

                const response: TResult<IAccidentReport> = yield call(
                    accidentReportService.updateAccidentReport,
                    companyId,
                    action.payload.editedReport,
                );

                if (response.isSuccess) {
                    yield put(createAccidentReportUpdateSuccess(response.value));
                } else if (response.isFailure) {
                    yield put(createAccidentReportUpdateFailure(action.payload.editedReport.id, response.error));
                }
            }
        } finally {
            if (yield cancelled()) {
                yield put(createCancelAccidentReportUpdate(action.payload.editedReport.id));
            }
        }
    };

const makeAutosaveContainer = (reportService: IAccidentReportService) =>
    function* container(action: IUpdateAccidentReportEditing) {
        const autosave = yield fork(makeAutosaveAccidentReportSaga(reportService), action);

        yield take(updateAccidentReportEditing);
        yield cancel(autosave);
    };

export default function createAutosaveAccidentReportSaga(debounceMs: number, reportService: IAccidentReportService) {
    return debounce(
         debounceMs || 1500,
         updateAccidentReportEditing,
         makeAutosaveContainer(reportService),
    );
}
