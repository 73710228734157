import { TResult, makeSuccess, makeFailure } from "../../../sg-core/utils/Result";
import ITTKAuthInfo from "../interfaces/ITTKAuthInfo";

export interface ITTKAuthStorage {
    getAuthFromStorage: () => TResult<ITTKAuthInfo>;
    setAuthToStorage: (auth: ITTKAuthInfo) => TResult<void>;
    clearAuthFromStorage: () => void;
}

export const getTTKAuthStorage = (storageKey: string = "sg:ttk:auth", storage?: Storage): ITTKAuthStorage => {

    // Return an in-memory solution if no storage is specified
    if (!storage) {
        let auth: ITTKAuthInfo | undefined;
        return {
            getAuthFromStorage: () => auth ? makeSuccess(auth) : makeFailure(new Error("No authentication")),
            setAuthToStorage: (a) => { auth = a; return makeSuccess(undefined); },
            clearAuthFromStorage: () => auth = undefined,
        };
    }

    const getAuthFromStorage = () => {
        const data = storage.getItem(storageKey);
        try {
            if (data) {
                return makeSuccess(JSON.parse(data));
            }
            return makeFailure(new Error("No authentication"));
        } catch (e) {
            return makeFailure(new Error("No authentication: storage contained invalid JSON"));
        }
    };

    const setAuthToStorage = (auth: ITTKAuthInfo) => {
        storage.setItem(storageKey, JSON.stringify(auth));
        return makeSuccess(undefined);
    };

    const clearAuthFromStorage = () => {
        storage.removeItem(storageKey);
    };

    return {
        getAuthFromStorage,
        setAuthToStorage,
        clearAuthFromStorage,
    };
};
