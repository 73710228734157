import { TurvaArviAction } from "./actions";
import { Reducer, combineReducers } from "redux";
    import IAccidentReportFormData, { IAccidentReport, IAccidentReportInquiry } from "../interfaces/IAccidentReportData";
import { TSubmittable, createUnsubmitted, createSubmitting, createSubmitted, createSubmitErrored } from "../../sg-core/models/ISubmittable";
import { TLoadable, createUnloaded, createLoaded, createLoading, createLoadErrored, isLoaded } from "../../sg-core/models/ILoadable";

import userReducer, { IUserState, defaultUserState } from "./reducers/userReducer";
import companyReducer, { ICompanyState, defaultCompanyState } from "./reducers/companyReducer";
import reportListReducer, { IReportListingState, initialListingState } from "./reducers/accidentReportListReducer";
import authReducer, { ITTKAuthState, defaultAuthState } from "../auth/store/authReducer";
import { defaultIndustryState, industryReducer, IIndustryState } from "./reducers/industryReducer";
import { IProceedingState, defaultProceedingState, proceedingReducer } from "./reducers/proceedingReducer";
import assignDefined from "../../sg-core/utils/assignDefined";

export interface ITurvaArviState {
    reports: ITurvaArviReportsState;
    reportListing: IReportListingState;
    users: IUserState;
    companies: ICompanyState;
    authentication: ITTKAuthState;
    industries: IIndustryState;
    proceedings: IProceedingState;
}

export interface ITurvaArviReportsState {
    accidentReportForm?: TSubmittable<IAccidentReportFormData>;
    departments: TLoadable<any>;
    accidentReports: { [id: string]: TLoadable<IAccidentReport> };
    accidentReportsEdited: { [id: string]: TSubmittable<IAccidentReport> };
    accidentReportInquiry?: TSubmittable<IAccidentReportInquiry>;
}

const initialState: ITurvaArviState = {
    reports: {
        accidentReports: {},
        accidentReportsEdited: {},
        departments: createUnloaded(),
    },
    reportListing: initialListingState,
    users: defaultUserState,
    companies: defaultCompanyState,
    authentication: defaultAuthState,
    industries: defaultIndustryState,
    proceedings: defaultProceedingState,
};

// tslint:disable-next-line: max-line-length
const reducer: Reducer<ITurvaArviReportsState, TurvaArviAction> = (state = initialState.reports, action): ITurvaArviReportsState => {
    switch (action.type) {
        case "REQUEST_FETCH_ACCIDENT_REPORT": {
            return {
                ...state,
                accidentReports: {
                    ...state.accidentReports,
                    [action.payload.id]: createLoading(),
                },
            };
        }
        case "SUCCESS_FETCH_ACCIDENT_REPORT": {
            return {
                ...state,
                accidentReports: {
                    ...state.accidentReports,
                    [action.payload.report.id]: createLoaded(action.payload.report),
                },
            };
        }
        case "FAILURE_FETCH_ACCIDENT_REPORT": {
            return {
                ...state,
                accidentReports: {
                    ...state.accidentReports,
                    [action.payload.id]: createLoadErrored(action.payload.error),
                },
            };
        }
        case "REQUEST_FETCH_ACCIDENT_REPORT_ANON": {
            return {
                ...state,
                accidentReports: {
                    ...state.accidentReports,
                    [action.payload.id]: createLoading(),
                },
            };
        }
        case "SUCCESS_FETCH_ACCIDENT_REPORT_ANON": {
            return {
                ...state,
                accidentReports: {
                    ...state.accidentReports,
                    [action.payload.report.id]: createLoaded(action.payload.report),
                },
            };
        }
        case "FAILURE_FETCH_ACCIDENT_REPORT_ANON": {
            return {
                ...state,
                accidentReports: {
                    ...state.accidentReports,
                    [action.payload.id]: createLoadErrored(action.payload.error),
                },
            };
        }        
        case "UPDATE_ACCIDENT_REPORT_FORM_DATA": {
            return {
                ...state,
                accidentReportForm: createUnsubmitted(action.payload.formData),
            };
        }
        case "INIT_ACC_REPORT_FORM": {
            return {
                ...state,
                accidentReportForm: createUnsubmitted<IAccidentReportFormData>({
                    description: { value: "", images: [] },
                    department: null,
                    eventDate: new Date(),
                    location: { value: "", images: [] },
                    mayBeInContact: false,
                    reporterContact: {},
                    whatWasOkay: "",
                    reportType: null,
                    inspectionRequired: false
                }),
            };
        }
        case "START_ACC_REPORT_FORM_POST": {
            return {
                ...state,
                accidentReportForm: createSubmitting<IAccidentReportFormData>(state.accidentReportForm!.data),
            };
        }
        case "SUCCESS_ACC_REPORT_FORM_POST": {
            const { report } = action.payload;
            return {
                ...state,
                accidentReportForm: createSubmitted(state.accidentReportForm!.data, action.payload.report),
                accidentReports: {
                    ...state.accidentReports,
                    [report.id]: createLoaded(report),
                },
            };
        }
        case "FAILURE_ACC_REPORT_FORM_POST": {
            return {
                ...state,
                accidentReportForm: createSubmitErrored(state.accidentReportForm!.data, action.error),
            };
        }
        case "INIT_ACC_REPORT_INQUIRY": {
            return {
                ...state,
                accidentReportInquiry: createUnsubmitted<IAccidentReportInquiry>({
                    report: {id: action.payload},
                    message: ''
                }),
            };
        }
        case "UPDATE_ACC_REPORT_INQUIRY": {
            return {
                ...state,
                accidentReportInquiry: createUnsubmitted<IAccidentReportInquiry>(action.payload),
            };
        }        
        case "START_ACC_REPORT_INQUIRY_POST": {
            return {
                ...state,
                accidentReportInquiry: createSubmitting<IAccidentReportInquiry>(state.accidentReportInquiry!.data),
            };
        }
        case "SUCCESS_ACC_REPORT_INQUIRY_POST": {
            return {
                ...state,
                accidentReportInquiry: createSubmitted(state.accidentReportInquiry!.data, action.payload.report),
            };
        }
        case "FAILURE_ACC_REPORT_INQUIRY_POST": {
            return {
                ...state,
                accidentReportInquiry: createSubmitErrored(state.accidentReportInquiry!.data, action.error),
            };
        }
        case "INIT_ACC_REPORT_EDITING": {
            const { report } = action.payload;

            const editedReport = createUnsubmitted({ ...report });

            const newEditedReports = {
                ...state.accidentReportsEdited,
                [report.id]: editedReport,
            };

            return {
                ...state,
                accidentReportsEdited: newEditedReports,
            };
        }
        case "UPDATE_ACC_REPORT_EDITING": {
            const { editedReport } = action.payload;
            const unsubmitted = createUnsubmitted<IAccidentReport>({ ...editedReport });

            const newEditedReports = {
                ...state.accidentReportsEdited,
                [editedReport.id]: unsubmitted,
            };

            return {
                ...state,
                accidentReportsEdited: newEditedReports,
            };
        }
        case "UPDATE_ACC_REPORT_EDITING_NO_SAVE": {
            const { editedReport } = action.payload;
            const unsubmitted = createUnsubmitted<IAccidentReport>({ ...editedReport });

            const newEditedReports = {
                ...state.accidentReportsEdited,
                [editedReport.id]: unsubmitted,
            };

            return {
                ...state,
                accidentReportsEdited: newEditedReports,
            };
        }
        case "REQUEST_SAVE_ACCIDENT_REPORT": {
            const { report } = action.payload;
            const { id } = report;
            const submitting = createSubmitting(report);

            const newEditedReports = {
                ...state.accidentReportsEdited,
                [id]: submitting,
            };

            return {
                ...state,
                accidentReportsEdited: newEditedReports,
            };
        }
        case "SUCCESS_SAVE_ACCIDENT_REPORT": {
            const { updatedReport } = action.payload;
            const { id } = updatedReport;
            const submitted = createSubmitted(updatedReport, updatedReport);
            const loaded = createLoaded(updatedReport);

            const newEditedReports = {
                ...state.accidentReportsEdited,
                [id]: submitted,
            };
            const newReports = {
                ...state.accidentReports,
                [id]: loaded,
            };

            return {
                ...state,
                accidentReports: newReports,
                accidentReportsEdited: newEditedReports,
            };
        }
        case "FAILURE_SAVE_ACCIDENT_REPORT": {
            const { error, id } = action.payload;
            const form = state.accidentReportsEdited[id];
            const newEditedReports = {
                ...state.accidentReportsEdited,
                [id]: createSubmitErrored(form.data, error),
            };

            return {
                ...state,
                accidentReportsEdited: newEditedReports,
            };
        }
        case "CANCEL_SAVE_ACCIDENT_REPORT": {
            const { id } = action.payload;
            const unsubmitted = createUnsubmitted(state.accidentReportsEdited[id].data);
            const newEditedReports = {
                ...state.accidentReportsEdited,
                [id]: unsubmitted,
            };
            return {
                ...state,
                accidentReportsEdited: newEditedReports,
            };
        }
        default: {
            return state;
        }

        // When a page is successfully loaded, add & refresh the loaded objects
        // to the report storing state.
        case "SUCCESS_REPORT_LIST_PAGE_LOAD": {
            const loadedReports
                = action.payload.page
                    .reduce<ITurvaArviReportsState["accidentReports"]>((reports, rep) => {
                        return { ...reports, [rep.id]: createLoaded(rep) };
                    }, {});
            const newReports = { ...state.accidentReports, ...loadedReports };
            return {
                ...state,
                accidentReports: newReports,
            };
        }

        // React to updates in a report data
        case "DIRECT_UPDATE_ACCIDENT_REPORT": {
            const report = state.accidentReports[action.paylod.report.id!];
            if (report && isLoaded(report)) {
                const newReport = assignDefined(report.data, action.paylod.report);
                return {
                    ...state,
                    accidentReports: {
                        ...state.accidentReports,
                        [action.paylod.report.id!]: createLoaded(newReport),
                    },
                };
            }
            return state;
        }
    }
};

const turvaArviReducer = combineReducers<ITurvaArviState, any>({
    authentication: authReducer,
    reports: reducer,
    reportListing: reportListReducer,
    users: userReducer,
    companies: companyReducer,
    industries: industryReducer,
    proceedings: proceedingReducer,
});

export default turvaArviReducer;
