import IAccidentReportFormData, { IAccidentReport, IAccidentReportInquiry } from "../interfaces/IAccidentReportData";

import { TAccidentReportListAction } from "./actions/accidentReportList";

export const updateAccReportFormDataAction = "UPDATE_ACCIDENT_REPORT_FORM_DATA";
export const initAccidentReportAction = "INIT_ACC_REPORT_FORM";
export const postAccidentReportFormAction = "START_ACC_REPORT_FORM_POST";
export const postAccidentReportFormAnonymousAction = "START_ACC_REPORT_FORM_POST_ANON";
export const accidentReportFormPostSuccess = "SUCCESS_ACC_REPORT_FORM_POST";
export const accidentReportFormPostError = "FAILURE_ACC_REPORT_FORM_POST";
export const initAccidentReportInquiryAction = "INIT_ACC_REPORT_INQUIRY";
export const updateAccidentReportInquiryAction = "UPDATE_ACC_REPORT_INQUIRY";
export const postAccidentReportInquiryAction = "START_ACC_REPORT_INQUIRY_POST";
export const accidentReportInquiryPostSuccess = "SUCCESS_ACC_REPORT_INQUIRY_POST";
export const accidentReportInquiryPostError = "FAILURE_ACC_REPORT_INQUIRY_POST";
export const initAccidentReportEditing = "INIT_ACC_REPORT_EDITING";
export const updateAccidentReportEditing = "UPDATE_ACC_REPORT_EDITING";
export const updateAccidentReportEditingNoSave = "UPDATE_ACC_REPORT_EDITING_NO_SAVE";

export const fetchAccidentReport = "REQUEST_FETCH_ACCIDENT_REPORT";
export const fetchAccidentReportSuccess = "SUCCESS_FETCH_ACCIDENT_REPORT";
export const fetchAccidentReportFailure = "FAILURE_FETCH_ACCIDENT_REPORT";
export const fetchAccidentReportAnonymous = "REQUEST_FETCH_ACCIDENT_REPORT_ANON";
export const fetchAccidentReportSuccessAnonymous = "SUCCESS_FETCH_ACCIDENT_REPORT_ANON";
export const fetchAccidentReportFailureAnonymous = "FAILURE_FETCH_ACCIDENT_REPORT_ANON";

export const saveActionReportRequest = "REQUEST_SAVE_ACCIDENT_REPORT";
export const updateActionReportAnonymousRequest = "REQUEST_UPDATE_ACCIDENT_REPORT_ANON";
export const saveActionReportSuccess = "SUCCESS_SAVE_ACCIDENT_REPORT";
export const saveActionReportFailure = "FAILURE_SAVE_ACCIDENT_REPORT";
export const saveActionReportCancel = "CANCEL_SAVE_ACCIDENT_REPORT";

export const setAccidentReportData = "DIRECT_UPDATE_ACCIDENT_REPORT";

interface IUpdateFormDataAction {
    type: typeof updateAccReportFormDataAction;
    payload: {
        formData: IAccidentReportFormData;
    };
}

interface IInitAccidentReportForm {
    type: typeof initAccidentReportAction;
    payload: null;
}

interface IStartAccidentReportFormPost {
    type: typeof postAccidentReportFormAction;
    payload: null;
}

interface IStartAccidentReportFormPostAnonymous {
    type: typeof postAccidentReportFormAnonymousAction;
    payload: null;
}

interface IAccidentReportFormPostSuccess {
    type: typeof accidentReportFormPostSuccess;
    payload: {
        report: IAccidentReport;
    };
}

interface IAccidentReportFormPostFailure {
    type: typeof accidentReportFormPostError;
    error: Error;
}

interface IInitAccidentReportInquiry {
    type: typeof initAccidentReportInquiryAction;
    payload: number;
}

interface IUpdateAccidentReportInquiry {
    type: typeof updateAccidentReportInquiryAction;
    payload: IAccidentReportInquiry;
}

interface IStartAccidentReportInquiryPost {
    type: typeof postAccidentReportInquiryAction;
    payload: null;
}

interface IAccidentReportInquiryPostSuccess {
    type: typeof accidentReportInquiryPostSuccess;
    payload: {
        report: IAccidentReportInquiry;
    };
}

interface IAccidentReportInquiryPostFailure {
    type: typeof accidentReportInquiryPostError;
    error: Error;
}

interface IInitAccidentReportEditing {
    type: typeof initAccidentReportEditing;
    payload: {
        report: IAccidentReport;
    };
}

export interface IDirectUpdateReportData {
    type: typeof setAccidentReportData;
    paylod: {
        report: Partial<IAccidentReport>;
    };
}

export interface IUpdateAccidentReportEditing {
    type: typeof updateAccidentReportEditing;
    payload: {
        editedReport: IAccidentReport;
        autosaveEnabled: boolean;
    };
}

export interface IUpdateAccidentReportEditingNoSave {
    type: typeof updateAccidentReportEditingNoSave;
    payload: {
        editedReport: IAccidentReport;
    };
}

// FETCH

export interface IFetchAccidentReport {
    type: typeof fetchAccidentReport;
    payload: {
        id: number;
    };
}

export interface IFetchAccidentReportSuccess {
    type: typeof fetchAccidentReportSuccess;
    payload: {
        report: IAccidentReport;
    };
}

interface IFetchAccidentReportFailure {
    type: typeof fetchAccidentReportFailure;
    payload: {
        id: number;
        error: Error;
    };
}

export interface IFetchAccidentReportAnonymous {
    type: typeof fetchAccidentReportAnonymous;
    payload: {
        id: number;
        guid: string;
    };
}

export interface IFetchAccidentReportAnonymousSuccess {
    type: typeof fetchAccidentReportSuccessAnonymous;
    payload: {
        report: IAccidentReport;
    };
}

interface IFetchAccidentReportAnonymousFailure {
    type: typeof fetchAccidentReportFailureAnonymous;
    payload: {
        id: number;
        error: Error;
    };
}

// UPDATE
interface ISaveAccidentReport {
    type: typeof saveActionReportRequest;
    payload: { report: IAccidentReport };
}

interface IUpdateAccidentReportAnonymous {
    type: typeof updateActionReportAnonymousRequest;
    payload: { editedReport: IAccidentReport };
}

export interface ISaveAccidentReportSuccess {
    type: typeof saveActionReportSuccess;
    payload: { updatedReport: IAccidentReport };
}

interface ISaveAccidentReportFailure {
    type: typeof saveActionReportFailure;
    payload: { id: number; error: Error };
}

interface ISaveAccidentReportCancel {
    type: typeof saveActionReportCancel;
    payload: { id: number };
}

// THE TYPE

export type TurvaArviAction
    = IUpdateFormDataAction
    | IInitAccidentReportForm
    | IStartAccidentReportFormPost
    | IAccidentReportFormPostSuccess
    | IAccidentReportFormPostFailure
    | IInitAccidentReportEditing
    | IUpdateAccidentReportEditing
    | IFetchAccidentReport
    | IFetchAccidentReportFailure
    | IFetchAccidentReportSuccess
    | ISaveAccidentReport
    | ISaveAccidentReportSuccess
    | ISaveAccidentReportFailure
    | ISaveAccidentReportCancel
    | IDirectUpdateReportData
    | TAccidentReportListAction
    | IInitAccidentReportInquiry
    | IStartAccidentReportInquiryPost
    | IAccidentReportInquiryPostSuccess
    | IAccidentReportInquiryPostFailure
    | IUpdateAccidentReportInquiry
    | IFetchAccidentReportAnonymous
    | IFetchAccidentReportAnonymousFailure
    | IFetchAccidentReportAnonymousSuccess
    | IUpdateAccidentReportEditingNoSave;

export const createInitAccidentReportForm = (): IInitAccidentReportForm => ({
    type: initAccidentReportAction,
    payload: null,
});

export const createUpdateAccidentReportFormDataAction = (formData: IAccidentReportFormData): IUpdateFormDataAction => {
    return {
        type: updateAccReportFormDataAction,
        payload: {
            formData,
        },
    };
};

export const createStartPostAccidentReportFormAction = (): IStartAccidentReportFormPost => ({
    type: postAccidentReportFormAction,
    payload: null,
});

export const createAccidentReportFormPostSuccess = (report: IAccidentReport): IAccidentReportFormPostSuccess => ({
    type: accidentReportFormPostSuccess,
    payload: { report },
});

export const createAccidentReportFormFailure = (error: Error): IAccidentReportFormPostFailure => ({
    type: accidentReportFormPostError,
    error,
});

export const createStartPostAccidentReportFormAnonymousAction = (): IStartAccidentReportFormPostAnonymous => ({
    type: postAccidentReportFormAnonymousAction,
    payload: null,
});

export const createInitAccidentReportInquiry = (reportId: number): IInitAccidentReportInquiry => ({
    type: initAccidentReportInquiryAction,
    payload: reportId,
});

export const createStartPostAccidentReportInquiryAction = (): IStartAccidentReportInquiryPost => ({
    type: postAccidentReportInquiryAction,
    payload: null,
});

export const createAccidentReportInquiryPostSuccess = (report: IAccidentReportInquiry): IAccidentReportInquiryPostSuccess => ({
    type: accidentReportInquiryPostSuccess,
    payload: { report },
});

export const createAccidentReportInquiryFailure = (error: Error): IAccidentReportInquiryPostFailure => ({
    type: accidentReportInquiryPostError,
    error,
});

// UPDATE

export const createInitAccidentReportEditing = (report: IAccidentReport): IInitAccidentReportEditing => ({
    type: initAccidentReportEditing,
    payload: { report },
});

export const createUpdateAccidentReportEditing =
    (editedReport: IAccidentReport, autosave?: boolean): IUpdateAccidentReportEditing => ({
        type: updateAccidentReportEditing,
        payload: { editedReport, autosaveEnabled: !!autosave },
    });

export const createUpdateAccidentReportEditingNoSave =
    (editedReport: IAccidentReport): IUpdateAccidentReportEditingNoSave => ({
        type: updateAccidentReportEditingNoSave,
        payload: { editedReport },
    });

export const createRequestAccidentReportUpdate = (report: IAccidentReport): ISaveAccidentReport => ({
    type: saveActionReportRequest,
    payload: { report },
});

export const createRequestAccidentReportAnonymousUpdate = (editedReport: IAccidentReport): IUpdateAccidentReportAnonymous => ({
    type: updateActionReportAnonymousRequest,
    payload: { editedReport },
});

export const createAccidentReportUpdateSuccess = (updatedReport: IAccidentReport): ISaveAccidentReportSuccess => ({
    type: saveActionReportSuccess,
    payload: { updatedReport },
});

export const createAccidentReportUpdateFailure = (id: number, error: Error): ISaveAccidentReportFailure => ({
    type: saveActionReportFailure,
    payload: { id, error },
});

export const createCancelAccidentReportUpdate = (id: number): ISaveAccidentReportCancel => ({
    type: saveActionReportCancel,
    payload: { id },
});


export const createInitAccidentReportInquiryUpdate = (id: number, message: string): IUpdateAccidentReportInquiry => ({
    type: updateAccidentReportInquiryAction,
    payload: {
        report: {id: id},
        message: message
    }
});

// FETCH

export const createFetchAccReportAction = (id: number): IFetchAccidentReport => ({
    type: fetchAccidentReport,
    payload: { id },
});

export const createFetchAccReportSuccessAction = (report: IAccidentReport): IFetchAccidentReportSuccess => ({
    type: fetchAccidentReportSuccess,
    payload: { report },
});

export const createFetchAccReportFailureAction = (id: number, error: Error): IFetchAccidentReportFailure => ({
    type: fetchAccidentReportFailure,
    payload: { id, error },
});


export const createFetchAccReportAnonymousAction = (id: number, guid: string): IFetchAccidentReportAnonymous => ({
    type: fetchAccidentReportAnonymous,
    payload: { id, guid },
});

export const createFetchAccReportAnonymousSuccessAction = (report: IAccidentReport): IFetchAccidentReportAnonymousSuccess => ({
    type: fetchAccidentReportSuccessAnonymous,
    payload: { report },
});

export const createFetchAccReportAnonymousFailureAction = (id: number, error: Error): IFetchAccidentReportAnonymousFailure => ({
    type: fetchAccidentReportFailureAnonymous,
    payload: { id, error },
});

// DIRECT UPDATE

export const createDirectUpdateAccidentReportdata = (updatedFields: Partial<IAccidentReport>): IDirectUpdateReportData => ({
    type: setAccidentReportData,
    paylod: { report: updatedFields },
});
