import React, { memo, useMemo } from "react";
import SgHeader from "../../sg-header";
import { useTranslation } from "react-i18next";
import { ISgHeaderMenuItem } from "../../sg-header/interfaces";
import { getRouteHrefById } from "../../routing";
import AuthenticationMenuItem from "../auth/components/AuthenticationMenuItem";
import { Link } from "react-router-dom";
import RouteLink from "../../sg-core/components/RouteLink";
import { useLoginStatus } from "../auth/store/authSelectors";
import { isLoaded } from "../../sg-core/models/ILoadable";
import { AuthenticationType } from "../auth/store/authReducer";
import SgTopLinks from "../../sg-header/components/SgTopLinks";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";

// Color to use for rendering the menu
// TODO: Place to a constants file
const COLOR: SemanticCOLORS = "green";

const renderMenuItem = (item: ISgHeaderMenuItem) => {
    if (item.linkTo) {
        return <Link to={item.linkTo}>{item.text}</Link>;
    }
    return <span    >{item.text}</span>;
};

/**
 * Default Gate-style circular button links for the navbar top
 */
const TopLinks: React.FC = memo(() => (
    <SgTopLinks
        color={COLOR}
        gateLink="https://gate.systemsgarden.com"
        homeLink={getRouteHrefById("FrontPage")!}
        routeLinkWrapper={Link}
    />
));

/**
 * Makes the logo a link to the front page
 */
const LogoWrapper: React.FC = memo(({ children }) => (
    <RouteLink id="FrontPage">{children}</RouteLink>
));

/**
 * This component contains the information of the menu items and other
 * header-related things in the application. Uses the standard SGHeader
 * component to render the header.
 */
const TurvaArviHeader: React.FC = () => {
    // Get translator and authentication status
    const { t } = useTranslation();
    const authState = useLoginStatus();

    // Load menu items. Menu items are updated each time the translator or the
    // authentication status changes. (Not all menu items are shown if auth
    // state is something other than "authenticated").
    const menuItems = useMemo(() => {
        const visibleMenuItems: ISgHeaderMenuItem[] = [];

        // Show app options if the user is logged in as a user. (Do not show on
        // company token login)
        if (isLoaded(authState) && authState.data >= AuthenticationType.TTKUser) {
            [{
                key: "ilmoitukset",
                text: t("turvallisuusilmoituksetMenu"),
                linkTo: getRouteHrefById("ReportListing"),
            }, {
                key: "toimenpiteet",
                text: t("toimenpiteetMenu"),
                linkTo: getRouteHrefById("ProceedingListView"),
            }, {
                key: "asetukset",
                text: t("asetuksetMenu"),
                linkTo: getRouteHrefById("Settings"),
            }].map((i) => visibleMenuItems.push(i));
        }

        visibleMenuItems.push({
            key: "logout",
            text: t("logoutMenu"),
            component: AuthenticationMenuItem,
        });

        return visibleMenuItems;
    }, [t, authState]);

    // Static header items
    const headerItems = useMemo(() => {
        return [{
            key: "ttk",
            text: "Etusivu",
            linkTo: getRouteHrefById("FrontPage"),
        }];
    }, []);

    return (
        <SgHeader
            menuColor={COLOR}
            logoUrl="/img/logo-ttk.png"
            menuItems={menuItems}
            menuHeaderItems={headerItems}
            renderMenuItem={renderMenuItem}
            logoWrapper={LogoWrapper}
            topRightContent={TopLinks}
        />
    );
};

export default memo(TurvaArviHeader);
