import { put, select, takeEvery } from "redux-saga/effects";
import { ISuccessSaveProceeding, successSaveProceeding } from "../actions/proceedingActions";
import { selectTurvaArviProceedingsList } from "../selectors";
import { TLoadable, isLoaded } from "../../../sg-core/models/ILoadable";
import { IProceedingDataInList, EProceedingStatus } from "../../interfaces/IProceedingData";
import { createDirectUpdateAccidentReportdata } from "../actions";

const completeStates = [EProceedingStatus.Done, EProceedingStatus.WontBeDone];

const makeAfterProceedingSavedSaga = () =>
    function* saga(action: ISuccessSaveProceeding) {
        const proceeding = action.payload.proceeding;

        if (completeStates.includes(proceeding.status)) {
            const proceedingList: TLoadable<IProceedingDataInList[]> = yield select(selectTurvaArviProceedingsList);

            if (isLoaded(proceedingList)) {
                const allProceedingsComplete = proceedingList.data.every(
                    (proc) => {
                        return (
                            (proc.reportId !== proceeding.reportId)
                            || proc.status !== EProceedingStatus.InProgress
                        );
                    },
                );

                if (allProceedingsComplete) {
                    yield put(createDirectUpdateAccidentReportdata({
                        id: proceeding.reportId,
                        status: "finished",
                    }));
                }
            }
        }
    };

export default function create() {
    return takeEvery(
        successSaveProceeding,
        makeAfterProceedingSavedSaga(),
    );
}
