import { takeLeading, call, put, select } from "redux-saga/effects";
import { ITTKUserService } from "../../services/TTKUserService";
import { TResult } from "../../../sg-core/utils/Result";
import { createFailureInviteUserAction, IRequestInviteUser, requestInviteUser, createSuccessInviteUserAction } from "../actions/userActions";
import { selectCurrentCompany } from "../selectors";
import { isLoaded, TLoadable } from "../../../sg-core/models/ILoadable";
import ITTKCompany from "../../interfaces/ITTKCompany";

const makeInviteUserSaga = (userService: ITTKUserService) =>
    function* saga(action: IRequestInviteUser) {
        try {
            const company: TLoadable<ITTKCompany> = yield select(selectCurrentCompany);

            if (!isLoaded(company)) {
                throw new Error("Do not trigger a user invite until company data is loaded");
            }
            // const aaa = yield select(selectCompa)

            const { email, firstName, surname } = action.payload.user;

            const result: TResult<any> = yield call(userService.inviteUser, company.data.guid, email, firstName, surname);

            if (result.isSuccess) {
                yield put(createSuccessInviteUserAction());
            } else {
                yield put(createFailureInviteUserAction(result.error));
            }
        } catch (e) {
            yield put(createFailureInviteUserAction(e));
        }
    };

export default function create(userService: ITTKUserService) {
    return takeLeading(
        requestInviteUser,
        makeInviteUserSaga(userService),
    );
}