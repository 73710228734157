import ITTKUser from "../../interfaces/ITTKUser";
import { TSubmittable, createUnsubmitted, createSubmitting, createSubmitted, createSubmitErrored } from "../../../sg-core/models/ISubmittable";
import { TLoadable, createUnloaded, createLoading, createLoaded } from "../../../sg-core/models/ILoadable";
import { IUserAction } from "../actions/userActions";
import { TAuthAction } from "../../auth/store/authActions";

export const defaultUserState: IUserState = {
    users: {},
    editedUsers: {},
    userList: createUnloaded(),
    userInvite: createUnsubmitted(true),
};

export interface IUserState {
    users: { [key: number]: ITTKUser };
    userInvite: TSubmittable<any>;
    editedUsers: { [key: number]: TSubmittable<ITTKUser> };
    userList: TLoadable<number[]>;
}

export default function userReducer(state = defaultUserState, action: IUserAction | TAuthAction) {
    switch (action.type) {
        case "REQUEST_FETCH_USERS": {
            return {
                ...state,
                userList: createLoading(),
            };
        }
        case "SUCCESS_FETCH_USERS": {
            interface IParsed {
                users: IUserState["users"];
                ids: number[];
            }

            const parsed: IParsed = action.payload.users.reduce<IParsed>((acc, user) => {
                return {
                    users: { ...acc.users, [user.id]: user },
                    ids: acc.ids.concat(user.id),
                };
            }, { users: {}, ids: [] });

            return {
                ...state,
                users: { ...state.users, ...parsed.users },
                userList: createLoaded(parsed.ids),
            };
        }
        case "USER_MARKED_FOR_EDITING": {
            return {
                ...state,
                editedUsers: {
                    ...state.editedUsers,
                    [action.payload.user.id]: createUnsubmitted(action.payload.user),
                },
            };
        }
        case "UPDATE_EDITED_USER": {
            return {
                ...state,
                editedUsers: {
                    ...state.editedUsers,
                    [action.payload.id]: createUnsubmitted<ITTKUser>({
                        ...state.editedUsers[action.payload.id].data,
                        ...action.payload.editedProps,
                    }),
                },
            };
        }
        case "REQUEST_SAVE_USER": {
            return {
                ...state,
                editedUsers: {
                    ...state.editedUsers,
                    [action.payload.user.id]: createSubmitting(action.payload.user),
                },
            };
        }
        case "SUCCESS_SAVE_USER": {
            return {
                ...state,
                editedUsers: {
                    ...state.editedUsers,
                    [action.payload.savedUser.id]: createSubmitted(action.payload.savedUser),
                },
                users: {
                    ...state.users,
                    [action.payload.savedUser.id]: action.payload.savedUser,
                },
            };
        }
        case "SUCCESS_LOGIN": {
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.user.id]: action.payload.user,
                },
            };
        }
        case "REQUEST_INVITE_USER": {
            return {
                ...state,
                userInvite: createSubmitting(true),
            };
        }
        case "SUCCESS_INVITE_USER": {
            return {
                ...state,
                userInvite: createSubmitted(true),
            };
        }
        case "FAILURE_INVITE_USER": {
            return {
                ...state,
                userInvite: createSubmitErrored(action.payload.error, action.payload.error),
            };
        }
        case "RESET_INVITE_USER": {
            return {
                ...state,
                userInvite: createUnsubmitted(true),
            };
        }
        default: {
            return state;
        }
    }
}
