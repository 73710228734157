import { useEffect } from "react";

const makeErrorMessage = (error) => `[GATracker]: ${error}`;

export const GATracker = ({ history }) => {
    useEffect(() => {
        if (!history || !history.listen) {
            console.warn(makeErrorMessage("No history object or invalid history object given as prop. Analytics not active"));
            return;
        }

        return history.listen((location) => {
            if (window.gtag) {
                try {
                    window.gtag('config', 'UA-5294435-14', {
                        page_path: `${location.pathname}`,
                    });
                } catch (e) {
                    console.error(makeErrorMessage(`GA Failure - ${e.message}`));
                }
            }
        });
    }, [history]);    

    return null;
};
