import { useState, useCallback } from "react";
import { InputOnChangeData } from "semantic-ui-react";

type UseInputResponse = [
    string,
    (_1: any, data: InputOnChangeData) => void,
];

export const useInput = (initial: string = ""): UseInputResponse => {
    const [inputValue, setValue] = useState(initial);

    const onInput = useCallback((_1, { value }: InputOnChangeData) => {
        setValue(value);
    }, [setValue]);

    return [inputValue, onInput];
};