import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";

export const gateBooleanToBoolean = (gateBoolean: string = "") => gateBoolean.toUpperCase() === "Y";

export const gateNonstandardDateToDate = (str: string): Date | null => {

    // Try parsing the date directly and return if successful
    const attemptedISOParse = parseISO(str);

    if (isValid(attemptedISOParse)) {
        return attemptedISOParse;
    }

    // example: 20.1.2020 9:45:00
    const gateDateFormat = "d.M.yyyy H:mm:ss";
    const attemptedGateParse = parse(str, gateDateFormat, new Date());

    return isValid(attemptedGateParse)
        ? attemptedGateParse
        : null;
};
