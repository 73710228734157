import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";

export enum EProceedingStatus {
    WontBeDone = "wontbedone",
    InProgress = "inprogress",
    Done = "done",
    Invalid = "$INVALID",
}

export const isProceedingStatus = (t: any): t is EProceedingStatus => {
    return [
        EProceedingStatus.Done,
        EProceedingStatus.InProgress,
        EProceedingStatus.WontBeDone,
    ].includes(t);
};

export const getProceedingStatusColor = (status: EProceedingStatus): SemanticCOLORS => {
    switch (status) {
        case EProceedingStatus.InProgress:
            return "orange";
        case EProceedingStatus.Done:
            return "green";
        case EProceedingStatus.WontBeDone:
            return "blue";
        case EProceedingStatus.Invalid:
            return "red";
    }
};

export interface IProceedingDataInList {
    id: number;
    reportId: number;
    reportRowId: number;
    reportRowType: string;
    status: EProceedingStatus;
    responsibility: string;
    writeupDate: Date;
}

export interface IProceedingsData extends IProceedingDataInList {
    additionalInfo: string;
    dueDate?: string;
}

// API interfaces

export interface IProceedingDataListResponse {
    toimenpiteet: IProceedingDataInListDTO[];
    success: boolean;
}

export interface IProceedingDataResponse {
    toimenpide: IProceedingDataDTO;
    success: boolean;
}

export interface IProceedingDataInListDTO {
    id: number;
    ilmoitus: number;
    ilmoitusrivi: number;
    ilmoitusrivityyppi: string;
    muokkaaja: string;
    tila: string;
    kirjauspaiva: string;
    base64data?: string;
}

export interface IProceedingDataDTO extends IProceedingDataInListDTO {
    base64data: string;
}

export interface IProceedingBase64Data {
    additionalInfo?: string;
    responsibility?: string;
    dueDateText?: string;
}
