import { takeLeading, call, put, select } from "redux-saga/effects";
import { requestFetchCurrentCompany, createSuccessFetchCompanyAction, createFailureFetchCompanyAction } from "../actions/companyActions";
import { ITTKCompanyService } from "../../services/TTKCompanyService";
import { TResult } from "../../../sg-core/utils/Result";
import ITTKCompany from "../../interfaces/ITTKCompany";
import { selectLoggedInUserCompanyId } from "../../auth/store/authSelectors";

const makeSaga = (companyService: ITTKCompanyService) =>
    function* saga() {
        try {
            const currentCompanyId = yield select(selectLoggedInUserCompanyId);

            if (!currentCompanyId) {
                yield put(createFailureFetchCompanyAction(0, new Error("Cannot get current company without a login")));
                return;
            }

            const result: TResult<ITTKCompany> = yield call(companyService.getCompanyById, currentCompanyId);

            if (result.isSuccess) {
                yield put(createSuccessFetchCompanyAction(result.value));
            } else {
                yield put(createFailureFetchCompanyAction(0, result.isFailure ? result.error : new Error("Unexpected error")));
            }
        } catch (error) {
            yield put(createFailureFetchCompanyAction(0, error));
        }
    };

export default function createSaga(companyService: ITTKCompanyService) {
    return takeLeading(
        requestFetchCurrentCompany,
        makeSaga(companyService),
    );
}
