import { takeLeading, call, put } from "redux-saga/effects";
import { ITTKAuthService } from "../services/ITTKAuthService";
import {
    requestFetchPasswordResetCode,
    IRequestFetchPasswordResetCode,
    createFailureRequestFetchPasswordResetCode,
    createSuccessRequestFetchPasswordResetCode,
} from "./authActions";
import { TResult } from "../../../sg-core/utils/Result";

const createFetchPasswordResetTokenSaga = (authService: ITTKAuthService) =>
    function* saga(action: IRequestFetchPasswordResetCode) {
        try {
            const result: TResult<any> = yield call(authService.requestPasswordResetCode, action.payload.email);

            if (result.isFailure) {
                yield put(createFailureRequestFetchPasswordResetCode(result.error));
            } else {
                yield put(createSuccessRequestFetchPasswordResetCode());
            }
        } catch (error) {
            yield put(createFailureRequestFetchPasswordResetCode(error));
        }
    };

export default function(authService: ITTKAuthService) {
    return takeLeading(
        requestFetchPasswordResetCode,
        createFetchPasswordResetTokenSaga(authService),
    );
}
