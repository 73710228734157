import { TResult, makeSuccess, makeFailure } from "../../sg-core/utils/Result";
import ITTKCompany from "../interfaces/ITTKCompany";
import { ISgApiService } from "../../sg-core/services/ApiService";
import CompanyMapper from "../mappers/ComanyMapper";

export interface ITTKCompanyService {
    getCompanyById: (id: string | number) => Promise<TResult<ITTKCompany>>;
    saveCompany: (company: ITTKCompany) => Promise<TResult<ITTKCompany>>;
    deleteDepartment: (id: number) => Promise<TResult<any>>;
}

export interface ICompanyQueryResult {
    success: true;
    action: "hae_yritys";
    yritys: {
        guid: string;
        id: number;
        nimi: string;
        deleted: "Y" | "N";
        toimiala: number;
        osastot: Array<{
            id: number,
            nimi: string;
            vastuuhenkilo_etunimi?: string;
            vastuuhenkilo_sukunimi?: string;
            vastuuhenkilo_id?: string;

        }>;
    }
}

export const getTTKCompanyService = (apiService: ISgApiService): ITTKCompanyService => {
    const getCompanyById = async (id: string | number): Promise<TResult<ITTKCompany>> => {

        const getWithoutToken = typeof id === "string";
        const payload = getWithoutToken
                            ? { guid: id }
                            : { id };

        const action = getWithoutToken
                        ? "hae_yritys_anonyymi"
                        : "hae_yritys";

        const apiResponse = await apiService.sendAction<ICompanyQueryResult>(
            action,
            { yritys: payload },
            getWithoutToken,
        );

        if (apiResponse.isSuccess === true) {
            return makeSuccess(CompanyMapper.apiToDomain(apiResponse.value.yritys));
        }

        return apiResponse;
    };

    const saveCompany = async (company: ITTKCompany): Promise<TResult<ITTKCompany>> => {
        const action = "paivita_yrityksen_tiedot";

        const  { osastot, ...yritys } = CompanyMapper.domainToApi(company);

        const departmentsWithoutNewIds = osastot!.map((dep) => {
            if (dep.id === -1) {
                return { nimi: dep.nimi };
            }
            return dep;
        });

        const payload = {
            yritys,
            osastot: departmentsWithoutNewIds,
        };

        const apiResponse = await apiService.sendAction<ICompanyQueryResult>(action, payload);

        if (apiResponse.isFailure) {
            return makeFailure(apiResponse.error);
        }

        const response = apiResponse.value;

        if (!(response as any).success) {
            return makeFailure(new Error("errServerError"));
        }

        return makeSuccess(CompanyMapper.apiToDomain({
            ...response.yritys,
            osastot: (response as any).osastot,
        }));
    };

    const deleteDepartment = async (id: number): Promise<TResult<any>> => {
        const action = "poista_osasto";

        const payload = { osasto: { id } };

        const result = await apiService.sendAction(action, payload);

        if (result.isFailure) {
            return makeFailure(result.error);
        }

        return makeSuccess(true);
    };

    return {
        getCompanyById,
        saveCompany,
        deleteDepartment,
    };
};
