import { takeLeading, put, call } from "redux-saga/effects";
import { requestIndustryListLoad, createSuccessIndustryListAction, createFailureIndustryListAction } from "../actions/industryActions";
import { TResult } from "../../../sg-core/utils/Result";
import IIndustry from "../../interfaces/IIndustry";
import { ISgApiService } from "../../../sg-core/services/ApiService";

interface IResponse {
    toimialat: Array<{ id: number, nimi: string }>;
}

const makeFetchIndustriesSaga = (apiService: ISgApiService) =>
    function* saga() {
        try {
            const result: TResult<IResponse> = yield call(
                apiService.sendAction,
                "hae_toimialalista",
                {},
            );

            if (result.isSuccess) {
                if (!(result.value as any).success) {
                    yield put(createFailureIndustryListAction(new Error("errServerError")));
                } else {
                    const industries = result.value.toimialat.map(({ id, nimi }): IIndustry => ({
                        id,
                        name: nimi,
                    }));
                    yield put(createSuccessIndustryListAction(industries));
                }
            } else {
                yield put(createFailureIndustryListAction(result.isFailure ? result.error : new Error()));
            }
        } catch (e) {
            yield put(createFailureIndustryListAction(e));
        }
    };

export default function create(apiService: ISgApiService) {
    return takeLeading(
        requestIndustryListLoad,
        makeFetchIndustriesSaga(apiService),
    );
}
