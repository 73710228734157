import React, { memo } from "react";
import { Grid, MenuProps } from "semantic-ui-react";

interface ISgSiteTopProps {
    logoUrl?: string;
    logoWrapper?: React.ComponentType;
    color: MenuProps["color"];
    topRightContent: React.ComponentType;
}

const SgSiteTop: React.FC<ISgSiteTopProps> = ({ logoUrl, color, logoWrapper: LogoWrap, topRightContent }) => (
    <Grid>
        <Grid.Row>
            <Grid.Column width={8}>
                { logoUrl && (
                    LogoWrap
                        ? <LogoWrap><img src={logoUrl} alt="logo" className="sg-top-logo" /></LogoWrap>
                        : <img src={logoUrl} alt="logo" className="sg-top-logo" />
                )}
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
                <div className="sg-top-buttons middle">
                    {React.createElement(topRightContent)}
                </div>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default memo(SgSiteTop);
