import { ICompanyQueryResult } from "../services/TTKCompanyService";
import ITTKCompany, { ITTKCompanyDepartment } from "../interfaces/ITTKCompany";

const DepartmentMapper = {
    apiToDomain: (api: ICompanyQueryResult["yritys"]["osastot"][0]): ITTKCompanyDepartment => ({
        id: api.id,
        name: api.nimi,
        order: -1,
        responsibleFirstName: api.vastuuhenkilo_etunimi,
        responsibleLastName: api.vastuuhenkilo_sukunimi,
        responsibleId: Number(api.vastuuhenkilo_id)

    }),
};

const CompanyMapper = {
    apiToDomain: (api: ICompanyQueryResult["yritys"]): ITTKCompany => ({
        id: api.id,
        name: api.nimi,
        sector: api.toimiala,
        departments: (api.osastot || []).map(DepartmentMapper.apiToDomain),
        guid: api.guid,
    }),
    domainToApi: (domain: ITTKCompany): Partial<ICompanyQueryResult["yritys"]> => ({
        nimi: domain.name,
        osastot: domain.departments.map((dep) => ({ id: dep.id, nimi: dep.name, vastuuhenkilo: dep.responsibleId })),
        toimiala: domain.sector,
        id: domain.id,
    }),
};

export default CompanyMapper;
