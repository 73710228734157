import { takeLeading, put, call, select } from "redux-saga/effects";
import { TResult } from "../../../sg-core/utils/Result";
import {
    IRequestSaveProceeding,
    createSuccessSaveProceeding,
    createFailureSaveProceeding,
    requestSaveProceeding,
} from "../actions/proceedingActions";
import { IProceedingsService } from "../../services/ProceedingsService";
import { IProceedingsData } from "../../interfaces/IProceedingData";
import { selectTurvaArviEditedProceedingsById } from "../selectors";
import { TSubmittable } from "../../../sg-core/models/ISubmittable";
import assignDefined from "../../../sg-core/utils/assignDefined";

const makeSaveProceedingSaga = (proceedingService: IProceedingsService) =>
    function* saga(action: IRequestSaveProceeding) {
        try {
            const editedProceedings: {[key: number]: TSubmittable<IProceedingsData>} =
                    yield select(selectTurvaArviEditedProceedingsById);

            const { data } = editedProceedings[action.payload.id];

            const result: TResult<IProceedingsData> = yield call(
                proceedingService.updateProceedingById,
                data,
            );

            if (result.isSuccess) {
                yield put(createSuccessSaveProceeding(assignDefined(data, result.value)));
            } else {
                yield put(createFailureSaveProceeding(action.payload.id, result.isFailure ? result.error : new Error()));
            }
        } catch (e) {
            yield put(createFailureSaveProceeding(action.payload.id, e));
        }
    };

export default function create(proceedingService: IProceedingsService) {
    return takeLeading(
        requestSaveProceeding,
        makeSaveProceedingSaga(proceedingService),
    );
}
