import React, { memo, Fragment } from "react";
import { Header, Icon, Segment, Grid, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import RouteLink from "../../sg-core/components/RouteLink";
import { useLoginStatus } from "../auth/store/authSelectors";
import { isLoaded } from "../../sg-core/models/ILoadable";
import { AuthenticationType } from "../auth/store/authReducer";
import { getRouteHrefById, RouteId } from "../../routing";
import { Redirect } from "react-router-dom";

/**
 * This module contains the frontpage of the Turva-arvi application.
 *
 * FrontPageView -component is used for the rendering of the page
 *
 * FrontPageContainer -component is used to fetch the require text translations
 * from the translation service.
 */

interface IFrontPageViewProps {
    headerText: string;
    turvaArviDescription: string;
    turvaArviDescriptionNote: string;
    accidentReportText: string;
    closeCallReportText: string;
    safetyObservationText: string;
    createNewText: string;
    seeAccidentListText: string;
}

interface IFrontPageBoxProps {
    linkTo: keyof typeof RouteId;
    buttonText: string;
    text: string;
}

const FrontPageBox: React.FC<IFrontPageBoxProps> = ({ linkTo, buttonText, text }) => (
    <Fragment>
        <RouteLink id={linkTo}>
            <Button primary fluid size="large"  className="front-page-box-link">{buttonText}</Button>
        </RouteLink>
        <p className="front-page-box-text">{text}</p>
    </Fragment>
);

const FrontPageView: React.FC<IFrontPageViewProps> = (props) => {

    const { t } = useTranslation();

    const {
        headerText,
        turvaArviDescription,
        turvaArviDescriptionNote,
    } = props;

    return (
        <Fragment>
            <Header as="h2" icon textAlign="center">
                <Icon circular color="orange" name="bell outline" />
                <Header.Content>{headerText}</Header.Content>
            </Header>

            <Segment basic textAlign="center">
                <p>{turvaArviDescription}</p>
                <p>
                    <small>
                        <em>
                            {turvaArviDescriptionNote}&nbsp;
                            <a href="https://www.tyosuojelu.fi/tyoterveys-ja-tapaturmat/tyotapaturmat">tyosuojelu.fi</a>
                        </em>
                    </small>
                </p>
            </Segment>

            <Grid columns={3} stackable >
                <Grid.Column>
                    <FrontPageBox linkTo="Settings" buttonText={t("shareReportLink")} text={t("shareReportLinkDescription")} />
                </Grid.Column>
                <Grid.Column>
                    <FrontPageBox linkTo="ReportListing" buttonText={t("viewReportsLink")} text={t("viewReportsLinkDescription")} />
                </Grid.Column>
                <Grid.Column>
                    <FrontPageBox linkTo="ProceedingListView" buttonText={t("viewProceedingsLink")} text={t("viewProceedingsLinkDescription")} />
                </Grid.Column>
            </Grid>
        </Fragment>
    );
};

const MemoizedFrontPageView = memo(FrontPageView);

const FrontPageContainer: React.FC = () => {
    const { t } = useTranslation();

    const loginStatus = useLoginStatus();

    if (isLoaded(loginStatus) && loginStatus.data === AuthenticationType.AnonymousWithCompanyId) {
        return <Redirect to={getRouteHrefById("AccidentReportForm")!} />;
    }

    const textProps: IFrontPageViewProps = {
        accidentReportText: t("accidentReport"),
        closeCallReportText: t("closeCallReport"),
        headerText: t("turvaArvi"),
        safetyObservationText: t("safetyObservation"),
        createNewText: t("addNew"),
        seeAccidentListText: t("seeAllAccidentReports"),
        turvaArviDescription: t("turvaArviDescription"),
        turvaArviDescriptionNote: t("turvaArviDescriptionNote"),
    };

    return <MemoizedFrontPageView {...textProps} />;
};

export default FrontPageContainer;
