export default function assignDefined<T extends object>(target: T, ...sources: Array<Partial<T>>) {
    return sources.reduce<T>((assigned, source) => {
        const definedValues: Partial<T> = {};

        const keys: Array<keyof T> = Object.keys(source) as Array<keyof T>;

        keys.forEach((key) => {
            const value: any = source[key];
            if (value !== undefined) {
                definedValues[key] = value;
            }
        });

        return Object.assign(assigned, definedValues);
    }, target);
}
