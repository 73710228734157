import { IAccidentReportTableFilters, IReportTableSorting } from "../../interfaces/IAccidentReportListing";
import { TLoadable, createLoading, createLoaded, createUnloaded, createLoadErrored, isLoaded } from "../../../sg-core/models/ILoadable";
import { IAccidentReport, IAccidentReportInList } from "../../interfaces/IAccidentReportData";
import { TAccidentReportListAction } from "../actions/accidentReportList";
import { ISaveAccidentReportSuccess, IDirectUpdateReportData } from "../actions";
import AccidentReportMapper from "../../mappers/AccidentReportMapper";
import assignDefined from "../../../sg-core/utils/assignDefined";

export interface IReportListingState {
    filters: IAccidentReportTableFilters;
    sorting: IReportTableSorting;
    pages: { [index: number]: TLoadable<IAccidentReport[]> };
    pagination: {
        pageSize: number;
        pageIndex: number;
        totalPages?: number;
    };
    list: TLoadable<IAccidentReportInList[]>;
}

export const initialListingState: IReportListingState = {
    filters: { status: "all" },
    pages: {},
    pagination: { pageSize: 10, pageIndex: 0 },
    sorting: { sortBy: "createdAt", descending: false },
    list: createUnloaded(),
};

type HandledAction
    = TAccidentReportListAction
    | ISaveAccidentReportSuccess
    | IDirectUpdateReportData;

// tslint:disable-next-line: max-line-length
export default function reducer(state: IReportListingState = initialListingState, action: HandledAction): IReportListingState {

    switch (action.type) {
        case "ACCIDENT_REPORT_LIST_FILTERS_CHANGED": {
            return {
                ...state,
                filters: action.payload.filters,

                // Pages are no longer valid, since filtering happens on the
                // server side.
                pages: {},
                pagination: { ...state.pagination, totalPages: undefined, pageIndex: 0 },
            };
        }
        case "ACCIDENT_REPORT_LIST_SORTING_CHANGED": {
            return {
                ...state,
                sorting: action.payload,

                // Same as with filter change, pages are no longer valid since
                // sorting happens on the server side. (Page count stays)
                pages: {},
            };
        }
        case "REPORT_LIST_PAGE_CHANGED": {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pageIndex: action.payload.newPage,
                },
            };
        }
        case "REQUEST_REPORT_LIST_PAGE_LOAD": {
            const newPages = { ...state.pages, [action.payload.index]: createLoading() };
            return {
                ...state,
                pages: newPages,
            };
        }
        case "SUCCESS_REPORT_LIST_PAGE_LOAD": {
            const newPages = { ...state.pages, [action.payload.pageIndex]: createLoaded(action.payload.page) };
            const newPagination = { ...state.pagination, totalPages: action.payload.totalPages };
            return {
                ...state,
                pages: newPages,
                pagination: newPagination,
            };
        }

        // FETCH WHOLE LIST REDUCERS

        case "REQUEST_REPORT_LIST_LOAD": {
            return {
                ...state,
                list: createLoading(),
            };
        }
        case "SUCCESS_REPORT_LIST_LOAD": {
            return {
                ...state,
                list: createLoaded(action.payload.list),
            };
        }
        case "FAILURE_REPORT_LIST_LOAD": {
            return {
                ...state,
                list: createLoadErrored(action.error),
            };
        }

        // FETCH SINGLE - PERFORM LIST UPDATE

        case "SUCCESS_SAVE_ACCIDENT_REPORT": {
            if (isLoaded(state.list)) {
                const updatedReport = action.payload.updatedReport;
                return {
                    ...state,
                    list: createLoaded(state.list.data.map((report) => {
                        if (report.id === updatedReport.id) {
                            const result = AccidentReportMapper.domainToList(updatedReport);
                            if (result.isFailure) {
                                return report;
                            }

                            return result.value;
                        }
                        return report;
                    })),
                };
            }
            return state;
        }

        // IF REPORT IS UPDATED DIRECTLY

        case "DIRECT_UPDATE_ACCIDENT_REPORT": {
            if (isLoaded(state.list)) {
                const updatedList = state.list.data.map((report): IAccidentReportInList => {
                    if (report.id === action.paylod.report.id) {
                        const domainUpdated = assignDefined(
                            AccidentReportMapper.listToDomain(report),
                            action.paylod.report,
                        );
                        const listUpdated = AccidentReportMapper.domainToList(domainUpdated);
                        if (listUpdated.isFailure) {
                            return report;
                        }

                        return listUpdated.value;
                    }
                    return report;
                });

                return {
                    ...state,
                    list: createLoaded(updatedList),
                };
            }
        }
    }

    return state;
}
