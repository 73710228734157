const UNLOADED = "unloaded";
const LOADING = "loading";
const LOADED = "loaded";
const LOAD_ERRORED = "load-errored";

export interface ILoadableUnloaded<T> {
    readonly $: typeof UNLOADED;
}

export interface ILoadableLoading<T> {
    readonly $: typeof LOADING;
}

export interface ILoadableLoaded<T> {
    readonly $: typeof LOADED;
    readonly data: T;
}

export interface ILoadableErrored<T> {
    readonly $: typeof LOAD_ERRORED;
    readonly error: Error;
}

export type TLoadable<T>
    = ILoadableUnloaded<T>
    | ILoadableLoading<T>
    | ILoadableLoaded<T>
    | ILoadableErrored<T>;

// CHECK TYPE

export function isUnloaded<T>(d: TLoadable<T>): d is ILoadableUnloaded<T> {
    return d.$ === UNLOADED;
}

export function isLoading<T>(d: TLoadable<T>): d is ILoadableLoading<T> {
    return d.$ === LOADING;
}

export function isLoaded<T>(d: TLoadable<T>): d is ILoadableLoaded<T> {
    return d.$ === LOADED;
}

export function isLoadErrored<T>(d: TLoadable<T>): d is ILoadableErrored<T> {
    return d.$ === LOAD_ERRORED;
}

// CREATE

export const createUnloaded = <T>(): ILoadableUnloaded<T> => ({ $: UNLOADED });

export const createLoading = <T>(): ILoadableLoading<T> => ({ $: LOADING });

export const createLoaded = <T>(data: T): ILoadableLoaded<T> => ({ $: LOADED, data });

export const createLoadErrored = <T>(error: Error): ILoadableErrored<T> => ({ $: LOAD_ERRORED, error });
