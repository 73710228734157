import { takeLeading, put, call } from "redux-saga/effects";
import { TResult } from "../../../sg-core/utils/Result";
import {
    IRequestFetchProceeding,
    requestFetchProceeding,
    createSuccessFetchProceedingAction,
    createFailureFetchProceedingAction,
} from "../actions/proceedingActions";
import { IProceedingsService } from "../../services/ProceedingsService";
import { IProceedingsData } from "../../interfaces/IProceedingData";

const makeFetchProceedingsSaga = (proceedingService: IProceedingsService) =>
    function* saga(action: IRequestFetchProceeding) {
        try {
            const result: TResult<IProceedingsData> = yield call(
                proceedingService.getProceedingById,
                action.payload.id,
            );

            if (result.isSuccess) {
                yield put(createSuccessFetchProceedingAction(result.value));
            } else {
                yield put(createFailureFetchProceedingAction(action.payload.id, result.isFailure ? result.error : new Error()));
            }
        } catch (e) {
            yield put(createFailureFetchProceedingAction(action.payload.id, e));
        }
    };

export default function create(proceedingService: IProceedingsService) {
    return takeLeading(
        requestFetchProceeding,
        makeFetchProceedingsSaga(proceedingService),
    );
}
