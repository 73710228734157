import { takeLeading, put, call, select } from "redux-saga/effects";
import { IAccidentReportService } from "../../services/AccidentReportService";
import { requestReportListLoad, createSuccessFetchReportListAction, createFailureFetchReportListAction } from "../actions/accidentReportList";
import { IAccidentReportInList } from "../../interfaces/IAccidentReportData";
import { selectLoggedInUserCompanyId } from "../../auth/store/authSelectors";
import { TResult } from "../../../sg-core/utils/Result";

const makeFetchReportListSaga = (accidentReportService: IAccidentReportService) =>
    function* saga() {
        try {
            const currentCompanyId = yield select(selectLoggedInUserCompanyId);
            const result: TResult<{
                totalItems: number,
                data: IAccidentReportInList[],
            }> = yield call(accidentReportService.getAccidentReportList, currentCompanyId);

            console.info(result);

            if (result.isSuccess) {
                yield put(createSuccessFetchReportListAction(result.value.data));
            } else {
                yield put(createFailureFetchReportListAction(result.isFailure ? result.error : new Error()));
            }
        } catch (e) {
            yield put(createFailureFetchReportListAction(e));
        }
    };

export default function create(accidentReportService: IAccidentReportService) {
    return takeLeading(
        requestReportListLoad,
        makeFetchReportListSaga(accidentReportService),
    );
}
