import ITTKUser, { ITTKUserDTO } from "../interfaces/ITTKUser";
import { gateBooleanToBoolean } from "../../sg-core/utils/GateSupport";

const UserMapper = {
    apiToDomain: (api: ITTKUserDTO): ITTKUser => {
        return {
            id: api.id,
            firstName: api.etunimi,
            surname: api.sukunimi,
            email: api.email,
            admin: gateBooleanToBoolean(api.on_paakayttaja),
            language: "fi",
        };
    },
    domainToApi: (domain: ITTKUser): ITTKUserDTO => {
        return {
            id: domain.id,
            etunimi: domain.firstName,
            sukunimi: domain.surname,
            email: domain.email,
            on_paakayttaja: domain.admin ? "Y" : "N",
        };
    },
};

export default UserMapper;
