import { takeEvery, call, put, select } from "redux-saga/effects";
import { TResult } from "../../../sg-core/utils/Result";
import { ITTKCompanyService } from "../../services/TTKCompanyService"; 
import { selectCurrentEditedCompany } from "../selectors";
import { TSubmittable } from "../../../sg-core/models/ISubmittable";
import ITTKCompany from "../../interfaces/ITTKCompany";
import {
    requestSaveEditedCurrentCompany,
    createFailureSaveEditedCurrentCompany,
    createSuccessSaveEditedCurrentCompany,
} from "../actions/companyActions";

const makeSaveUserSaga = (companyService: ITTKCompanyService) =>
    function* saga() {
        try {
            const editedCompany: TSubmittable<ITTKCompany> = yield select(selectCurrentEditedCompany);

            const result: TResult<ITTKCompany> = yield call(companyService.saveCompany, editedCompany.data);
            console.log(editedCompany)
            if (result.isFailure) {
                yield put(createFailureSaveEditedCurrentCompany(result.error));
                return;
            }

            yield put(createSuccessSaveEditedCurrentCompany(result.value));
        } catch (error) {
            yield put(createFailureSaveEditedCurrentCompany(error));
        }

    };

export default function createSaga(companyService: ITTKCompanyService) {
    return takeEvery(
        requestSaveEditedCurrentCompany,
        makeSaveUserSaga(companyService),
    );
}
