import React from "react";
import { createStore, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import sagaMiddleware from "./sagaMiddleware";

import createSagas from "../turva-arvi/store/sagas";

import { getSgApiService } from "../sg-core/services/ApiService";
import getAccidentReportService from "../turva-arvi/services/AccidentReportService";
import { getTTKUserService } from "../turva-arvi/services/TTKUserService";
import { getTTKCompanyService } from "../turva-arvi/services/TTKCompanyService";

import { getTTKAuthStorage } from "../turva-arvi/auth/services/TTKAuthStorage";
import { getTTKAuthService } from "../turva-arvi/auth/services/ITTKAuthService";
import { getProceedingService } from "../turva-arvi/services/ProceedingsService";

const middlewares = [applyMiddleware(sagaMiddleware)];

if (process.env.NODE_ENV === "development" && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    middlewares.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
}

export const store = createStore(
    rootReducer,
    compose(...middlewares),
);

const apiService = getSgApiService(process.env.REACT_APP_API_URL!);

sagaMiddleware.run(createSagas(
    getAccidentReportService(apiService),
    getTTKUserService(apiService),
    getTTKCompanyService(apiService),
    getTTKAuthService(
        getTTKAuthStorage("ttk:auth", sessionStorage),
        apiService,
    ),
    apiService,
    getProceedingService(apiService),
));

export const StoreProvider: React.FC = ({ children }) => (
    <Provider store={store}>
        {children}
    </Provider>
);
