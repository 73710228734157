import { takeLeading, put, call, select } from "redux-saga/effects";
import { selectLoggedInUserCompanyId } from "../../auth/store/authSelectors";
import { TResult } from "../../../sg-core/utils/Result";
import { requestFetchProceedings, createSuccessFetchProceedingsAction, createFailureFetchProceedingsAction } from "../actions/proceedingActions";
import { IProceedingsService } from "../../services/ProceedingsService";
import { IProceedingDataInList } from "../../interfaces/IProceedingData";

const makeFetchProceedingsSaga = (proceedingService: IProceedingsService) =>
    function* saga() {
        try {
            const currentCompanyId = yield select(selectLoggedInUserCompanyId);
            const result: TResult<IProceedingDataInList[]> = yield call(
                proceedingService.getProceedingsList,
                currentCompanyId,
            );

            if (result.isSuccess) {
                yield put(createSuccessFetchProceedingsAction(result.value));
            } else {
                yield put(createFailureFetchProceedingsAction(result.isFailure ? result.error : new Error()));
            }
        } catch (e) {
            yield put(createFailureFetchProceedingsAction(e));
        }
    };

export default function create(proceedingService: IProceedingsService) {
    return takeLeading(
        requestFetchProceedings,
        makeFetchProceedingsSaga(proceedingService),
    );
}
