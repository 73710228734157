import { takeEvery, put, call, select } from "redux-saga/effects";

import { IAccidentReportService } from "../../services/AccidentReportService";
import { IRequestPageLoadAction, requestPageLoad, createSuccessFetchReportListPage } from "../actions/accidentReportList";
import {
    selectTurvaArviReportListingPagination,
    selectTurvaArviReportListingSorting,
    selectTurvaArviReportListingFilters,
} from "../selectors";
import { IReportListingState } from "../reducers/accidentReportListReducer";
import { IAccidentReport } from "../../interfaces/IAccidentReportData";
import { TResult } from "../../../sg-core/utils/Result";

const makeFetchReportPageSaga = (accidentReportService: IAccidentReportService) =>
function* saga(action: IRequestPageLoadAction) {
    try {
        const { payload: { index } } = action;
        const pagination: IReportListingState["pagination"] = yield select(selectTurvaArviReportListingPagination);
        const sorting: IReportListingState["sorting"]  = yield select(selectTurvaArviReportListingSorting);
        const filters: IReportListingState["filters"] = yield select(selectTurvaArviReportListingFilters);

        const result: TResult<{ totalItems: number, data: IAccidentReport[] }> = yield call(
            accidentReportService.getReportPage,
            index,
            pagination,
            sorting,
            filters,
        );

        if (result.isSuccess) {
            const pageCount = Math.ceil(result.value.totalItems / pagination.pageSize);

            yield put(createSuccessFetchReportListPage(index, result.value.data, pageCount));
        }
    } catch (e) {
        console.error(e);
    }
};

export default function create(accidentReportService: IAccidentReportService) {
    return takeEvery(
        requestPageLoad,
        makeFetchReportPageSaga(accidentReportService),
    );
}
