import { IProceedingDataInList, IProceedingsData, EProceedingStatus } from "../../interfaces/IProceedingData";

export const requestFetchProceedings = "REQUEST_FETCH_PROCEEDINGS";
const successFetchProceedings = "SUCCESS_FETCH_PROCEEDINGS";
const failureFetchProceedings = "FAILURE_FETCH_PROCEEDINGS";

export const requestFetchProceeding = "REQUEST_FETCH_PROCEEDING";
const successFetchProceeding = "SUCCESS_FETCH_PROCEEDING";
const failureFetchProceeding = "FAILURE_FETCH_PROCEEDING";

const markProceedingForEditing = "MARK_PROCEEDING_FOR_EDITING";
const updateEditedProceeding = "UPDATE_EDITED_PROCEEDING";

export const requestSaveProceeding = "REQUEST_SAVE_PROCEEDINGS";
export const successSaveProceeding = "SUCCESS_SAVE_PROCEEDING";
const failureSaveProceeding = "FAILURE_SAVE_PROCEEDING";

const updateProceedingFilters = "UPDATE_PROCEEDING_FILTERS";

interface IRequestFetchProceedings { type: typeof requestFetchProceedings; }
interface ISuccessFetchProceedings {
    type: typeof successFetchProceedings;
    payload: { list: IProceedingDataInList[]; };
}
interface IFailureFetchProceedings {
    type: typeof failureFetchProceedings;
    error: Error;
}

export interface IRequestFetchProceeding {
    type: typeof requestFetchProceeding;
    payload: { id: number };
}
interface ISuccessFetchProceeding {
    type: typeof successFetchProceeding;
    payload: { proceeding: IProceedingsData };
}
interface IFailureFetchProceeding {
    type: typeof failureFetchProceeding;
    payload: { id: number; error: Error };
}

interface IMarkProceedingForEditing {
    type: typeof markProceedingForEditing;
    payload: { id: number };
}

interface IUpdateEditedProceeding {
    type: typeof updateEditedProceeding;
    payload: {
        id: number;
        editedFields: Partial<IProceedingsData>;
    };
}

export interface IRequestSaveProceeding {
    type: typeof requestSaveProceeding;
    payload: {
        id: number;
    };
}

export interface ISuccessSaveProceeding {
    type: typeof successSaveProceeding;
    payload: { proceeding: IProceedingsData };
}

interface IFailureSaveProceeding {
    type: typeof failureSaveProceeding;
    payload: { id: number; error: Error };
}

interface IUpdateProceedingFilters {
    type: typeof updateProceedingFilters;
    payload: { status: EProceedingStatus | "all" };
}

export type TProceedingAction
    = IRequestFetchProceedings
    | ISuccessFetchProceedings
    | IFailureFetchProceedings
    | IRequestFetchProceeding
    | ISuccessFetchProceeding
    | IFailureFetchProceeding
    | IMarkProceedingForEditing
    | IUpdateEditedProceeding
    | IRequestSaveProceeding
    | ISuccessSaveProceeding
    | IFailureSaveProceeding
    | IUpdateProceedingFilters;

export const createRequestFetchProceedingsAction = (): IRequestFetchProceedings => ({
    type: requestFetchProceedings,
});

export const createSuccessFetchProceedingsAction = (list: IProceedingDataInList[]): ISuccessFetchProceedings => ({
    type: successFetchProceedings,
    payload: { list },
});

export const createFailureFetchProceedingsAction = (error: Error): IFailureFetchProceedings => ({
    type: failureFetchProceedings,
    error,
});

export const createRequestFetchProceedingAction = (id: number): IRequestFetchProceeding => ({
    type: requestFetchProceeding,
    payload: { id },
});

export const createSuccessFetchProceedingAction = (proceeding: IProceedingsData): ISuccessFetchProceeding => ({
    type: successFetchProceeding,
    payload: { proceeding },
});

export const createFailureFetchProceedingAction = (id: number, error: Error): IFailureFetchProceeding => ({
    type: failureFetchProceeding,
    payload: { id, error },
});

export const createMarkProceedingForEditingAction = (id: number): IMarkProceedingForEditing => ({
    type: markProceedingForEditing,
    payload: { id },
});

export const createUpdateEditedProceedingAction = (id: number, editedFields: Partial<IProceedingsData>): IUpdateEditedProceeding => ({
    type: updateEditedProceeding,
    payload: { id, editedFields },
});

export const createRequestSaveProceeding = (id: number): IRequestSaveProceeding => ({
    type: requestSaveProceeding,
    payload: { id },
});

export const createSuccessSaveProceeding = (proceeding: IProceedingsData): ISuccessSaveProceeding => ({
    type: successSaveProceeding,
    payload: { proceeding },
});

export const createFailureSaveProceeding = (id: number, error: Error): IFailureSaveProceeding => ({
    type: failureSaveProceeding,
    payload: { id, error },
});

export const createUpdateProceedingFilters = (status: EProceedingStatus | "all"): IUpdateProceedingFilters => ({
    type: updateProceedingFilters,
    payload: { status },
});
