import ITTKUser, { IUserInviteFormData } from "../../interfaces/ITTKUser";

export const requestFetchUsers = "REQUEST_FETCH_USERS";
const successFetchUsers = "SUCCESS_FETCH_USERS";
const failureFetchUsers = "FAILURE_FETCH_USERS";
const userMarkedForEditing = "USER_MARKED_FOR_EDITING";
const updateEditedUser = "UPDATE_EDITED_USER";
export const requestSaveUser = "REQUEST_SAVE_USER";
const successSaveUser = "SUCCESS_SAVE_USER";
const failureSaveUser = "FAILURE_SAVE_USER";

export const requestInviteUser = "REQUEST_INVITE_USER";
const successInviteUser = "SUCCESS_INVITE_USER";
const failureInviteUser = "FAILURE_INVITE_USER";
const resetInviteUser = "RESET_INVITE_USER";

interface IRequestFetchUsers {
    type: typeof requestFetchUsers;
}

interface ISuccessFetchUsers {
    type: typeof successFetchUsers;
    payload: { users: ITTKUser[]; };
}

interface IFailureFetchUsers {
    type: typeof failureFetchUsers;
    payload: { error: Error };
}

interface IUserMarkedForEditing {
    type: typeof userMarkedForEditing;
    payload: { user: ITTKUser };
}

interface IUpdateEditedUser {
    type: typeof updateEditedUser;
    payload: { id: number, editedProps: Partial<ITTKUser> };
}

export interface IRequestSaveUser {
    type: typeof requestSaveUser;
    payload: { user: ITTKUser };
}

interface ISuccessSaveUser {
    type: typeof successSaveUser;
    payload: { savedUser: ITTKUser };
}

interface IFailureSaveUser {
    type: typeof failureSaveUser;
    payload: { id: number, error: Error };
}

interface IResetInviteUser {
    type: typeof resetInviteUser;
}

/*
export const requestInviteUser = "REQUEST_INVITE_USER";
const successInviteUser = "SUCCESS_INVITE_USER";
const failureInviteUser = "FAILURE_INVITE_USER";
*/

export interface IRequestInviteUser {
    type: typeof requestInviteUser;
    payload: { user: IUserInviteFormData };
}

interface ISuccessInviteUser {
    type: typeof successInviteUser;
}

interface IFailureInviteUser {
    type: typeof failureInviteUser;
    payload: { error: Error };
}



export type IUserAction
    = IRequestFetchUsers
    | ISuccessFetchUsers
    | IFailureFetchUsers
    | IUserMarkedForEditing
    | IUpdateEditedUser
    | IRequestSaveUser
    | ISuccessSaveUser
    | IFailureSaveUser
    | IRequestInviteUser
    | ISuccessInviteUser
    | IFailureInviteUser
    | IResetInviteUser;

export const createRequestFetchUsersAction = (): IRequestFetchUsers => ({
    type: requestFetchUsers,
});

export const createSuccessFetchUsersAction = (users: ITTKUser[]): ISuccessFetchUsers => ({
    type: successFetchUsers,
    payload: { users },
});

export const createFailureFetchUsersAction = (error: Error): IFailureFetchUsers => ({
    type: failureFetchUsers,
    payload: { error },
});

export const createUserMarkedForEditingAction = (user: ITTKUser): IUserMarkedForEditing => ({
    type: userMarkedForEditing,
    payload: { user },
});

export const createUpdateEditedUserAction = (id: number, editedProps: Partial<ITTKUser>): IUpdateEditedUser => ({
    type: updateEditedUser,
    payload: { id, editedProps },
});

export const createRequestSaveUseraction = (user: ITTKUser): IRequestSaveUser => ({
    type: requestSaveUser,
    payload: { user },
});

export const createSuccessSaveUserAction = (savedUser: ITTKUser): ISuccessSaveUser => ({
    type: successSaveUser,
    payload: { savedUser },
});

export const createFailureSaveUserAction = (id: number, error: Error): IFailureSaveUser => ({
    type: failureSaveUser,
    payload: { id, error },
});

export const createRequestInviteUserAction = (user: IUserInviteFormData): IRequestInviteUser => ({
    type: requestInviteUser,
    payload: { user },
});

export const createSuccessInviteUserAction = (): ISuccessInviteUser => ({
    type: successInviteUser,
});

export const createFailureInviteUserAction = (error: Error): IFailureInviteUser => ({
    type: failureInviteUser,
    payload: { error },
});

export const createResetInviteUser = (): IResetInviteUser => ({ type: resetInviteUser });
