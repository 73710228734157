
export interface IUnsubmitted<T> {
    readonly $: "unsubmitted";
    readonly data: T;
}

export interface ISubmitting<T> {
    readonly $: "submitting";
    readonly data: T;
}

export interface ISubmitted<T, TResponse = any> {
    readonly $: "submitted";
    readonly data: T;
    readonly response?: TResponse;
}

export interface ISubmitErrored<T> {
    readonly $: "submit-errored";
    readonly data: T;
    readonly error: Error;
}

export type TSubmittable<T, TResponse = any>
    = IUnsubmitted<T>
    | ISubmitting<T>
    | ISubmitted<T, TResponse>
    | ISubmitErrored<T>;

// CHECK TYPE

export function isUnsubmitted<T>(d: TSubmittable<T>): d is IUnsubmitted<T> {
    return d.$ === "unsubmitted";
}

export function isSubmitting<T>(d: TSubmittable<T>): d is ISubmitting<T> {
    return d.$ === "submitting";
}

export function isSubmitted<T, TResponse>(d: TSubmittable<T, TResponse>): d is ISubmitted<T, TResponse> {
    return d.$ === "submitted";
}

export function isSubmitErrored<T>(d: TSubmittable<T>): d is ISubmitErrored<T> {
    return d.$ === "submit-errored";
}

// CREATE

export function createUnsubmitted<T>(data: T): IUnsubmitted<T> {
    return { $: "unsubmitted", data };
}

export function createSubmitting<T>(data: T): ISubmitting<T> {
    return { $: "submitting", data };
}

export function createSubmitted<T, TResponse = any>(data: T, response?: TResponse): ISubmitted<T, TResponse> {
    return { $: "submitted", data, response };
}

export function createSubmitErrored<T>(data: T, error: Error): ISubmitErrored<T> {
    return { $: "submit-errored", data, error };
}
