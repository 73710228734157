import ITTKCompany from "../../interfaces/ITTKCompany";

export const requestFetchCurrentCompany = "REQUEST_FETCH_CURRENT_COMPANY";
const successFetchCurrentCompany = "SUCCESS_FETCH_CURRENT_COMPANY";
const failureFetchCurrentCompany = "FAILURE_FETCH_CURRENT_COMPANY";

const markCurrentCompanyForEditing = "MARK_CURRENT_COMPANY_FOR_EDIT";

export const requestSaveEditedCurrentCompany = "REQUEST_SAVE_EDITED_CURRENT_COMPANY";
const successSaveEditedCurrentCompany = "SUCCESS_SAVE_EDITED_CURRENT_COMPANY";
const failureSaveEditedCurrentCompany = "FAILURE_SAVE_EDITED_CURRENT_COMPANY";

export const requestDeleteDepartment = "REQUEST_DELETE_DEPARTMENT";
const successDeleteDepartment = "SUCCESS_DELETE_DEPARTMENT";
const failureDeleteDepartment = "FAILURE_DELETE_DEPARTMENT";

const editedCompanyDataUpdate = "EDITED_COMPANY_DATA_UPDATE";

interface IRequestFetchCurrentCompany {
    type: typeof requestFetchCurrentCompany;
}

interface ISuccessFetchCurrentCompany {
    type: typeof successFetchCurrentCompany;
    payload: {
        company: ITTKCompany;
    };
}

interface IFailureFetchCurrentCompany {
    type: typeof failureFetchCurrentCompany;
    payload: {
        id: number;
        error: Error;
    };
}

interface IMarkCurrentCompanyForEditing {
    type: typeof markCurrentCompanyForEditing;
}

interface IRequestSaveEditedCurrentCompany {
    type: typeof requestSaveEditedCurrentCompany;
}

interface ISuccessSaveEditedCurrentCompany {
    type: typeof successSaveEditedCurrentCompany;
    payload: {
        company: ITTKCompany;
    };
}

interface IFailureSaveEditedCurrentCompany {
    type: typeof failureSaveEditedCurrentCompany;
    payload: {
        error: Error;
    };
}

export interface IRequestDeleteDepartment {
    type: typeof requestDeleteDepartment;
    payload: { id: number };
}

interface ISuccessDeleteDepartment {
    type: typeof successDeleteDepartment;
    payload: { id: number };
}

interface IFailureDeleteDepartment {
    type: typeof failureDeleteDepartment;
    payload: { id: number; error: Error; };
}

interface IEditedCompanyDataUpdate {
    type: typeof editedCompanyDataUpdate;
    payload: {
        editedFields: Partial<ITTKCompany>;
    };
}

export type TCompanyAction
    = IRequestFetchCurrentCompany
    | ISuccessFetchCurrentCompany
    | IFailureFetchCurrentCompany
    | IMarkCurrentCompanyForEditing
    | IRequestSaveEditedCurrentCompany
    | ISuccessSaveEditedCurrentCompany
    | IFailureSaveEditedCurrentCompany
    | IRequestDeleteDepartment
    | ISuccessDeleteDepartment
    | IFailureDeleteDepartment
    | IEditedCompanyDataUpdate;

export const createRequestFetchCurrentCompanyAction = (): IRequestFetchCurrentCompany => ({
    type: requestFetchCurrentCompany,
});

export const createSuccessFetchCompanyAction = (company: ITTKCompany): ISuccessFetchCurrentCompany => ({
    type: successFetchCurrentCompany,
    payload: { company },
});

export const createFailureFetchCompanyAction = (id: number, error: Error): IFailureFetchCurrentCompany => ({
    type: failureFetchCurrentCompany,
    payload: { id, error },
});

export const createMarkCurrentCompanyForEditingAction = (): IMarkCurrentCompanyForEditing => ({
    type: markCurrentCompanyForEditing,
});

export const createRequestSaveEditedCurrentCompany = (): IRequestSaveEditedCurrentCompany => ({
    type: requestSaveEditedCurrentCompany,
});

export const createSuccessSaveEditedCurrentCompany = (company: ITTKCompany): ISuccessSaveEditedCurrentCompany => ({
    type: successSaveEditedCurrentCompany,
    payload: { company },
});

export const createFailureSaveEditedCurrentCompany = (error: Error): IFailureSaveEditedCurrentCompany => ({
    type: failureSaveEditedCurrentCompany,
    payload: { error },
});

export const createRequestDeleteDepartmentAction = (id: number): IRequestDeleteDepartment => ({
    type: requestDeleteDepartment,
    payload: { id },
});

export const createSuccessDeleteDepartmentAction = (id: number): ISuccessDeleteDepartment => ({
    type: successDeleteDepartment,
    payload: { id },
});

export const createFailureDeleteDepartmentaction = (id: number, error: Error): IFailureDeleteDepartment => ({
    type: failureDeleteDepartment,
    payload: { id, error },
});

export const createEditedCompanyDataUpdateAction = (editedFields: Partial<ITTKCompany>): IEditedCompanyDataUpdate => ({
    type: editedCompanyDataUpdate,
    payload: { editedFields },
});
