export interface ISuccessResult<T> {
    isSuccess: true;
    isFailure: false;
    value: T;
}

export interface IFailureResult {
    isSuccess: false;
    isFailure: true;
    error: Error;
}

export type TResult<T> = ISuccessResult<T> | IFailureResult;

export function makeSuccess<T>(value: T): ISuccessResult<T> {
    return {
        isSuccess: true,
        isFailure: false,
        value,
    };
}

export function makeFailure(error: Error): IFailureResult {
    return {
        isSuccess: false,
        isFailure: true,
        error,
    };
}

export function combineResults(results: Array<TResult<any>>): TResult<any> {
    const foundError = results.find((res) => res.isFailure);

    return foundError || makeSuccess(true);
}
