import { takeEvery, call, put } from "redux-saga/effects";
import { ITTKCompanyService } from "../../services/TTKCompanyService";
import {
    requestDeleteDepartment,
    IRequestDeleteDepartment,
    createFailureDeleteDepartmentaction,
    createSuccessDeleteDepartmentAction,
} from "../actions/companyActions";
import { TResult } from "../../../sg-core/utils/Result";

const makeDeleteDepartmentSaga = (companyService: ITTKCompanyService) =>
    function* saga(action: IRequestDeleteDepartment) {
        const { id } = action.payload;
        try {
            const result: TResult<any> = yield call(companyService.deleteDepartment, id);

            if (result.isFailure) {
                yield put(createFailureDeleteDepartmentaction(id, result.error));
            } else {
                yield put(createSuccessDeleteDepartmentAction(id));
            }
        } catch (error) {
            yield put(createFailureDeleteDepartmentaction(id, error));
        }
    };

export default function(companyService: ITTKCompanyService) {
    return takeEvery(requestDeleteDepartment, makeDeleteDepartmentSaga(companyService));
}
