import IIndustry from "../../interfaces/IIndustry";
import { TLoadable, createUnloaded, createLoading, createLoaded, createLoadErrored } from "../../../sg-core/models/ILoadable";
import { TIndustryAction } from "../actions/industryActions";

export interface IIndustryState {
    list: TLoadable<IIndustry[]>;
}

export const defaultIndustryState: IIndustryState = {
    list: createUnloaded(),
};

export const industryReducer = (state = defaultIndustryState, action: TIndustryAction) => {
    switch (action.type) {
        case "REQUEST_FETCH_INDUSTRY_LIST": {
            return {
                ...state,
                list: createLoading(),
            };
        }
        case "SUCCESS_FETCH_INDUSTRY_LIST": {
            return {
                ...state,
                list: createLoaded(action.payload.list),
            };
        }
        case "FAILURE_FETCH_INDUSTRY_LIST": {
            return {
                ...state,
                list: createLoadErrored(action.error),
            };
        }
    }
    return state;
};
