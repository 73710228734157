import { IProceedingDataInList, IProceedingsData, EProceedingStatus } from "../../interfaces/IProceedingData";
import { TLoadable, createUnloaded, createLoading, createLoaded, createLoadErrored, isLoaded} from "../../../sg-core/models/ILoadable";
import { TProceedingAction } from "../actions/proceedingActions";
import { TSubmittable, createUnsubmitted, createSubmitting, createSubmitted, createSubmitErrored } from "../../../sg-core/models/ISubmittable";

interface IProceedingFilters {
    status: EProceedingStatus | "all";
}

export interface IProceedingState {
    list: TLoadable<IProceedingDataInList[]>;
    filters: IProceedingFilters;
    byId: { [key: number]: TLoadable<IProceedingsData> };
    edited: { [key: number]: TSubmittable<IProceedingsData> };
}

export const defaultProceedingState: IProceedingState = {
    filters: { status: "all" },
    list: createUnloaded<IProceedingDataInList[]>(),
    byId: {},
    edited: {},
};

export const proceedingReducer =
(state: IProceedingState = defaultProceedingState, action: TProceedingAction): IProceedingState => {
    switch (action.type) {
        case "REQUEST_FETCH_PROCEEDINGS": {
            return {
                ...state,
                list: createLoading(),
            };
        }
        case "SUCCESS_FETCH_PROCEEDINGS": {
            return {
                ...state,
                list: createLoaded(action.payload.list),
            };
        }
        case "FAILURE_FETCH_PROCEEDINGS": {
            return {
                ...state,
                list: createLoadErrored(action.error),
            };
        }
        case "REQUEST_FETCH_PROCEEDING": {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: createLoading(),
                },
            };
        }
        case "SUCCESS_FETCH_PROCEEDING": {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.proceeding.id]: createLoaded(action.payload.proceeding),
                },
            };
        }
        case "FAILURE_FETCH_PROCEEDING": {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: createLoadErrored(action.payload.error),
                },
            };
        }
        case "MARK_PROCEEDING_FOR_EDITING": {
            const forEditing = state.byId[action.payload.id];
            if (forEditing && isLoaded(forEditing)) {
                return {
                    ...state,
                    edited: {
                        ...state.edited,
                        [action.payload.id]: createUnsubmitted(forEditing.data),
                    },
                };
            }
            return state;
        }
        case "UPDATE_EDITED_PROCEEDING": {
            const inEditing = state.edited[action.payload.id];
            if (inEditing) {
                return {
                    ...state,
                    edited: {
                        ...state.edited,
                        [action.payload.id]: createUnsubmitted({
                            ...inEditing.data,
                            ...action.payload.editedFields,
                        }),
                    },
                };
            }
            return state;
        }
        case "REQUEST_SAVE_PROCEEDINGS": {
            return {
                ...state,
                edited: {
                    ...state.edited,
                    [action.payload.id]: createSubmitting(state.edited[action.payload.id].data)
                },
            };
        }
        case "SUCCESS_SAVE_PROCEEDING": {
            const id = action.payload.proceeding.id;

            const mapProceedingInList = (proceeding: IProceedingDataInList) => {
                if (proceeding.id === id) {
                    const { reportRowId, reportRowType } = proceeding;

                    return {
                        ...proceeding,
                        ...action.payload.proceeding,
                        reportRowId,
                        reportRowType,
                    };
                }
                return proceeding;
            };

            const list = isLoaded(state.list)
                ? createLoaded(state.list.data.map(mapProceedingInList))
                : state.list;

            const byId = isLoaded(state.byId[id])
                ? { ...state.byId, [id]: createLoaded(action.payload.proceeding) }
                : state.byId;

            return {
                ...state,
                list,
                byId,
                edited: {
                    ...state.edited,
                    [id]: createSubmitted(
                        state.edited[id].data,
                        action.payload.proceeding,
                    ),
                },
            };
        }
        case "FAILURE_SAVE_PROCEEDING": {
            return {
                ...state,
                edited: {
                    ...state.edited,
                    [action.payload.id]: createSubmitErrored(
                        state.edited[action.payload.id].data,
                        action.payload.error,
                    ),
                },
            };
        }
        case "UPDATE_PROCEEDING_FILTERS": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    status: action.payload.status,
                },
            };
        }
    }
    return state;
};
