import React, { useCallback } from "react";
import { Header, Segment, Grid, Form, Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { createRequestLoginAction } from "../auth/store/authActions";
import { useLoginStatus } from "../auth/store/authSelectors";
import { isLoading, isLoadErrored, isLoaded } from "../../sg-core/models/ILoadable";
import { Redirect } from "react-router-dom";
import { getRouteHrefById } from "../../routing";
import { AuthenticationType } from "../auth/store/authReducer";
import RouteLink from "../../sg-core/components/RouteLink";
import { useInput } from "../../sg-hooks/useInput";
import { useTranslation } from "react-i18next";

const LoginPage: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const loginStatus = useLoginStatus();

    const [username, handleUsername] = useInput();
    const [password, handlePassword] = useInput()

    const onLoginRequest = useCallback(() => {
        dispatch(createRequestLoginAction(username, password));
    }, [dispatch, username, password]);

    const loggingIn = isLoading(loginStatus);
    const loginError = isLoadErrored(loginStatus);

    if (isLoaded(loginStatus)) {
        if (loginStatus.data > AuthenticationType.AnonymousWithCompanyId) {
            return <Redirect to={getRouteHrefById("FrontPage")!} />;
        }
        return <Redirect to={getRouteHrefById("AccidentReportForm")!} />;
    }

    return (
        <>
        <Header as="h2" icon textAlign="center">
            <Header.Content>{t("turvaArvi")}</Header.Content>
        </Header>
        <Segment basic>
            <p>{t("turvaArviDescription")}</p>
            <p>
                <small>
                    <em>
                        {t("turvaArviDescriptionNote")}
                        <a href="https://www.tyosuojelu.fi/tyoterveys-ja-tapaturmat/tyotapaturmat">tyosuojelu.fi</a>
                    </em>
                </small>
            </p>
            <Grid stackable columns={2} className="form-grid">
                <Grid.Column>
                    <Header as="h2">Kirjautuminen</Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                    <p>
                        <span className="margin-right">Ei tunnuksia?</span>
                        <RouteLink id="Register">
                            <Button primary size="small">Rekisteröidy tästä</Button>
                        </RouteLink>
                    </p>
                </Grid.Column>
            </Grid>
            <div className="ui divider"></div>
            <Form onSubmit={onLoginRequest}>
                <Grid stackable className="form-grid">
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <label>Käyttäjätunnus</label>
                        </Grid.Column>
                        <Grid.Column  width={10}>
                            <Form.Input
                                id="username"
                                fluid
                                required
                                disabled={loggingIn}
                                onChange={handleUsername}
                                value={username}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={6}>
                            <label>Salasana</label>
                        </Grid.Column>
                        <Grid.Column  width={10}>
                            <Form.Input
                                id="password"
                                type="password"
                                fluid
                                required
                                disabled={loggingIn}
                                onChange={handlePassword}
                                value={password}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                { loginError && (
                    <Segment color="red">
                        <Header as="h4">Kirjautuminen epäonnistui</Header>
                        <p>Väärä käyttäjätunnus/salasana</p>
                    </Segment>
                )}

                <Grid columns={1} textAlign="right">
                    <Grid.Column>
                        <Button primary type="submit" loading={loggingIn}>Kirjaudu</Button>
                        <p>
                            <small>
                                Unohtuiko salasana?
                                <RouteLink
                                    id="ForgottenPassword"
                                    params={{emailHint: username}}
                                >Klikkaa tästä</RouteLink>
                            </small>
                        </p>
                    </Grid.Column>
                </Grid>
            </Form>
        </Segment>
        </>
    );
};

export default LoginPage;
