import { call, put, takeLeading } from "redux-saga/effects";

import { IAccidentReportService } from "../../services/AccidentReportService";
import {
    IFetchAccidentReport,
    createFetchAccReportFailureAction,
    createFetchAccReportSuccessAction,
    fetchAccidentReport,
} from "../actions";
import { TResult } from "../../../sg-core/utils/Result";
import { IAccidentReport } from "../../interfaces/IAccidentReportData";

const makeFetchAccidentReportSaga = (accidentReportService: IAccidentReportService) =>
    function* fetchAccidentReportSaga(action: IFetchAccidentReport) {
        const { payload: { id } } = action;
        try {
            const result: TResult<IAccidentReport> = yield call(accidentReportService.getAccidentReportById, id);

            if (result.isSuccess) {
                yield put(createFetchAccReportSuccessAction(result.value));
            } else {
                console.error(result.error);
                if (result.isFailure) {
                    yield put(createFetchAccReportFailureAction(id, result.error));
                } else {
                    yield put(createFetchAccReportFailureAction(id, new Error("Invalid result value")));
                }
            }
        } catch (error) {
            console.error(error);
            if (error instanceof Error) {
                yield put(createFetchAccReportFailureAction(id, error));
            } else {
                yield put(createFetchAccReportFailureAction(id, new Error(error)));
            }
        }
    };

export default function create(accidentReportService: IAccidentReportService) {
    return takeLeading(
        fetchAccidentReport,
        makeFetchAccidentReportSaga(accidentReportService),
    );
}
