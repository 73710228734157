import fetch from "unfetch";
import { IFile } from "../models/IFile";
import { TResult, makeSuccess, makeFailure } from "../../sg-core/utils/Result";
import { IFileUpload5Response } from "../models/UploadResponse";

export interface IFileUploadService {
    getFileData(fileId: string): Promise<string>;
    getFileToken(): Promise<TResult<string>>;
    getToken(): string | null;
    uploadFileWithUpload5(file: File): Promise<TResult<IFile>>;
    removeFileWithId(fileId: string): Promise<TResult<void>>;
    setToken(token: string | null): void;
    getOpts(): IFileUploadServiceOptions;
}

export interface IFileUploadServiceOptions {
    url: string;
}

export const createFileUploadService = (opts: IFileUploadServiceOptions): IFileUploadService => {

    let TOKEN: string | null = null;
    let FILETOKEN: string | null = null;

    const getFileData = async (fileId: string) => {
        const payload = {
            action: "hae_tiedosto",
            tiedosto: { guid: fileId },
        };

        const headers: any = {};

        if (TOKEN) {
            headers.Authorization = `NAS SID ${TOKEN}`;
        }

        const response = await fetch(opts.url, {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            console.error(response);
        }

        const fileData = URL.createObjectURL(await response.blob());

        return fileData;
    };

    const getFileToken = async (): Promise<TResult<string>> => {
        const headers: any = {
            "Content-Type": "application/json",
        };

        if (TOKEN) {
            headers.Authorization = `NAS SID ${TOKEN}`;
        }

        const payload = { action: "hae_varmenne" };

        try {
            const response = await fetch(opts.url, {
                method: "POST",
                headers,
                body: JSON.stringify(payload),
            });
            const json = await response.json();

            const token = json.varmenne;

            if (token) {
                FILETOKEN = token;
                return makeSuccess(token);
            }
            return makeFailure(new Error("errNoFileTokenReceived"));
        } catch (error) {
            return makeFailure(error);
        }
    };

    const getToken = () => FILETOKEN;

    const uploadFileWithUpload5 = async (file: File): Promise<TResult<IFile>> => {
        const formData = new FormData();
        formData.append("file", file);

        const headers: any = {};

        if (TOKEN) {
            headers.Authorization = `NAS SID ${TOKEN}`;
        }

        try {
            const response = await fetch(opts.url + "?action=lataa_tiedosto", {
                method: "POST",
                headers,
                body: formData,
            });

            const json: IFileUpload5Response = await response.json();

            // We only upload one file, so response should only have one file
            if (json.tiedosto && json.tiedosto[0]) {
                return makeSuccess<IFile>({ id: json.tiedosto[0].guid });
            } else {
                return makeFailure(new Error("errNoFileInServerResponse"));
            }
        } catch (error) {
            return makeFailure(error);
        }
    };

    const removeFileWithId = async (fileId: string): Promise<TResult<void>> => {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        return makeSuccess(undefined);
    };

    const setToken = (token: string | null) => {
        TOKEN = token;
    };

    const getOpts = (): IFileUploadServiceOptions => opts;

    return {
        getFileData,
        getFileToken,
        uploadFileWithUpload5,
        removeFileWithId,
        setToken,
        getToken,
        getOpts,
    };
};
