import { takeLeading, call } from "redux-saga/effects";
import { ITTKAuthService } from "../services/ITTKAuthService";
import { logout } from "./authActions";

const createLogoutSaga = (ttkAuth: ITTKAuthService) =>
    function* saga() {
        yield call(ttkAuth.logout);
    };

export default function(ttkAuth: ITTKAuthService) {
    return takeLeading(
        logout,
        createLogoutSaga(ttkAuth),
    );
}
