import React from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { useLoginStatus } from "../store/authSelectors";
import { isLoaded, isLoading } from "../../../sg-core/models/ILoadable";
import { getRouteHrefById } from "../../../routing";
import { Segment } from "semantic-ui-react";
import { AuthenticationType } from "../store/authReducer";

interface IAuthenticatedRouteProps {
    requiredAuthenticationLevel?: AuthenticationType;
}

const AuthenticatedRoute: React.FC<RouteProps & IAuthenticatedRouteProps> = (props) => {
    const {
        requiredAuthenticationLevel = AuthenticationType.TTKUser,
        ...restProps
    } = props;
    const { render } = props;
    const loginStatus = useLoginStatus();

    const isLoggedIn = isLoaded(loginStatus);
    const loginType: number = isLoaded(loginStatus) ? loginStatus.data : -1;
    const isLoggingIn = isLoading(loginStatus);

    const enoughPrivileges = loginType >= requiredAuthenticationLevel;

    return (
        <Route
            {...restProps}
            render={(renderProps) => {
                return (isLoggedIn && enoughPrivileges)
                    ? render!(renderProps)
                    : isLoggingIn
                        ? <Segment padded="very" loading />
                        : <Redirect to={getRouteHrefById("Login")!} />;
            }}
        />
    );
};

export default AuthenticatedRoute;
