import { 
    IReportListReportItem, 
    IReportResponseItem, 
    IReportPostData, 
    IResponseReportRowItem, 
    IInquiryPostData, 
    IGetInquiryResponse,
    IReportAnonymousPostData
} from "../services/AccidentReportService";
import IAccidentReportFormData, {
    IAccidentReportInList,
    isAccidentReportStatus,
    TAccidentReportStatus,
    IAccidentReport,
    IAccidentReportJSON,
    IAccidentReportYesNoMap,
    IAccidentReportRowJSON,
    IAccidentReportYesNo,
    IAccidentReportInquiry
} from "../interfaces/IAccidentReportData";
import { gateNonstandardDateToDate } from "../../sg-core/utils/GateSupport";
import { TResult, makeFailure, makeSuccess } from "../../sg-core/utils/Result";
import { IFile } from "../../sg-fileupload/models/IFile";

const StatusMap: { [key: string]: TAccidentReportStatus } = {
    uusi: "new",
    kasittelyssa: "inprogress",
    kasitelty: "awaitingActions",
    valmis: "finished",
};
const StatusMapReverse: { [key: string]: keyof typeof StatusMap } = {
    new: "uusi",
    inprogress: "kasittelyssa",
    awaitingActions: "kasitelty",
    finished: "valmis",
};

const AccidentReportMapper = {
    apiToDomain(api: IReportResponseItem, rows: IResponseReportRowItem[] = []): TResult<IAccidentReport> {

        const jsonData: IAccidentReportJSON = api.base64data ? JSON.parse(atob(api.base64data)) : {};

        const status = StatusMap[api.tila] || "new";

        if (!isAccidentReportStatus(status)) {
            console.error("Invalid status", status);
            return makeFailure(new Error("errInvalidDataFromServer"));
        }
        const filteredRows = rows ? rows.filter(r => r.ilmoitusrivityyppi) : rows;
        const yesNoRows = (filteredRows || []).reduce<IAccidentReportYesNoMap>((acc, row) => {
            const data: IAccidentReportRowJSON = row.base64data
                                                    ? JSON.parse(atob(row.base64data))
                                                    : {};

            const rowValue: IAccidentReportYesNo = {
                id: row.id,
                description: data.info || "",
                images: (data.images || []).map((id) => ({ id })),
                value: row.tila === "korjattavaa",
            };
            return {
                ...acc,
                [row.ilmoitusrivityyppi]: rowValue,
            };
        }, {});

        const {
            valaistus,
            cleaniness,
            slippery,
            noise,
            other,
            ...otherFactors
        } = yesNoRows;

        const environmentalFactors = { valaistus, cleaniness, slippery, noise, other };

        const report: IAccidentReport = {
            id: api.id,
            description: {
                value: api.kuvaus,
                images: (jsonData.descriptionImages || []).map((id): IFile => ({ id })),
            },
            department: api.osasto ? parseInt(api.osasto, 10) : null,
            environmentalFactors,
            eventDate: gateNonstandardDateToDate(api.tapahtuma_aika) || new Date("invalid"),
            lastModified: gateNonstandardDateToDate(api.viimeksi_kasitelty) || new Date("invalid"),
            otherFactors,
            location: {
                value: jsonData.location || "",
                images: (jsonData.locationImages || []).map((id): IFile => ({ id })),
            },
            reportType: api.ilmoitustyyppi,
            status,
            mayBeInContact: !!jsonData.mayBeInContact,
            whatWasOkay: jsonData.whatWasOkay || "",
            reporterContact: {
                email: jsonData.contactEmail,
            },
            inspectionRequired: api.tutkinta === 1 ? true : false,
            guid: api.guid
        };

        return makeSuccess(report);
    },
    listApiToDomain(api: IReportListReportItem): TResult<IAccidentReportInList> {

        const eventTime = gateNonstandardDateToDate(api.tapahtuma_aika);
        if (eventTime === null) {
            console.error("Invalid event time", api.tapahtuma_aika);
            return makeFailure(new Error("errInvalidDataFromServer"));
        }

        const lastModified = gateNonstandardDateToDate(api.viimeksi_kasitelty);
        if (api.viimeksi_kasitelty && lastModified === null) {
            console.error("Invalid last modified time", api.viimeksi_kasitelty);
            return makeFailure(new Error("errInvalidDataFromServer"));
        }

        const status = StatusMap[api.tila];

        if (!isAccidentReportStatus(status)) {
            console.error("Invalid status", api.tila);
            return makeFailure(new Error("errInvalidDataFromServer"));
        }

        return makeSuccess<IAccidentReportInList>({
            id: api.id,
            departmentId: api.osasto ? parseInt(api.osasto, 10) : null,
            eventTime,
            lastModified,
            status,
            title: api.kuvaus,
            reportType: api.ilmoitustyyppi,
            inspectionRequired: api.tutkinta === 1 ? true : false
        });
    },

    domainToList(domain: IAccidentReport): TResult<IAccidentReportInList> {
        const inList: IAccidentReportInList = {
            id: domain.id,
            departmentId: domain.department,
            eventTime: domain.eventDate || new Date(),
            lastModified: domain.lastModified || null,
            status: domain.status,
            title: domain.description.value,
            reportType: domain.reportType,
            inspectionRequired: domain.inspectionRequired
        };

        return makeSuccess(inList);
    },

    listToDomain(list: IAccidentReportInList): IAccidentReport {
        return {
            id: list.id,
            department: list.departmentId,
            description: { images: [], value: list.title },
            environmentalFactors: {},
            location: { images: [], value: "" },
            mayBeInContact: false,
            otherFactors: {},
            reporterContact: {},
            whatWasOkay: "",
            status: list.status,
            reportType: list.reportType,
            eventDate: list.eventTime,
            lastModified: list.lastModified || undefined,
            inspectionRequired: list.inspectionRequired
        };
    },

    domainToApi(
        companyId: number | string,
        report: IAccidentReport | IAccidentReportFormData): TResult<IReportPostData> {

        let reportRows: IResponseReportRowItem[] = [];

        const getRowsFrom = (obj?: IAccidentReportYesNoMap): IResponseReportRowItem[] => {
            if (!obj) {
                return [];
            }

            const keys = Object.keys(obj).filter((k) => obj[k]);

            return keys.map((key): IResponseReportRowItem => {
                const row = obj[key];

                if (row.value) {
                    const rowJsonData: IAccidentReportRowJSON = {
                        info: row.description,
                        images: row.images.map((img) => img.id),
                    };
                    return {
                        ilmoitus: report.id,
                        ilmoitusrivityyppi: key,
                        tila: "korjattavaa",
                        id: row.id,
                        base64data: btoa(JSON.stringify(rowJsonData, null, 2)),
                    };
                }

                return {
                    ilmoitusrivityyppi: key,
                    ilmoitus: report.id,
                    tila: "kunnossa",
                    base64data: btoa("{}"),
                    id: row.id,
                };
            });
        };

        reportRows = reportRows.concat(getRowsFrom(report.environmentalFactors));
        reportRows = reportRows.concat(getRowsFrom(report.otherFactors));

        const idFromImage = (img: IFile) => img.id;

        const jsonData: IAccidentReportJSON = {
            location: report.location.value,
            descriptionImages: report.description.images.map(idFromImage),
            locationImages: report.location.images.map(idFromImage),
            mayBeInContact: !!report.mayBeInContact,
            whatWasOkay: report.whatWasOkay,
            contactEmail: report.reporterContact.email
        };

        const base64data = btoa(JSON.stringify(jsonData, null, 2));

        const apiData: IReportPostData = {
            ilmoitus: {
                id: report.id,
                base64data,
                ilmoitustyyppi: report.reportType,
                kuvaus: report.description.value,
                tapahtuma_aika: report.eventDate.toISOString(),
                tila: StatusMapReverse[report.status] as any,
                yritys: companyId,
                osasto: report.department,
                tutkinta: report.inspectionRequired || report.reportType === 1 ? 1 : 0
            },
            ilmoitusrivit: reportRows,
        };

        return makeSuccess(apiData);
    },
    anonDomainToApi(
        companyId: number | string,
        report: IAccidentReport | IAccidentReportFormData): TResult<IReportAnonymousPostData> {

        const idFromImage = (img: IFile) => img.id;
        const jsonData: IAccidentReportJSON = {
            location: report.location.value,
            descriptionImages: report.description.images.map(idFromImage),
            locationImages: report.location.images.map(idFromImage),
            mayBeInContact: !!report.mayBeInContact,
            whatWasOkay: report.whatWasOkay,
            contactEmail: report.reporterContact.email
        };

        const base64data = btoa(JSON.stringify(jsonData, null, 2));

        const apiData: IReportAnonymousPostData = {
            ilmoitus: {
                id: report.id,
                base64data,
                ilmoitustyyppi: report.reportType,
                kuvaus: report.description.value,
                tapahtuma_aika: report.eventDate.toISOString(),
                tila: StatusMapReverse[report.status] as any,
                yritys: companyId,
                osasto: report.department,
                tutkinta: report.inspectionRequired || report.reportType === 1 ? 1 : 0
            },
            guid: report.guid,
        };

        return makeSuccess(apiData);
    },    

    inquiryApiToDomain(data: IGetInquiryResponse): TResult<IAccidentReportInquiry> {
        const report: IAccidentReportInquiry = {
            report: { id: data.id},
            message: data.viesti,
        };

        return makeSuccess(report);
    },
    inquiryDomainToApi(reportId: number, message: string): TResult<IInquiryPostData> {
        const apiData: IInquiryPostData = {
            ilmoitus: {
                id: reportId
            },
            viesti: message,
        };

        return makeSuccess(apiData);
    }    
};

export default AccidentReportMapper;
