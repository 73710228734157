import { takeLeading, put, call, select } from "redux-saga/effects";
import { requestFetchUsers, createSuccessFetchUsersAction, createFailureFetchUsersAction } from "../actions/userActions";
import { ITTKUserService } from "../../services/TTKUserService";
import { TResult } from "../../../sg-core/utils/Result";
import ITTKUser from "../../interfaces/ITTKUser";
import { selectLoggedInUserCompanyId } from "../../auth/store/authSelectors";

const makeFetchUsersSaga = (userService: ITTKUserService) =>
    function* saga() {
        try {
            const companyId = yield select(selectLoggedInUserCompanyId);
            const result: TResult<ITTKUser[]> = yield call(userService.getUsers, companyId);

            if (result.isSuccess) {
                yield put(createSuccessFetchUsersAction(result.value));
            } else {
                yield put(createFailureFetchUsersAction(result.isFailure ? result.error : new Error()));
            }
        } catch (e) {
            yield put(createFailureFetchUsersAction(e));
        }
    };

export default function create(userService: ITTKUserService) {
    return takeLeading(
        requestFetchUsers,
        makeFetchUsersSaga(userService),
    );
}
