import ITTKCompany, { ITTKCompanyDepartment } from "../../interfaces/ITTKCompany";
import { TLoadable, createUnloaded, createLoading, createLoaded, createLoadErrored, isLoaded, ILoadableLoaded } from "../../../sg-core/models/ILoadable";
import { TCompanyAction } from "../actions/companyActions";
import { TSubmittable, createUnsubmitted, createSubmitting, createSubmitted, createSubmitErrored } from "../../../sg-core/models/ISubmittable";
import { ILogoutAction } from "../../auth/store/authActions";

export interface ITTKCompanyInStore {
    id: number;
    guid: string;
    name: string;
    sector: number;
    departments: number[];
}

export interface ICompanyState {
    company: TLoadable<ITTKCompanyInStore>;
    editedCompany?: TSubmittable<ITTKCompany>;
    departments: {
        [key: number]: ITTKCompanyDepartment;
    };
    deletingDepartments: {
        [key: number]: TSubmittable<any>;
    };
}

export const defaultCompanyState: ICompanyState = {
    company: createUnloaded(),
    departments: {},
    deletingDepartments: {},
};

function companyReducer(state: ICompanyState = defaultCompanyState, action: TCompanyAction | ILogoutAction): ICompanyState {
    switch (action.type) {
        case "LOGOUT": {
            return defaultCompanyState;
        }
        case "REQUEST_FETCH_CURRENT_COMPANY": {
            return {
                ...state,
                company: createLoading(),
            };
        }
        case "SUCCESS_FETCH_CURRENT_COMPANY": {
            const { company } = action.payload;
            return {
                ...state,
                company: createLoaded<ITTKCompanyInStore>({
                    id: company.id,
                    name: company.name,
                    sector: company.sector,
                    departments: company.departments.map((d) => d.id),
                    guid: company.guid,
                }),
                departments: company.departments.reduce<ICompanyState["departments"]>((acc, department) => {
                    acc[department.id] = department;
                    return acc;
                }, {}),
            };
        }
        case "FAILURE_FETCH_CURRENT_COMPANY": {
            return {
                ...state,
                company: createLoadErrored(action.payload.error),
            };
        }
        case "MARK_CURRENT_COMPANY_FOR_EDIT": {
            if (isLoaded(state.company)) {
                const { departments, ...rest } = state.company.data;
                const edited: ITTKCompany = {
                    ...rest,
                    departments: departments.map((id) => state.departments[id]),
                };
                return {
                    ...state,
                    editedCompany: createUnsubmitted(edited),
                };
            }
            return state;
        }
        case "EDITED_COMPANY_DATA_UPDATE": {
            if (isLoaded(state.company)) {
                const newData = {
                    ...state.editedCompany!.data,
                    ...action.payload.editedFields,
                };
                return {
                    ...state,
                    editedCompany: createUnsubmitted(newData),
                };
            }
            return state;
        }
        case "REQUEST_SAVE_EDITED_CURRENT_COMPANY": {
            return {
                ...state,
                editedCompany: createSubmitting(state.editedCompany!.data),
            };
        }
        case "REQUEST_DELETE_DEPARTMENT": {
            return {
                ...state,
                deletingDepartments: {
                    ...state.deletingDepartments,
                    [action.payload.id]: createSubmitting(1),
                },
            };
        }
        case "SUCCESS_DELETE_DEPARTMENT": {
            return {
                ...state,
                deletingDepartments: {
                    ...state.deletingDepartments,
                    [action.payload.id]: createSubmitted(1),
                },
                company: createLoaded({
                    ...(state.company as ILoadableLoaded<ITTKCompanyInStore>).data,
                    departments: (state.company as ILoadableLoaded<ITTKCompanyInStore>).data.departments.filter((dep) => {
                        return dep !== action.payload.id;
                    }),
                }),
            };
        }
        case "FAILURE_DELETE_DEPARTMENT": {
            return {
                ...state,
                deletingDepartments: {
                    ...state.deletingDepartments,
                    [action.payload.id]: createSubmitErrored(1, action.payload.error),
                },
            };
        }
        case "SUCCESS_SAVE_EDITED_CURRENT_COMPANY": {
            return {
                ...state,
                editedCompany: createSubmitted(action.payload.company),
                company: createLoaded({
                    ...(state.company as ILoadableLoaded<ITTKCompanyInStore>).data,
                    departments: action.payload.company.departments.map((dep) => dep.id),
                }),
                departments: action.payload.company.departments.reduce<ICompanyState["departments"]>((acc, department) => {
                    acc[department.id] = department;
                    return acc;
                }, {}),
            };
        }
        case "FAILURE_SAVE_EDITED_CURRENT_COMPANY": {
            return {
                ...state,
                editedCompany: createSubmitErrored(state.editedCompany!.data, action.payload.error),
            };
        }
        default: {
            return state;
        }
    }
}

export default companyReducer;
