import { IRouteDefinition } from "../routing";

// Set up route ids for Turva-Arvi
export const TurvaArviRouteId = Object.freeze({
    FrontPage: "turvaarvi-front",
    AccidentReportForm: "turvaarvi-form-acc-report-form",
    AccidentReportEditing: "turvaarvi-form-acc-report-form",
    AccidentReportInfo: "turvaarvi-acc-report-info",
    AccidentReportProcessView: "turvaarvi-acc-report-process",
    AccidentReportInquiryView: "turvaarvi-acc-report-inquiry",
    ProceedingListView: "turvaarvi-proceedings-view",
    ProceedingInstanceView: "turvaarvi-proceeding-view",
    PriceedingInstanceReportView: "turvaarvi-proceeding-report-view",
    ReportListing: "turvaarvi-report-listing",
    Settings: "turvaarvi-settings",
    Login: "turvaarvi-login",
    Register: "turvaarvi-register",
    ForgottenPassword: "turvaarvi-forgottenpassword",
    InviteUser: "turvaarvi-inviteuser",

    // Print routes
    PrintReportList: "print-report-list",
    PrintReportInstance: "print-report-instance",
    PrintReportProcess: "print-report-process",
});

export const TurvaArviRoutes: IRouteDefinition[] = [];
