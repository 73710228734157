import { TResult, makeFailure, makeSuccess } from "../../sg-core/utils/Result";
import { IProceedingDataInList, IProceedingDataListResponse, IProceedingsData, IProceedingDataResponse } from "../interfaces/IProceedingData";
import { ISgApiService } from "../../sg-core/services/ApiService";
import ProceedingMapper from "../mappers/ProceedingMapper";

export interface IProceedingsService {
    getProceedingsList(companyId: number): Promise<TResult<IProceedingDataInList[]>>;
    getProceedingById(proceedingId: number): Promise<TResult<IProceedingsData>>;
    updateProceedingById(proceeding: IProceedingsData): Promise<TResult<IProceedingsData>>;
}

export const getProceedingService = (apiService: ISgApiService): IProceedingsService => {
    const getProceedingsList = async (companyId: number): Promise<TResult<IProceedingDataInList[]>> => {
        const response = await apiService.sendAction<IProceedingDataListResponse>("hae_toimenpidelista", {
            yritys: { id: companyId },
        });

        if (response.isFailure === true) {
            return makeFailure(response.error);
        }

        const mapped = response.value.toimenpiteet.map(ProceedingMapper.listApiToDomain);

        return makeSuccess(mapped);
    };

    const getProceedingById = async (proceedingId: number): Promise<TResult<IProceedingsData>> => {
        const action = "hae_toimenpide";
        const payload = {
            toimenpide: {
                id: proceedingId,
            },
        };

        const response = await apiService.sendAction<IProceedingDataResponse>(action, payload);

        if (response.isFailure === true) {
            return makeFailure(response.error);
        }

        return makeSuccess(ProceedingMapper.apiToDomain(response.value.toimenpide));
    };

    const updateProceedingById = async (proceeding: IProceedingsData): Promise<TResult<IProceedingsData>> => {
        const action = "tallenna_toimenpide";
        const dto = ProceedingMapper.domainToApi(proceeding);

        const payload = {
            toimenpide: dto,
        };

        const response = await apiService.sendAction<IProceedingDataResponse>(action, payload);

        if (response.isFailure === true) {
            return makeFailure(response.error);
        }

        return makeSuccess(ProceedingMapper.apiToDomain(response.value.toimenpide));
    };

    return {
        getProceedingsList,
        getProceedingById,
        updateProceedingById,
    };
};
