import { IFormData } from "./IForm";
import { IFile } from "../../sg-fileupload/models/IFile";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { IWithImages, IWithImagesForm } from "./IWithImages";

export default interface IAccidentReportFormData extends IFormData {
    description: IWithImagesForm<string>;
    department: number | null;
    eventDate: Date;
    location: IWithImagesForm<string>;
    mayBeInContact: boolean;
    whatWasOkay: string;
    reporterContact: {
        email?: string;
    };
    reportType: number | null;
    inspectionRequired: boolean;    
}

// tslint:disable-next-line: class-name
export interface IAccidentReportYesNo_Yes {
    id?: number;
    value: true;
    description: string;
    images: IFile[];
}

// tslint:disable-next-line: class-name
export interface IAccidentReportYesNo_No {
    id?: number;
    value: false;
}

export type IAccidentReportYesNo = IAccidentReportYesNo_Yes | IAccidentReportYesNo_No;

export interface IAccidentReportYesNoMap {
    [key: string]: IAccidentReportYesNo;
}

export type TAccidentReportStatus = "new" | "inprogress" | "awaitingActions" | "finished";

export const isAccidentReportStatus = (t: string): t is TAccidentReportStatus => {
    return ["new", "inprogress", "awaitingActions", "finished"].includes(t);
};

export const getAccidentReportStatusColor = (status: TAccidentReportStatus): SemanticCOLORS => {
    switch (status) {
        case "new":
            return "orange";
        case "inprogress":
            return "yellow";
        case "finished":
            return "green";
        case "awaitingActions":
            return "teal";
    }
};

export interface IAccidentReportInList {
    id: number;
    title: string;
    status: TAccidentReportStatus;
    reportType: number | null;
    eventTime: Date;
    lastModified: Date | null;
    departmentId: number | null;
    inspectionRequired: boolean;
}

export interface IAccidentReport {
    id: number;
    reportType: number | null;
    status: TAccidentReportStatus;
    eventDate: Date;
    lastModified?: Date;
    description: IWithImages<string>;
    whatWasOkay: string;
    department: number | null;
    location: IWithImages<string>;
    mayBeInContact: boolean;
    reporterContact: {
        email?: string;
    };
    otherFactors: IAccidentReportYesNoMap;
    environmentalFactors: IAccidentReportYesNoMap;
    inspectionRequired: boolean;
    guid?: string;
}

export interface IAccidentReportJSON {
    location?: string;
    locationImages?: string[];
    descriptionImages?: string[];
    mayBeInContact?: boolean;
    contactEmail?: string;
    whatWasOkay?: string;
}

export interface IAccidentReportRowJSON {
    info: string;
    images: string[];
}

export interface IAccidentReportInquiry {
    report: { id: number};
    message: string;
}
