import { TResult, makeSuccess, combineResults, ISuccessResult, makeFailure } from "../../sg-core/utils/Result";
import IAccidentReportFormData, { IAccidentReport, IAccidentReportInList, IAccidentReportInquiry } from "../interfaces/IAccidentReportData";
import { IReportTableSorting, IAccidentReportTableFilters } from "../interfaces/IAccidentReportListing";
import { ISgApiService } from "../../sg-core/services/ApiService";
import AccidentReportMapper from "../mappers/AccidentReportMapper";

/**
 * The purpose of the AccidentReportService is to handle the API calls for
 * fetching, creating, listing and updating Accident Reports in the TTK
 * application.
 *
 * Any parts of the application requiring this functionality should request an
 * instance of IAccidentReportService instead of the AccidentReportService
 * directly.
 */

// Get report list response type

export interface IReportListReportItem {
    id: number;
    kuvaus: string;
    osasto: string;
    ilmoitustyyppi: number;
    tapahtuma_aika: string; // datetime
    viimeksi_kasitelty: string; // datetime
    tila: string;
    tutkinta: number;
}
interface IReportListResponse {
    action: "hae_ilmoituslista";
    yritys: "";
    ilmoitukset: IReportListReportItem[];
    success: boolean;
}

// Get single report response type

export interface IReportResponseItem {
    id: number;
    kuvaus: string;
    ilmoitustyyppi: number;
    yritys: 1;
    osasto: string;
    guid: string;
    base64data: string;
    tapahtuma_aika: string;
    viimeksi_kasitelty: string;
    tila: string;
    tutkinta: number;
}
export interface IResponseReportRowItem {
    ilmoitusrivityyppi: string;
    base64data: string;
    tila: "korjattavaa" | "kunnossa";
    ilmoitus: number;
    id?: number;
}
interface IGetReportResponse {
    action: "hae_ilmoitus" | "hae_ilmoitus_anonyymi";
    ilmoitus: IReportResponseItem;
    ilmoitusrivit: IResponseReportRowItem[];
    success: boolean;
    error?: { message?: string}
}

export interface IGetInquiryResponse {
    action: "laheta_lisatietopyynto";
    viesti: string;
    id: number;
    success: boolean;
}

// Post accident report input type
export interface IReportPostData {
    ilmoitus: {
        id?: number;
        base64data: string;
        kuvaus: string;
        ilmoitustyyppi: number | null;
        yritys: number | string;
        tapahtuma_aika: string;
        tila: "uusi" | "kasittelyssa" | "valmis";
        osasto: number | null;
        tutkinta: number;
    };
    ilmoitusrivit: IResponseReportRowItem[];
    guid?: string;
}

export interface IReportAnonymousPostData {
    ilmoitus: {
        id?: number;
        base64data: string;
        kuvaus: string;
        ilmoitustyyppi: number | null;
        yritys: number | string;
        tapahtuma_aika: string;
        tila: "uusi" | "kasittelyssa" | "valmis";
        osasto: number | null;
        tutkinta: number;
    };
    guid: string;
}

export interface IInquiryPostData {
    ilmoitus: {
        id: number;
    };
    viesti: string;
}

// Search response types

type TResSingle = Promise<TResult<IAccidentReport>>;
type TResPaginated = TResult<{
    totalItems: number;
    data: IAccidentReportInList[];
}>;

/**
 * Definition of the accident report service interface
 */

export interface IAccidentReportService {
    createAccidentReport: (companyId: string | number, data: IAccidentReportFormData) => TResSingle;
    getAccidentReportById: (id: number, guid?: string) => TResSingle;
    getAccidentReportList: (companyId: number) => Promise<TResPaginated>;
    // tslint:disable-next-line: max-line-length
    getReportPage: (index: number, pagination: { pageSize: number }, sorting: IReportTableSorting, filtering: IAccidentReportTableFilters) => Promise<TResPaginated>;
    updateAccidentReport: (companyId: string | number, data: IAccidentReport) => TResSingle;
    createAccidentReportInquiry: (reportId: number, message: string) => Promise<TResult<IAccidentReportInquiry>>;
    updateAccidentReportAnonymous: (id: number, report: IAccidentReport) => Promise<TResult<IAccidentReport>>;
}

export default function getAccidentReportService(api: ISgApiService): IAccidentReportService {

    // tslint:disable-next-line: max-line-length
    const createAccidentReport = async (companyId: string | number, data: IAccidentReportFormData): Promise<TResult<IAccidentReport>> => {
        try {
            const isAnonymous = typeof companyId === "string";

            const payloadResult = AccidentReportMapper.domainToApi(companyId, data);

            if (payloadResult.isFailure) {
                return makeFailure(payloadResult.error);
            }

            const action = isAnonymous ? "lisaa_ilmoitus_anonyymi" : "tallenna_ilmoitus";

            const payload = payloadResult.value;
            payload.ilmoitus.tila = "uusi";

            const response = await api.sendAction<IGetReportResponse>(action, payloadResult.value, isAnonymous);

            if (response.isFailure) {
                return makeFailure(response.error);
            }

            if (!response.value.success) {
                return makeFailure(new Error("Palvelinvirhe"));
            }

            const mappedData = AccidentReportMapper.apiToDomain(response.value.ilmoitus, response.value.ilmoitusrivit);

            if (mappedData.isFailure) {
                return makeFailure(mappedData.error);
            }

            return makeSuccess(mappedData.value);
        } catch (error) {
            return makeFailure(error as Error);
        }
    };

    const createAccidentReportInquiry = async (reportId: number, message: string): Promise<TResult<IAccidentReportInquiry>> => {
        try {

            const payloadResult = AccidentReportMapper.inquiryDomainToApi(reportId, message);

            if (payloadResult.isFailure) {
                return makeFailure(payloadResult.error);
            }

            const action = "laheta_lisatietopyynto";

            const response = await api.sendAction<IGetInquiryResponse>(action, payloadResult.value);

            if (response.isFailure) {
                return makeFailure(response.error);
            }

            if (!response.value.success) {
                return makeFailure(new Error("Palvelinvirhe"));
            }

            const mappedData = AccidentReportMapper.inquiryApiToDomain(response.value);

            if (mappedData.isFailure) {
                return makeFailure(mappedData.error);
            }

            return makeSuccess(mappedData.value);
        } catch (error) {
            return makeFailure(error as Error);
        }
    };    

    const getAccidentReportById = async (id: number, guid?: string): Promise<TResult<IAccidentReport>> => {
        try {
            const response = await api.sendAction<IGetReportResponse>(
                !guid ? "hae_ilmoitus" : "hae_ilmoitus_anonyymi", 
                !guid ? {
                    ilmoitus: { id },
                } :
                {
                    ilmoitus: { id },
                    guid: guid
                },
                !guid ? false : true
            );

            if (response.isFailure) {
                return makeFailure(response.error);
            }

            if (!response.value.success) {
                if (response.value.error) {
                    return makeFailure(new Error(response.value.error.message));
                }
                return makeFailure(new Error("Palvelinvirhe"));
            }

            const accidentReport = AccidentReportMapper.apiToDomain(response.value.ilmoitus, response.value.ilmoitusrivit);

            return accidentReport.isSuccess
                    ? makeSuccess(accidentReport.value)
                    : makeFailure(accidentReport.error);
        } catch (e) {
            return makeFailure(e as Error);
        }
    };

    const getAccidentReportList = async (companyId: number): Promise<TResPaginated> => {
        try {
            const response = await api.sendAction<IReportListResponse>(
                "hae_ilmoituslista", {
                    yritys: { id: companyId },
                },
            );

            if (response.isSuccess) {
                const mappedResults = (response.value.ilmoitukset || []).map(AccidentReportMapper.listApiToDomain);
                const combinedResult = combineResults(mappedResults);

                if (combinedResult.isSuccess) {
                    const totalItems = mappedResults.length;
                    const items = mappedResults.map((res) => (res as ISuccessResult<IAccidentReportInList>).value);
                    return makeSuccess({
                        totalItems,
                        data: items,
                    });
                }

                return makeFailure(combinedResult.error);
            }
            return makeFailure(response.error);
        } catch (error) {
            return makeFailure(new Error("errRequestFailure"));
        }
    };

    // tslint:disable-next-line: max-line-length
    const getReportPage = async (index: number, pagination: { pageSize: number }, sorting: IReportTableSorting, filtering: IAccidentReportTableFilters): Promise<TResPaginated> => {
        throw new Error("Obsolete");
    };

    // tslint:disable-next-line: max-line-length
    const updateAccidentReport = async (companyId: number | string, report: IAccidentReport): Promise<TResult<IAccidentReport>> => {

        // Business logic: a report is "new" until the first save action.
        if (report.status === "new") {
            report.status = "inprogress";
        }

        const payloadResult = AccidentReportMapper.domainToApi(companyId, report);

        if (payloadResult.isFailure) {
            return makeFailure(payloadResult.error);
        }

        const response = await api.sendAction<IGetReportResponse>("tallenna_ilmoitus", payloadResult.value);

        if (response.isFailure) {
            return makeFailure(response.error);
        }

        if (response.isSuccess && !response.value.success) {
            return makeFailure(new Error("errServerError"));
        }

        const mappedResult = AccidentReportMapper.apiToDomain(response.value.ilmoitus, response.value.ilmoitusrivit);

        if (mappedResult.isFailure) {
            return makeFailure(mappedResult.error);
        }

        return makeSuccess(mappedResult.value);
    };

    const updateAccidentReportAnonymous = async (id: number, report: IAccidentReport): Promise<TResult<IAccidentReport>> => {
        const payloadResult = AccidentReportMapper.anonDomainToApi(id, report);

        if (payloadResult.isFailure) {
            return makeFailure(payloadResult.error);
        }

        const response = await api.sendAction<IGetReportResponse>("tallenna_ilmoitus_anonyymi", payloadResult.value, true);

        if (response.isFailure) {
            return makeFailure(response.error);
        }

        if (response.isSuccess && !response.value.success) {
            return makeFailure(new Error("errServerError"));
        }

        const mappedResult = AccidentReportMapper.apiToDomain(response.value.ilmoitus, response.value.ilmoitusrivit);

        if (mappedResult.isFailure) {
            return makeFailure(mappedResult.error);
        }

        return makeSuccess(mappedResult.value);
    };    

    return {
        createAccidentReport,
        getAccidentReportById,
        getAccidentReportList,
        getReportPage,
        updateAccidentReport,
        createAccidentReportInquiry,
        updateAccidentReportAnonymous
    };
}
