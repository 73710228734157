import { createSelector } from "reselect";
import { selectTurvaArviState, selectUsersByIdState } from "../../store/selectors";
import ITTKUser from "../../interfaces/ITTKUser";
import { useSelector } from "react-redux";

export const selectAuthState = createSelector(
    selectTurvaArviState,
    (state) => state.authentication,
);

export const selectLoginStatus = createSelector(
    selectAuthState,
    (auth) => auth.authenticationStatus,
);

export const selectRegistrationData = createSelector(
    selectAuthState,
    (state) => state.registration,
);

export const selectToken = createSelector(
    selectAuthState,
    (auth) => auth.token,
);

export const selectLoggedInUserId = createSelector(
    selectAuthState,
    (auth) => auth.loggedInUserId,
);

export const selectLoggedInUserCompanyId = createSelector(
    selectAuthState,
    (auth) => auth.loggedInUserCompanyId,
);

export const selectLoggedInUser = createSelector(
    selectUsersByIdState,
    selectLoggedInUserId,
    (usersById, userId): ITTKUser | null => {
        if (userId === null) {
            return null;
        }
        return usersById[userId] || null;
    },
);

export const selectForgottenPasswordFormData = createSelector(
    selectAuthState,
    (auth) => auth.forgottenPasswordForm,
);

export const selectForgottenPasswordTokenStatus = createSelector(
    selectAuthState,
    (auth) => auth.passwordResetCode,
);

export const selectForgottenPasswordPageData = createSelector(
    selectForgottenPasswordFormData,
    selectForgottenPasswordTokenStatus,
    (form, token) => ({ form, token }),
);

// HOOKS

export const useLoginStatus = () => useSelector(selectLoginStatus);

export const useLoggedInUser = () => useSelector(selectLoggedInUser);

export const useLoggedInUserCompanyId = () => useSelector(selectLoggedInUserCompanyId);

export const useRegistrationg = () => useSelector(selectRegistrationData);

export const useForgottenPassword = () => useSelector(selectForgottenPasswordPageData);
